import React, {useState, useEffect, useContext} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
import Loader from '../Elements/Loader'
import styles from './AllGenres.module.css'
import {Translations} from '../../Translations'
import Dropdown from '../Elements/Dropdown';
import { MSEContext } from '../Context/MainSearchEngineContext'

export default function AllGenres(props) {

    // Just name of component
    const name = "AllGenres";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 66;

    // Getting context 
    const showContext = useContext(MSEContext)

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [composersList, updateComposers] = useState([]);

    // Data selected from dropdowns
    const [composers, composersUpdate] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    showContext.updateMenuPositionHandler(3);


    // Get data for search enginge dropdowns
    const dropdownData = () => {

        // Composers
        const composersPromise = fetch(`${window.apiUrl}compositions/composers?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            composersList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        composersList.push({"name": item.name, "value": item.id})
                    );
            })
        })
        return Promise.all([composersPromise]);
    }

    let searchParams = "";
    
    if(composers){
        searchParams += `&id_composer=${composers}`
    }

    const clear = e => {
        e.preventDefault();
        composersUpdate(false);
        setNoMore(false);
        updateFetchCounter(1);
        clearRequestUpdate(!clearRequest)
    }

    let compositionsLink = `${window.apiUrl}compositions/genres?lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(compositionsLink)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(compositionsLink)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })  
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        updateData(false);
        dropdownData().then(()=>{
            fetch(compositionsLink)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(compositionsLink)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        });
    },[clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(compositionsLink)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    const changeComposers = (name, value) => composersUpdate(value)

    
    return (
        data  ? (
            <main>
                <div className="main-wrapper compositions-wrapper" style={{backgroundColor: "#f8f8f8", padding: "30px"}}>
                    <div className={styles.title}>{texts.genres}</div>
                    <form className={`${styles.compositions} secondary-search`} style={{marginBottom: "30px"}} onClick={()=>window.activeSearch = 'secondary'}>
                        <div>
                            <div className="dropdowns-wrapper single">
                                {composersList && composersList.length ? (
                                    <div>
                                        <div className="label">{texts.composers}</div>
                                        <Dropdown data={composersList} type="kompozytorzy" change={changeComposers}  />
                                    </div>
                                ) : null}
                            </div>
                            <div style={{textAlign: "center"}}>
                                <button onClick={clear} className="button white">{texts.clear}</button>
                                <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                            </div>
                        </div>
                    </form>

                    {!data || loading ? <Loader /> : (
                        <div className={styles.wrapper}>   
                            {data.map(function(item,index){
                                if(!item.id) return null
                                return(
                                    <Link key={index} to={`${url}/${item.id}${composers ? `?composer=${composers}` : ""}`}>
                                        <div className={styles.item}>
                                            <div>{item.name}</div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    )}

                    {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                    {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
                </div>
            </main>

        ) : <Loader />
    )
}