import React from 'react'
import loaderImg from '../../../assets/icons/loader.svg'
import loaderLeft from '../../../assets/icons/svg1.svg'
import loaderRight from '../../../assets/icons/svg2.svg'
import loader from '../../../assets/icons/svg3.svg'
import './Loader.css'

export default function Loader() {
    return (
        <div id="loader">
            {/* <img src={loaderImg} alt="loader"/> */}
            <div className="loader-wrapper">
                <img className="imgLeft" src={loaderLeft} alt="loader"/>
                <img className="imgRight" src={loaderRight} alt="loader"/>
            </div>
        </div>
    )
}
