import React, {useState, useEffect} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
import Loader from '../Loader'
import Dropdown from '../Dropdown'
import {Datalist} from '../Datalist'
import Multiselect from '../Mulitselect'
import {Translations} from '../../../Translations'
import {parseLink} from '../../../Helpers'
import {mobile} from '../../../Helpers'


export default function Bibliography(props) {

    // Just name of component
    const name = "Bibliographies";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

        // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

        // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [authorsList, updateAuthors] = useState([]);
    const [yearsList, updateYears] = useState([]);
    const [categoriesList, updateCategories] = useState([]);


    const memory = {
        categories: sessionStorage.getItem("memoCategories"),
        authors: sessionStorage.getItem("memoAuthors"),
        yearTo: sessionStorage.getItem("memoYearTo"),
        yearFrom: sessionStorage.getItem("memoYearFrom"),
    }

    // Data selected from dropdowns
    const [authors, authorsUpdate] = useState(memory.authors && JSON.parse(memory.authors) && JSON.parse(memory.authors).length ? JSON.parse(memory.authors) : []);
    const [yearTo, yearToUpdate] = useState(memory.yearTo ? memory.yearTo : false);
    const [yearFrom, yearFromUpdate] = useState(memory.yearFrom ? memory.yearFrom : false);
    const [categories, categoriesUpdate] = useState(memory.categories && JSON.parse(memory.categories) && JSON.parse(memory.categories).length ? JSON.parse(memory.categories) : []);
    const [searchText, updateSearchText] = useState(false);
    const [sortType, updateSortType] = useState("datePublishedAsc");
    const [authorsSearchType, changeAuthorsSearchType] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    const sortParams = [
        {name: texts.date+" "+texts.asc, value: "datePublishedAsc"},
        {name: texts.date+" "+texts.desc, value: "datePublishedDesc"},
        {name: texts.title+" "+texts.asc, value: "titleAsc"},
        {name: texts.title+" "+texts.desc, value: "titleDesc"},
        {name: texts.type+" "+texts.asc, value: "categoryAsc"},
        {name: texts.type+" "+texts.desc, value: "categoryDesc"},
    ]

    const authorsUrl = `${window.apiUrl}bibliographies/authors?lang=${window.language}&id_composer=${props.id}`
    const categoriesUrl = `${window.apiUrl}bibliographies/categories?lang=${window.language}&id_composer=${props.id}`


    // Get data for search enginge dropdowns
    const dropdownData = () => {

        // Authors
        // const authorsPromise = fetch(`${window.apiUrl}bibliographies/authors?lang=${window.language}&id_composer=${props.id}`)
        // .then(res => res.json())
        // .then((res)=>{
        //     console.log(`${window.apiUrl}bibliographies/authors?lang=${window.language}&id_composer=${props.id}`)
        //     authorsList.push({"name": texts.every, "value": false})
        //     res.map(function(item,index){
        //         return(
        //                 authorsList.push({"name": item.name, "value": item.id})
        //             );
        //     })
        //     // console.log("Autorzy", authors)
        // })

        // Categories
        // const categoriesPromise = fetch(`${window.apiUrl}bibliographies/categories?lang=${window.language}&id_composer=${props.id}`)
        // .then(res => res.json())
        // .then((res)=>{

        //     categoriesList.push({"name": texts.every, "value": false})
        //     res.map(function(item,index){
        //         return(
        //                 categoriesList.push({"name": item.name, "value": item.id})
        //             );
        //     })
        //     // console.log("Kategorie:", categories)
        // })

        // Years
        const yearsPromise = fetch(`${window.apiUrl}bibliographies/years?id_composer=${props.id}`)
        .then(res => res.json())
        .then((res)=>{
            yearsList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    yearsList.push({"name": item, "value": item})
                    );
            })
            // console.log("Lata:", console.log(res));
        })

        // return Promise.all([authorsPromise, categoriesPromise, yearsPromise]);
        return Promise.all([yearsPromise]);
    }

    let searchParams = "";


    if(authors.length){
        console.log("Autorzy to:", authors)
        for(let i = 0; i < authors.length; i++){
            searchParams += `&ids_authors[]=${authors[i].value}`
        }
    }

    if(authorsSearchType){
        searchParams += `&authors_search_type=AND`
    }


    if(categories.length){
        console.log("Autorzy to:", categories)
        for(let i = 0; i < categories.length; i++){
            searchParams += `&ids_categories[]=${categories[i].value}`
        }
    }

    if(yearTo){
        searchParams += `&date_of_publishing_to=${yearTo}`
    }

    if(yearFrom){
        searchParams += `&date_of_publishing_from=${yearFrom}`
    }

    if(searchText){
        searchParams += `&search=${searchText}`
    }

    if(sortType){
        searchParams += `&sort=${sortType}`
    }

    const createStorage = (name, value) => {
        sessionStorage.setItem(name, value)
    }

    const clear = e => {
        e.preventDefault();
        authorsUpdate([]);
        categoriesUpdate(false);
        yearToUpdate(false);
        yearFromUpdate(false);
        updateSearchText(false);
        clearRequestUpdate(!clearRequest);
        sessionStorage.removeItem("memoAuthors");
        sessionStorage.removeItem("memoCategories");
        sessionStorage.removeItem("memoYearTo");
        sessionStorage.removeItem("memoYearFrom");
    }

    // bibliographies?lang=en&offset=0&limit100&search=text&id_author=1&year=2020&id_category=1&id_character=1

    let link = `${window.apiUrl}bibliographies?ids_characters[]=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}&authors_search_type=OR${searchParams}`

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(link)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(link)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })  
    }

    useEffect(() => {
        
    }, [authors, yearFrom])

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {

        props.updateMenu(7);
        updateData(false);
        
        // Get dropdownData only if it is not exists
        if(!authorsList.length && !yearsList.length && !categoriesList.length){
            dropdownData().then(()=>{
                fetch(link)
                .then(res => res.json())
                .then((res)=>{
                    console.log(name, res);
                    console.log(link)
                    updateData(res);
                    if(res.length < elementAmount){
                        setNoMore(true);
                    }
                    if(mobile()){
                        const text = document.getElementsByClassName("text-wrapper")
                        text[0].scrollIntoView(); 
                    }
                })    
            });
        } else {
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(link)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })   
        }
    },[clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if(sortType){
            loadingUpdate(true);
            setNoMore(false)
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                // console.log(object)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                loadingUpdate(false);
            })    
        }
        }, [sortType])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    // console.log("path to:", path, "a url to:", url)

    const changeAuthors = (authorArray) => {
        authorsUpdate(authorArray)
        createStorage("memoAuthors", JSON.stringify(authorArray))
        // console.log("Autorzy to:", authors)
    }

    const changeCategories = (name, value) => {
        // console.log("name i value to:", name, value)
        categoriesUpdate(name)
        createStorage("memoCategories", JSON.stringify(name));
    }

    const changeYearTo = (name, value) => {
        yearToUpdate(value)
        createStorage("memoYearTo", value);
    }

    const changeYearFrom = (name, value) => {
        yearFromUpdate(value)
        createStorage("memoYearFrom", value);
    }

    const changeSort = (name, value) => {
        updateSortType(value)
    }


    const authorsSearchTypeHandler = value => {
        changeAuthorsSearchType(value)
    }

    // console.log("test memory:", JSON.parse(memory.authors)[0].name);

    return (
        data ? (
            <div className="compositions-wrapper">
                <div className="title">{texts.biblio}</div>
                <form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase}/>
                        <div className="dropdowns-wrapper two">
                            <div className="grid-col-2">
                                <div className="label">{texts.type}</div>
                                <Multiselect
                                    // data={categoriesList}
                                    dataUrl={categoriesUrl}
                                    label={memory.categories && memory.categories.length && memory.categories.length > 2 ? memory.categories : false}
                                    change={changeCategories}
                                    />
                            </div>
                            {yearsList && yearsList.length ?  (
                                <div>
                                    <div className="label">{texts.dateFrom}</div>
                                    <Datalist data={yearsList} label={memory.yearFrom} change={changeYearFrom} />
                                </div>
                            ) : null}
                            {yearsList && yearsList.length ? (
                                <div>
                                    <div className="label">{texts.date} {texts.to}</div>
                                    <Datalist 
                                        data={yearFrom ? yearsList.filter(({value}) => parseInt(value) > parseInt(yearFrom)) : yearsList}
                                        label={memory.yearTo} change={changeYearTo}
                                    />
                                </div>
                            ) : null}
                            <div className="grid-col-2">
                                <div className="label">{texts.author}</div>
                                <Multiselect
                                    medium
                                    // data={authorsList}
                                    dataUrl={authorsUrl}
                                    label={memory.authors && memory.authors.length && memory.authors.length > 2 ? memory.authors : false}
                                    change={changeAuthors}
                                    searchType={authorsSearchTypeHandler}
                                />
                            </div>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                    {sortParams ? (
                        <div className="sort-section">
                            <span className="label">{texts.sortBy}</span>
                            <Dropdown data={sortParams} type="sort" change={changeSort} />
                        </div>
                    ) : null}
                </form>
                {loading ? <Loader /> : (
                    <div>   
                        <div className="composition-item biblio-item tab-header">
                            <div>{texts.title}</div>
                            <div>{texts.author}</div>
                            <div>{texts.date}</div>
                            <div>{texts.type}</div>
                        </div> 
                        {data.map(function(item,index){
                            return(
                                <Link to={`${url}/${item.id}-${item.title ? parseLink(item.title) : null}`} key={index}>
                                    <div className="composition-item biblio-item">
                                        <div dangerouslySetInnerHTML={{ __html: item.title}}/>
                                        <div>
                                            {item.authors.length ? (
                                                item.authors.map(function(item, index){
                                                    return(
                                                        <div key={index}><span>{item.first_name}</span> <span>{item.last_name}</span></div>
                                                    )
                                                })
                                            ) : null}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: item.date_of_publishing_text ? item.date_of_publishing_text : "" }}/>
                                        <div>{item.category ? item.category : null}</div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                )}


                {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
            </div>
        ) : <Loader />
    )
}
