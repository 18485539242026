import React, { useState, useEffect, useContext} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
import Loader from '../Elements/Loader'
import Dropdown from '../Elements/Dropdown';
import {Translations} from '../../Translations'
import {parseLink} from '../../Helpers'
import Multiselect from '../Elements/Mulitselect'
import {mobile} from '../../Helpers'
import { MSEContext } from '../Context/MainSearchEngineContext'
import {Datalist} from '../Elements/Datalist'
import styles from './AllBibliographies.module.css'

export default function AllBibliographies(props) {

    // Just name of component
    const name = "Bibliographies";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [charactersList, updateCharacters] = useState([]);
    const [authorsList, updateAuthors] = useState([]);
    const [yearsList, updateYears] = useState([]);
    const [categoriesList, updateCategories] = useState([]);

    const memory = {
        authors: sessionStorage.getItem("memoAuthors"),
    }

    // Data selected from dropdowns
    const [characters, charactersUpdate] = useState(false);
    const [authors, authorsUpdate] = useState(memory.authors && JSON.parse(memory.authors) && JSON.parse(memory.authors).length ? JSON.parse(memory.authors) : []);
    const [years, yearsUpdate] = useState(false);
    const [categories, categoriesUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [sortType, updateSortType] = useState(false);
    const [authorsSearchType, changeAuthorsSearchType] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    const sortParams = [
        { name: texts.title + " " + texts.asc, value: "titleAsc" },
        { name: texts.title + " " + texts.desc, value: "titleDesc" },
        { name: texts.type + " " + texts.asc, value: "categoryAsc" },
        { name: texts.type + " " + texts.desc, value: "categoryDesc" },
    ]

    const authorsUrl = `${window.apiUrl}bibliographies/authors?lang=${window.language}&limit=9999`

    console.log(authorsUrl,"authorsUrl")

    // Get data for search enginge dropdowns
    const dropdownData = () => {
        // Characters
        const charactersPromise = fetch(`${window.apiUrl}bibliographies/characters?lang=${window.language}`)
            .then(res => res.json())
            .then((res) => {
                charactersList.push({ "name": texts.every, "value": false })
                res.map(function (item, index) {
                    return (
                        charactersList.push({ "name": item.name, "value": item.id })
                    );
                })
                console.log("Characters:", characters)
            })

        // Authors
        const authorsPromise = fetch(`${window.apiUrl}bibliographies/authors?lang=${window.language}`)
            .then(res => res.json())
            .then((res) => {
                authorsList.push({ "name": texts.every, "value": false })
                res.map(function (item, index) {
                    return (
                        authorsList.push({ "name": item.name, "value": item.id })
                    );
                })
                // console.log("Autorzy", authors)
            })

        // Categories
        const categoriesPromise = fetch(`${window.apiUrl}bibliographies/categories?lang=${window.language}`)
            .then(res => res.json())
            .then((res) => {

                categoriesList.push({ "name": texts.every, "value": false })
                res.map(function (item, index) {
                    return (
                        categoriesList.push({ "name": item.name, "value": item.id })
                    );
                })
                // console.log("Kategorie:", categories)
            })

        // Years
        const yearsPromise = fetch(`${window.apiUrl}bibliographies/years`)
            .then(res => res.json())
            .then((res) => {
                yearsList.push({ "name": texts.every, "value": false })
                res.map(function (item, index) {
                    return (
                        yearsList.push({ "name": item, "value": item })
                    );
                })
                // console.log("Lata:", years);
            })

        return Promise.all([charactersPromise, authorsPromise, categoriesPromise, yearsPromise]);
    }

    let searchParams = "";

    if (characters) {
        searchParams += `&ids_characters[]=${characters}`
    }

    if(authors.length){
        //console.log("Autorzy to:", authors)
        for(let i = 0; i < authors.length; i++){
            searchParams += `&ids_authors[]=${authors[i].value}`
        }
    }

    if(authorsSearchType){
        searchParams += `&authors_search_type=AND`
    }

    if (categories) {
        searchParams += `&ids_categories[]=${categories}`
    }

    if (years) {
        searchParams += `&year=${years}`
    }

    if (searchText) {
        searchParams += `&search=${searchText}`
    }

    if (sortType) {
        searchParams += `&sort=${sortType}`
    }

    const createStorage = (name, value) => {
        sessionStorage.setItem(name, value)
    }

    const clear = e => {
        e.preventDefault();
        charactersUpdate(false);
        authorsUpdate([]);
        categoriesUpdate(false);
        yearsUpdate(false);
        clearRequestUpdate(!clearRequest)
        sessionStorage.removeItem("memoAuthors");
    }


    let link = `${window.apiUrl}bibliographies?lang=${window.language}&offset=${(fetchCounter - 1) * elementAmount}&limit=${elementAmount}&authors_search_type=OR${searchParams}`

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(link)
            .then(res => res.json())
            .then((res) => {
                console.log(name, res);
                console.log(link)
                updateData(res);
                if (res.length < elementAmount) {
                    setNoMore(true);
                }
                loadingUpdate(false);
            })
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        updateData(false);
        dropdownData().then(()=>{
            fetch(link)
            .then(res => res.json())
            .then((res) => {
                console.log(name, res);
                console.log(link)
                updateData(res);
                if (res.length < elementAmount) {
                    setNoMore(true);
                }
            })
        })
    }, [clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if (sortType) {
            loadingUpdate(true);
            setNoMore(false)
            fetch(link)
                .then(res => res.json())
                .then((res) => {
                    console.log(name, res);
                    // console.log(object)
                    updateData(res);
                    if (res.length < elementAmount) {
                        setNoMore(true);
                    }
                    loadingUpdate(false);
                })
        }
    }, [sortType])

    // Get only list data on the counter increase
    useEffect(() => {
        if (fetchCounter > 1) {
            fetch(link)
                .then(res => res.json())
                .then((res) => {
                    console.log(name, res);
                    updateData(data.concat(res));
                    if (res.length < elementAmount) {
                        setNoMore(true);
                    }
                })
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter + 1);
    }

    let { path, url } = useRouteMatch();

    //console.log("path to:", path, "a url to:", url)

    const changeCharacters = (name, value) => {
        charactersUpdate(value)
    }

    const changeAuthors = (authorArray) => {
        authorsUpdate(authorArray)
        createStorage("memoAuthors", JSON.stringify(authorArray))
    }

    const changeCategories = (name, value) => {
        categoriesUpdate(value)
    }

    const changeYears = (name, value) => {
        yearsUpdate(value)
    }

    const changeSort = (name, value) => {
        updateSortType(value)
    }


    const authorsSearchTypeHandler = value => {
        changeAuthorsSearchType(value)
    }

    // Getting context 
    const showContext = useContext(MSEContext)

    showContext.updateMenuPositionHandler(5);

    return (
        data ? (
            <main>
                <div className="main-wrapper">
                    <div className="compositions-wrapper grey-wrapper">
                        <div className="title">{texts.biblio}</div>
                        <form className="secondary-search" onClick={() => window.activeSearch = 'secondary'}>
                            <div>
                                <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase} />
                                <div className={styles.dropdownsWrapper}>
                                    {charactersList && charactersList.length ? (
                                        <div className={styles.data}>
                                            <div className="label">{texts.composers}</div>
                                            <Dropdown data={charactersList} type="obsada" change={changeCharacters} />
                                        </div>
                                    ) : null}

                                    {categoriesList && categoriesList.length ? (
                                        <div className={styles.data1}>
                                            <div className="label">{texts.type}</div>
                                            <Dropdown data={categoriesList} type="data" change={changeCategories} />
                                        </div>
                                    ) : null}

                                    
                                    {yearsList && yearsList.length ? (
                                        <div className={styles.data2}>
                                            <div className="label">{texts.date}</div>
                                            <Datalist data={yearsList} change={changeYears} />
                                        </div>
                                    ) : null}

                                    {authorsList && authorsList.length ? (
                                        <div className={styles.authors}>
                                            <div className="label" style={{textAlign:"center"}}>{texts.author}</div>
                                            {/* <Datalist small data={authorsList} change={changeAuthors} /> */}
                                                <Multiselect
                                                medium
                                                all
                                                //data={authorsList}
                                                dataUrl={authorsUrl}
                                                limited={true}
                                                label={memory.authors && memory.authors.length && memory.authors.length > 2 ? memory.authors : false}
                                                change={changeAuthors}
                                                searchType={authorsSearchTypeHandler}
                                            />
                                        </div>
                                    ) : null}
                                    
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <button onClick={clear} className="button white">{texts.clear}</button>
                                    <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                                </div>
                            </div>
                            <div className="sort-section">
                                <span className="label">{texts.sortBy}</span>
                                <Dropdown data={sortParams} type="sort" change={changeSort} />
                            </div>
                        </form>
                        {loading ? <Loader /> : (
                            <div>
                                <div className="composition-item biblio-item tab-header">
                                    <div>{texts.title}</div>
                                    <div>{texts.author}</div>
                                    <div>{texts.date}</div>
                                    <div>{texts.type}</div>
                                </div>
                                {data.map(function (item, index) {
                                    return (
                                        <>
                                            {item.characters[0].id === 1 ? (
                                                <a target="_blank" href={`${window.chopinUrl}/bibliografia/${item.id}-${item.title ? parseLink(item.title) : null}`} key={index}>
                                                    <div className="composition-item biblio-item">
                                                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                        <div>
                                                            {item.authors.length ? (
                                                                item.authors.map(function (item, index) {
                                                                    return (
                                                                        <div key={index}><span>{item.first_name}</span> <span>{item.last_name}</span></div>
                                                                    )
                                                                })
                                                            ) : null}
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.date_of_publishing_text ? item.date_of_publishing_text : "" }} />
                                                        <div>{item.category ? item.category : null}</div>

                                                    </div>
                                                </a>
                                            ) : (
                                                <Link to={`osoba/${item.characters[0].id}-${item.characters[0].first_name}-${item.characters[0].last_name}/bibliografia/${item.id}-${item.title ? parseLink(item.title) : null}`} key={index}>
                                                    <div className="composition-item biblio-item">
                                                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                        <div>
                                                            {item.authors.length ? (
                                                                item.authors.map(function (item, index) {
                                                                    return (
                                                                        <div key={index}><span>{item.first_name}</span> <span>{item.last_name}</span></div>
                                                                    )
                                                                })
                                                            ) : null}
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.date_of_publishing_text ? item.date_of_publishing_text : "" }} />
                                                        <div>{item.category ? item.category : null}</div>

                                                    </div>
                                                </Link>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                        )}
                        {data.length < 1 ? <div style={{ textAlign: "center", fontWeight: "bold" }}>{texts.noPositions}</div> : null}
                        {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button>}
                    </div>
                </div>
            </main>
        ) : <Loader />
    )
}
