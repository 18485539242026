import React, { useEffect, useState} from 'react'
import { Link, useRouteMatch} from "react-router-dom"
import Loader from '../Elements/Loader'
import {minHeight} from '../../Helpers'
import {parseLink} from '../../Helpers'
import {Translations} from '../../Translations'
import styles from './Multimedia.module.css'
import placeholder from '../../assets/img/placeholder.jpg'
import {mobile} from '../../Helpers'

export default function Multimedia(props) {

    const name = 'Multimedia'

    // Languages
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // All data
    const [data, updateData] = useState(false);
    const [spotifyData, updateSpotifyData] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [clearRequest, clearRequestUpdate] = useState(false);

    // Pagination options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false);
    const elementAmount = 12;

    // console.log(name, props)

    let searchParam = searchText ? `&search=${searchText}` : ""
    let multimediaLink = `${window.apiUrl}multimedia?id_composer=${props.id}&lang=${window.language}${searchParam}`
    let spotifyLink = `${window.apiUrl}spotify?id_composer=${props.id}&lang=${window.language}${searchParam}`

    const fetchHandler = (link, updater, fetchName) => {
        isLoadingUpdate(true)
        fetch(link)
        .then(res => res.json())
        .then((res)=>{
            console.log(fetchName, "link: ", link);
            console.log(fetchName, "response: ", res);
            updater(res);
            isLoadingUpdate(false);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })  
    }

    useEffect(() => {
        minHeight()
        props.updateMenu(10);
        fetchHandler(multimediaLink, updateData, "MultimediaData");
        fetchHandler(spotifyLink, updateSpotifyData, "SpotifyData");
    }, [clearRequest])

    const clear = e => {
        e.preventDefault();
        updateSearchText(false);
        clearRequestUpdate(!clearRequest);
    }

    const submit = e => {
        e.preventDefault();
        fetchHandler(multimediaLink, updateData, "MultimediaData");
        fetchHandler(spotifyLink, updateSpotifyData, "SpotifyData");
    }

    let {path, url} = useRouteMatch();

    return (
        <div>
            {data ? (
                <div>
                    <div className={styles.mainWrapper}>
                        <form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                            <div>
                                <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={searchText ? searchText : texts.searchPhrase}/>
                                <div style={{textAlign: "center"}}>
                                    <button onClick={clear} className="button white">{texts.clear}</button>
                                    <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                                </div>
                            </div>
                        </form>
                        {!isLoading ?  <h1 className={styles.title}>{texts.multimedia}</h1> : <Loader /> }
                        {data && data.length ? (
                        <div className={styles.secondaryWrapper}>
                            {data.map(function(item, index){
                                const image = item.image ? item.image : placeholder 
                                return(
                                    <Link to={`${url}/${item.id}${item.title ? "-"+parseLink(item.title) : ''}`} key={index}>
                                        <div className={styles.item}>
                                            <div className={styles.image} style={{backgroundImage: `url(${image})`}}></div>
                                            <div>
                                                <div className={styles.itemTitle}>{item.title}</div>
                                                {item.subtitle ? (
                                                    <div className={styles.itemSubtitle} dangerouslySetInnerHTML={{ __html: item.subtitle }}/>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                        ) : <div className="no-results">{texts.noPositions}</div>}
                        {spotifyData && spotifyData.length ? (
                            <div className={styles.secondaryWrapper}>
                                {spotifyData.slice(0, 1).map(function(item, index){
                                    const image = spotifyData[0].image ? spotifyData[0].image : placeholder 
                                    return(
                                        <Link to={`${url}/spotify/${props.id}`} key={index}>
                                            <div className={styles.item}>
                                                <div className={styles.image} style={{backgroundImage: `url(${image})`}}></div>
                                                <div>
                                                    <div className={styles.itemTitle}>Audio</div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        ) : null}
                        
                    </div>
                </div>
            ) : <Loader />}
        </div>
    )
}