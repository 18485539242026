import React, {useState, useEffect} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {Translations} from '../../../Translations'
import Loader from '../Loader'
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'
import styles from './Calendary.module.css'

export default function Calendary(props) {

    const translation = Translations();

    const name = "Calendary";
    const [data, updateData] = useState(false);
    const [loading, loadingUpdate] = useState(false);
    const [text, updateText] = useState([])
    const [texts, updateTexts] = useState(translation);
    const [active, updateActive] = useState(0);

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    let { id } = useParams();    

    id = parseInt(id)

    // Properties of slider
    const glide = new Glide('.glide', {
        type: 'slider',
        startAt: 0,
        perView: 5,
    })

    // Remove carousel functions and reset active subcategory on unmount
    useEffect(() => {
        return () => {
            glide.destroy()
            props.updateSubmenu(false)
            console.log("unmounted")
        }
    }, [])

    useEffect(() => {
        loadingUpdate(true);
        updateActive(0)
        props.updateMenu(2);
        props.updateSubmenu(id);
        fetch(`${window.apiUrl}calendar/category/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            if(res.subcategories && res.subcategories.length){
                updateText(res.subcategories[0].events);
                glide.mount();
            } else {
                updateText([])
            }
            loadingUpdate(false)
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })
    }, [id])

    useEffect(() => {
        initGallery(".gallery-text img", lightboxImages, lightboxToggle);

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [text])

    // Selfdescriptive function ftw!
    const updateTextHandler = (e, index) => {
        updateText(e.events)
        updateActive(index)
    }

    // Gallery function
    const closeLightbox = () => {
        lightboxToggle(false)
    }


    return (
        data ? (
            <div className={loading ? styles.loading : ""}>
                {data.subcategories && data.subcategories.length ? (
                    <div className="glide">
                        <div className="glide__track" data-glide-el="track">
                            <div className="glide__slides">
                                {data.subcategories.map(function(item,index){
                                    return(
                                        <div key={index} className={`glide__slide ${styles.event} ${index === active ? styles.active : ""}`} onClick={updateTextHandler.bind(this, item, index)}>{item.title}</div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="<"></button>
                            <button className="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
                            <button className="glide__arrow glide__arrow--left left-max" data-glide-dir="<<"></button>
                            <button className="glide__arrow glide__arrow--right right-max" data-glide-dir=">>"></button>
                        </div>
                    </div>
                ) : null}

                <div>
                    {text.map(function(item, index){
                        return(
                            <div key={index}>
                                <div className="title">{item.title}</div>
                                <div className={`${styles.calendaryText} gallery-text`} dangerouslySetInnerHTML={{ __html: item.text }} />
                            </div>
                        )
                    })}
                    {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
                </div>
            </div>
        ) : <Loader />
    )
}