import React, { useEffect, useState, useContext } from "react";
import "./Footer.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Translations } from "../../Translations";
import { MSEContext } from "../Context/MainSearchEngineContext";
import Logo from "../Header/Logo";
import fun_pl_x from "../../assets/img/fun-pl-x.jpg";
import fun_en_x from "../../assets/img/fun-en-x.jpg";
import fun_pl_y from "../../assets/img/fun-pl-y.jpg";
import fun_en_y from "../../assets/img/fun-en-y.jpg";
import pl_pl_x from "../../assets/img/pl-pl-x.png";
import pl_en_x from "../../assets/img/pl-en-x.png";
import pl_pl_y from "../../assets/img/pl-pl-y.png";
import pl_en_y from "../../assets/img/pl-en-y.png";
import min_pl from "../../assets/img/logoMkidn.png";
import min_en from "../../assets/img/logoMkidnEN.png";
import min_pl_png from "../../assets/img/logoMkidn.png";
import min_en_png from "../../assets/img/logoMkidnEN.png";
import eu_pl_x from "../../assets/img/eu-pl-x.jpg";
import eu_en_x from "../../assets/img/eu-en-x.jpg";
import eu_pl_y from "../../assets/img/eu-pl-y.jpg";
import eu_en_y from "../../assets/img/eu-en-y.jpg";

import nifc from "../../assets/img/NIFC.svg";
import pwm from "../../assets/img/PWM.png";
import { mobile } from "../../Helpers";

export default function Footer() {
    const translation = Translations();

    const [texts, updateTexts] = useState(translation);
    const [scrolled, updateScrolled] = useState("");

    // Getting context
    const showContext = useContext(MSEContext);

    let data = [
        {
            name: texts.news,
            active: showContext.menuPosition === 1 ? true : false,
            url: `/${window.language}/aktualnosci`,
        },
        {
            name: texts.composers,
            active: showContext.menuPosition === 2 ? true : false,
            url: `/${window.language}/kompozytorzy`,
        },
        {
            name: texts.genres,
            active: showContext.menuPosition === 3 ? true : false,
            url: `/${window.language}/gatunki`,
        },
        {
            name: texts.compositions,
            active: showContext.menuPosition === 4 ? true : false,
            url: `/${window.language}/kompozycje`,
        },
        {
            name: texts.biblio,
            active: showContext.menuPosition === 5 ? true : false,
            url: `/${window.language}/wydawnictwa`,
        },
        {
            name: texts.multimedia,
            active: showContext.menuPosition === 7 ? true : false,
            url: `/${window.language}/multimedia`,
        },

        {
            name: texts.aboutProject,
            active: showContext.menuPosition === 6 ? true : false,
            url: `/${window.language}`,
        },
        {
            name: texts.declaration,
            active: showContext.menuPosition === 8 ? true : false,
            url: `/${window.language}/deklaracja-dostepnosci`,
        },
    ];

    document.addEventListener("scroll", function () {
        if (window.scrollY > 1000) {
            updateScrolled("scrolled");
        } else {
            updateScrolled("");
        }
        // console.log(window.scrollY)
    });

    let logo1, logo2, logo3, logo4, min;

    if (window.language === "pl") {
        if (!mobile()) {
            logo1 = fun_pl_x;
            logo2 = pl_pl_x;
            logo4 = eu_pl_x;
        } else {
            logo1 = fun_pl_y;
            logo2 = pl_pl_y;
            logo4 = eu_pl_y;
        }
        logo3 = min_pl;
        min = min_pl_png;
    } else {
        if (!mobile()) {
            logo1 = fun_en_x;
            logo2 = pl_en_x;
            logo4 = eu_en_x;
        } else {
            logo1 = fun_en_y;
            logo2 = pl_en_y;
            logo4 = eu_en_y;
        }
        logo3 = min_en;
        min = min_en_png;
    }

    return (
        <footer>
            <div className="footer-wrapper">
                <div className="logo-wrapper">
                    <div>
                        <div>{texts.financedBy}</div>
                        <a
                            target="_blank"
                            href="https://www.gov.pl/web/kultura/"
                        >
                            <img src={min} alt="MKiDN" />
                        </a>
                    </div>
                    <a target="_blank" href="https://nifc.pl/pl">
                        <img src={nifc} alt="NIFC" />
                    </a>
                    <a target="_blank" href="https://pwm.com.pl/">
                        <img src={pwm} alt="PWM" />
                    </a>
                </div>
                <div></div>
                <div className="footer-menu">
                    <ul>
                        {data.map(function (item, index) {
                            const active = item.active ? "active" : "";
                            return (
                                <li key={index}>
                                    <Link className={active} to={item.url}>
                                        {item.name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    className={`up-we-go up-we-go-hidden ${scrolled}`}
                ></div>
            </div>
            <div className="footer-logos-container">
                <div className="footer-logos-wrapper">
                    <div>
                        <img src={logo1} alt="" />
                    </div>
                    <div>
                        <img src={logo2} alt="" />
                    </div>
                    <div>
                        <img src={logo3} alt="" />
                    </div>
                    <div>
                        <img src={logo4} alt="" />
                    </div>
                </div>
            </div>
        </footer>
    );
}
