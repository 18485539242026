export function Translations() {
    let list;
    if (window.language === "pl") {
        list = {
            reviewing: "Recenzuje",
            reviewed_by: "Recenzowana przez",
            genre: "Gatunek",
            genres: "Gatunki",
            genres2: "Omówienia twórczości",
            tonation: "Tonacja",
            cast: "Obsada",
            createDate: "Data powstania",
            publicationDate: "Data publikacji",
            opusSpan: "Opus",
            calendary: "Kalendarium",
            calendar: "Kalendarium",
            compositions: "Kompozycje",
            articles: "Artykuły",
            firstEditions: "Pierwodruki",
            manuscripts: "Rękopisy",
            return: "Powrót",
            contact: "Kontakt",
            filter: "Filtruj wyniki",
            searchRecords: "Wyniki wyszukiwania",
            titlePage: "Strona tytułowa",
            dedications: "Dedykacje",
            publishHouse: "Wydawnictwo",
            publishing: "Wydawnictwa",
            publisher: "Wydawca",
            publishDate: "Data wydania",
            publishPlace: "Miejsce wydania",
            publishNr: "Numer wydawniczy",
            pagesAmout: "Liczba stron",
            comments: "Uwagi",
            otherPublications: "Inne wydania",
            mazurek: "Mazurek",
            concert: "Koncert",
            song: "Pieśń",
            symphony: "Symfonia",
            sonata: "Sonata",
            polonaise: "Polonez",
            etude: "Etiuda",
            nocturne: "Nokturn",
            noResults: "Brak osób dla podanych kryteriów",
            mainSpan: "Baza muzyki polskiej w latach 1795-1918",
            searchComp: "Szukaj kompozytorów",
            format: "Format",
            reproduction: "Reprodukcja",
            notes: "Partytury",
            dateFrom: "Data od",
            to: "do",
            letter: "Litera",
            aboutProject: "O projekcie",
            composers: "Kompozytorzy",
            composer: "Kompozytor",
            source : "Teksty źródłówe",
            multimedia: "Multimedia",
            iconography: "Ikonografia",
            all: "Wszyscy",
            openAccess: "w otwartym dostępie",
            opus: "Opus",
            createYear: "Rok powstania",
            createYearFrom: "Rok powstania od",
            createYearTo: "Rok powstania do",
            dedicationAdres: "Adresat dedykacji",
            every: "Wszystkie",
            clear: "Wyczyść",
            search: "Wyszukaj",
            writeDown: "Wpisz",
            searchPhrase: "Wpisz szukaną frazę",
            aboutSong: "O utworze",
            titleOnSource: "Tytuł w źródle",
            sourceType: "Typ źródła",
            copyist: "Kopista",
            authorOfWords: "Autor słów",
            sizes: "Wymiary",
            sourceCreationDate: "Data powstania źródła",
            sourceCreationDateInWords: "Data powstania źródła słownie",
            storagePlace: "Miejsce przechowania",
            firstEdition: "Pierwsza edycja",
            noPositions: "Brak wyników dla podanych kryteriów",
            moreResults: "Więcej wyników",
            categories: "Kategorie",
            sortBy: "Sortuj po:",
            asc: "rosnąco",
            desc: "malejąco",
            title: "Tytuł",
            composerFem: "Kompozytorka",
            place: "Miejsce",
            date: "Data",
            countries: "Kraje",
            biography: "Biografia",
            fullBio: "Pełna biografia",
            shortBio: "Skrócona biografia",
            biblio: "Bibliografia",
            authors: "Autorzy",
            author: "Autor",
            characters: "Bohaterowie",
            category: "Kategoria",
            keywords: "Słowa kluczowe",
            journal: "Czasopismo",
            descriptionF: "Opis fizyczny",
            filmography: "Filmografia",
            direction: "Reżyseria",
            year: "Rok",
            others: "Inne",
            techData: "Dane techniczne",
            copy: "Kopia",
            production: "Produkcja",
            series: "Seria",
            type: "Typ",
            letters: "Listy",
            addresse: "Adresat",
            sender: "Nadawca",
            gallery: "Galeria",
            news: "Aktualności",
            scores: "Partytury",
            executionTime: "Data wykonania",
            premiereDate: "Data pierwszego wykonania",
            orderingPerson: "Osoba zamawiająca",
            lyricsAuthor: "Autor słów",
            praPlace: "Miejsce prawykonania",
            orderingInstitution: "Instytucja zamawiająca",
            disjunction: "Alternatywa wyników",
            unknown: "Nieznany",
            video: "Wideo",
            circulationNumber: "Nakład",
            prefaceForeword: "Przedmowa/Wstęp",
            language: "Język",
            financedBy: "Sfinansowano ze środków",
            editors: "Redakcja",
            shopURL: "Link do sklepu",
            shopURLTxt: "Przejdź do sklepu",
            declaration: "Deklaracja dostępności",
            compositionCheckbox: "Kompozycje z opisem",
            signature : "Sygnatura"

        };
    } else {
        list = {
            reviewing: "Reviewing",
            reviewed_by: "Reviewed by",
            genre: "Genre",
            genres: "Genres",
            genres2: "Bodies of work",
            tonation: "Tonality",
            cast: "Cast",
            publicationDate: "Publication date",
            createDate: "Create date",
            opusSpan: "Opus",
            calendary: "Calendary",
            calendar: "Calendar",
            compositions: "Compositions",
            articles: "Articles",
            firstEditions: "First editions",
            manuscripts: "Manuscripts",
            return: "Return",
            contact: "Contact",
            publishing: "Publishing",
            filter: "Filter results",
            searchRecords: "Searched results",
            titlePage: "Title page",
            dedications: "Recipient dedication",
            publishHouse: "Publishing House",
            iconography: "Iconography",
            publisher: "Publishing House",
            publishDate: "Date release",
            publishPlace: "Place of publication",
            publishNr: "Plate number",
            pagesAmout: "Number of pages",
            comments: "Notes",
            otherPublications: "Other editions",
            mazurek: "Mazura",
            concert: "Concert",
            song: "Song",
            symphony: "Symphony",
            sonata: "Sonatas",
            polonaise: "Polonaise",
            etude: "Etude",
            nocturne: "Nocturne",
            noResults: "No people for the given criteria",
            mainSpan: "Polish music base during 1795-1918",
            searchComp: "Search composers",
            format: "Format",
            reproduction: "Reproduction",
            notes: "Music notes",
            dateFrom: "Date from",
            to: "to",
            letter: "Letter",
            aboutProject: "About project",
            composers: "Composers",
            composer: "Composer",
            composerFem: "Composer",
            sourceTexts: "Source texts",
            multimedia: "Multimedia",
            all: "All",
            openAccess: "IN OPEN ACCESS",
            opus: "Opus",
            createYear: "Year of creation",
            createYearFrom: "Year of creation from",
            createYearTo: "Year of creation to",
            dedicationAdres: "Addressee",
            every: "All",
            clear: "Clear",
            search: "Search",
            writeDown: "Write",
            searchPhrase: "Enter the search phrase",
            aboutSong: "About song",
            titleOnSource: "Title in source",
            sourceType: "Source type:",
            copyist: "The copyist",
            authorOfWords: "Author of words",
            sizes: "Sizes",
            sourceCreationDate: "Source creation date",
            sourceCreationDateInWords: "Source creation date in words",
            dateSourceCreation: "Source creation date",
            storagePlace: "Place of storage",
            firstEdition: "First edition",
            noPositions: "No results for the given criteria",
            moreResults: "More results",
            categories: "Categories",
            sortBy: "Sort by:",
            asc: "ascending",
            desc: "descending",
            title: "Title",
            place: "Place",
            date: "Date",
            countries: "Countries",
            biography: "Biography",
            fullBio: "Full biography",
            shortBio: "Short biography",
            biblio: "Bibliography",
            characters: "Characters",
            category: "Category",
            keywords: "Keywords",
            journal: "Journal",
            descriptionF: "Physical description",
            filmography: "Filmography",
            direction: "Directory",
            year: "Year",
            others: "Others",
            techData: "Technical data",
            copy: "Copy",
            production: "Production",
            series: "Series",
            authors: "Authors",
            author: "Author",
            type: "Type",
            letters: "Letters",
            addresse: "Addresse",
            sender: "Sender",
            gallery: "Gallery",
            news: "News",
            scores: "Scores",
            executionTime: "Date of execution",
            premiereDate: "Release Date",
            orderingPerson: "Ordering person",
            lyricsAuthor: "Lyrics author",
            praPlace: "Premiere place",
            orderingInstitution: "Ordering institution",
            disjunction: "Records disjunction",
            unknown: "Unknown",
            video: "Wideo",
            circulationNumber: "Circulation",
            prefaceForeword: "Preface/Introduction",
            language: "Language",
            financedBy: "Financed by",
            editors: "Editors,",
            shopURL: "Link to the store",
            shopURLTxt: "Go to shop",
            compositionCheckbox: "Compositions with a description",
            signature : "Signature"
        };
    }
    return list;
}
