import React, { useState, useEffect } from "react";
import { Link, useParams, useRouteMatch, Route } from "react-router-dom";
import Loader from "../Loader";
import { Translations } from "../../../Translations";
import styles from './FirstEdition.module.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'

export default function FirstEdition(props) {

	let { id } = useParams();

	id = parseInt(id);

	const translation = Translations();
	const name = "FirstEdition";
	const [data, updateData] = useState(false);
	const [loading, loadingUpdate] = useState(false);
	const [texts, updateTexts] = useState(translation);

	// Gallery states
	const [lightboxOn, lightboxToggle] = useState(false);
	const [lightboxImages, lightboxImagesUpdate] = useState([]);

	console.log(`${window.apiUrl}first_edition/${id}?lang=${window.language}`);

	useEffect(() => {
		props.updateMenu(6);
		fetch(`${window.apiUrl}first_edition/${id}?lang=${window.language}`)
			.then((res) => res.json())
			.then((res) => {
				console.log(name, res);
				updateData(res);
				initGallery(".gallery-text img", lightboxImages, lightboxToggle);
				if(mobile()){
					const text = document.getElementsByClassName("text-wrapper")
					text[0].scrollIntoView(); 
				}
			});

		return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
	}, []);

    // Gallery function
    const closeLightbox = () => lightboxToggle(false);
    

	return data ? (
		<div className="first-edition--single gallery-text">
			<div>
				<h1 className={styles.mainTitle} dangerouslySetInnerHTML={{ __html: data.title }} />
			</div>

			<div className="first-edition-single--table">
				{data.titlepage ? (
					<div>
						<div className={styles.label}>{texts.titlePage}</div>
						<div dangerouslySetInnerHTML={{ __html: data.titlepage }} />
					</div>
				) : null}

				{data.dedications.length ? (
					<div>
						<div className={styles.label}>{texts.dedications}</div>
						<div>
							{data.dedications.map(function (item, index) {
								return <div key={index}>{item.name}</div>;
							})}
						</div>
					</div>
				) : null}
				{data.publishinghouse ? (
					<div>
						<div className={styles.label}>{texts.publishHouse}</div>
						<div>{data.publishinghouse}</div>
					</div>
				) : null}

				{data.date ? (
					<div>
						<div className={styles.label}>{texts.publishDate}</div>
						<div>{data.date}</div>
					</div>
				) : null}

				{data.country ? (
					<div>
						<div className={styles.label}>{texts.publishPlace}</div>
						<div>
							<span>{data.country}, </span>
							<span>{data.place}</span>
						</div>
					</div>
				) : null}

				{data.publishingnumber ? (
					<div>
						<div className={styles.label}>{texts.publishNr}</div>
						<div>{data.publishingnumber}</div>
					</div>
				) : null}

				{data.numberpages ? (
					<div>
						<div className={styles.label}>{texts.pagesAmout}</div>
						<div>{data.numberpages}</div>
					</div>
				) : null}

				{data.format ? (
					<div>
						<div className={styles.label}>{texts.format}</div>
						<div>{data.format}</div>
					</div>
				) : null}

				{data.text ? (
					<div>
						<div className={styles.label}>{texts.comments}</div>
						<div dangerouslySetInnerHTML={{ __html: data.text }} />
					</div>
				) : null}

				{data.reproduction ? (
					<div>
						<div className={styles.label}>{texts.reproduction}</div>
						<div
							dangerouslySetInnerHTML={{ __html: data.reproduction }}
						/>
					</div>
				) : null}
			</div>
		
			{data.other_editions.length ? (
				<div>
					<div className={styles.label}>{texts.otherPublications}</div>
					<div>
						{data.other_editions.map(function (item, index) {
							return (
								<div key={index} className="list-element" >
									<span
										dangerouslySetInnerHTML={{
											__html: item.title,
										}}
									/>
									<span>| {item.country} |</span>
									<span> {item.publishinghouse}</span>
								</div>
							);
						})}
					</div>
				</div>
			) : null}

			{data.humdrums.length ? (
				<div>
					<div className={styles.label}>{texts.notes}</div>
					<div>
						{data.humdrums.map(function(item,index){
							return(
								<div key={index} className="list-element">
									<a target="_blank" href={`https://chopinscores.org/index.php/partytura/${item.id}`}>
										{item.title}
									</a>
								</div>
							)
						})}
					</div>
				</div>
			) : null}
			{lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
		</div>
	) : <Loader />
}