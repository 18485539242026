import React, {useState, useEffect} from 'react'
import styles from './Gallery.module.css'

export default function Gallery(props) {

    const [galleryLength, updateGalleryLength] = useState(props.images.length)

    const [elemIndex, elemIndexUpdate] = useState(0);

    useEffect(() => {
        
    }, [elemIndex])

    const prevImg = e => {
        e.preventDefault();
        if (elemIndex > 0){
            elemIndexUpdate(elemIndex - 1)
        }
    }

    const nextImg = e => {
        e.preventDefault();
        if(elemIndex < galleryLength - 1) {
            elemIndexUpdate(elemIndex + 1)
        }
    }

    
    return (
        <div className={styles.gallery}>
            <button disabled={elemIndex < 1} onClick={prevImg} className={styles.left}></button>
            <div className={styles.innerWrapper}>
                <div className={styles.secondaryInnerWrapper}>
                    <img src={props.images[elemIndex].src} alt="" />
                    {props.images[elemIndex].description && props.images[elemIndex].description.length ? (
                        <div className={styles.description}>{props.images[elemIndex].description}</div>
                    ) : null}
                </div>
            </div>
            <button disabled={elemIndex == galleryLength - 1} onClick={nextImg} className={styles.right}></button>
            <button onClick={props.close} className={styles.close}></button>
        </div>
    )
}