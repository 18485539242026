import React, {useState, useEffect} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {Translations} from '../../../Translations'
import Loader from '../Loader'
import styles from '../Composition/Composition.module.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'

export default function FilmographyItem(props) {

    let { id } = useParams();
    id = parseInt(id);

    const translation = Translations();

    const name = "FilmographyItem";
    const [data, updateData] = useState(false);
    const [loading, loadingUpdate] = useState(false);
    const [texts, updateTexts] = useState(translation);
   

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 300);
        props.updateMenu(8);
        fetch(`${window.apiUrl}film/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            initGallery(".gallery-text img", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [])

    // Gallery function
    const closeLightbox = () => lightboxToggle(false)
    

    return (
        data ? (
            <div className="composition-single">
                <div>
                    <h1 className={styles.mainTitle} dangerouslySetInnerHTML={{__html: `${data.title ? data.title : ""} ${data.opus ? data.opus : ""}`}}/ >
                </div>

                {data.image ? (
                    <div>
                        <img style={{maxWidth:"100%"}} src={data.image} alt=""/>
                    </div>
                ) : null}

                {data.txt ? (
                    <div>
                        <div className={`${styles.mainText} gallery-text`}>
                            <div dangerouslySetInnerHTML={{__html: data.txt}}/>
                        </div>
                    </div>
                ) : null}

                <div className="composition-single--table filmography-table">
                    {data.year ? (
                        <div>
                            <div className={styles.label}>{texts.year}</div>
                            <div>{data.year}</div>
                        </div>
                    ) : null}
                    {data.copy ? (
                        <div>
                            <div className={styles.label}>{texts.copy}</div>
                            <div dangerouslySetInnerHTML={{__html: data.copy}} />
                        </div>
                    ) : null}
                    {data.other ? (
                        <div>
                            <div className={styles.label}>{texts.others}</div>
                            <div dangerouslySetInnerHTML={{__html: data.other}} />
                        </div>
                    ) : null}
                    {data.technical_data ? (
                        <div>
                            <div className={styles.label}>{texts.techData}</div>
                            <div dangerouslySetInnerHTML={{__html: data.technical_data}} />
                        </div>
                    ) : null}
                    {data.institutions.length ? (
                        <div>
                            <div className={styles.label}>{texts.production}</div>
                            {data.institutions.map(function(item, index){
                                return(
                                    <div key={index}>{item.name}</div>
                                )
                            })}
                        </div>
                    ) : null}
                    {data.people.length ? (
                        data.people.map(function(item,index){
                            return(
                                <div key={index}>
                                    <div className={styles.label}>{item.type}</div>
                                    <div>{item.first_name} {item.last_name}</div>
                                </div>
                            )
                        })
                    ) : null}
                </div>
                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
            </div>
        ) : <Loader />
    )
}