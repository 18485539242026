import React, { useState, useEffect } from "react";
import { Link, useParams, useRouteMatch, Route } from "react-router-dom";
import Loader from "../Loader";
import { Translations } from "../../../Translations";
import styles from "./Manuscript.module.css";
import Gallery from "../Gallery";
import { mobile, initGallery, unmountGallery } from "../../../Helpers";
import { parseLink } from "../../../Helpers";
export default function Manuscript(props) {
    let { id } = useParams();
    id = parseInt(id);

    const translation = Translations();
    const name = "Manuscript";
    const [data, updateData] = useState(false);
    const [loading, loadingUpdate] = useState(false);
    const [texts, updateTexts] = useState(translation);

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    // console.log(`${window.apiUrl}manuscript/${id}?lang=${window.language}`)

    useEffect(() => {
        props.updateMenu(5);
        fetch(`${window.apiUrl}manuscript/${id}?lang=${window.language}`)
            .then((res) => res.json())
            .then((res) => {
                // console.log(name, res);
                updateData(res);
                initGallery(
                    ".gallery-text img",
                    lightboxImages,
                    lightboxToggle
                );

                if (mobile()) {
                    const text =
                        document.getElementsByClassName("text-wrapper");
                    text[0].scrollIntoView();
                }
            });

        return () =>
            unmountGallery(".gallery-text img", lightboxImages, lightboxToggle);
    }, []);

    // Gallery function
    const closeLightbox = () => lightboxToggle(false);

    console.log(data);
    const urlPops =
        "https://polish.musicsources.pl/pl/lokalizacje/galeria/rekopisy/";

    return data ? (
        <div className="manuscript-single gallery-text">
            <h1
                className={styles.mainTitle}
                dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
                className={styles.mainText}
                dangerouslySetInnerHTML={{ __html: data.txt }}
            />

            <div className="manuscript-table">
                {data.manuscript_title_on_source ? (
                    <div>
                        <div className={styles.label}>
                            {texts.titleOnSource}
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.manuscript_title_on_source,
                            }}
                        />
                    </div>
                ) : null}
                {data.manuscripts_category ? (
                    <div>
                        <div className={styles.label}>{texts.sourceType}</div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.manuscripts_category,
                            }}
                        />
                    </div>
                ) : null}
                {data.copyists.length ? (
                    <div>
                        <div className={styles.label}>{texts.copyist}</div>
                        <div>
                            {data.copyists.map(function (item, index) {
                                return <div key={index}>{item.name}</div>;
                            })}
                        </div>
                    </div>
                ) : null}
                {data.lyrics_authors.length ? (
                    <div>
                        <div className={styles.label}>
                            {texts.authorOfWords}
                        </div>
                        <div>
                            {data.lyrics_authors.map(function (item, index) {
                                return <div key={index}>{item.name}</div>;
                            })}
                        </div>
                    </div>
                ) : null}
                {data.size ? (
                    <div>
                        <div className={styles.label}>{texts.sizes}</div>
                        <div>{data.size}</div>
                    </div>
                ) : null}
                {data.number_of_pages ? (
                    <div>
                        <div className={styles.label}>{texts.pagesAmout}</div>
                        <div>{data.number_of_pages}</div>
                    </div>
                ) : null}
                {data.human_date_source_creation ? (
                    <div>
                        <div className={styles.label}>
                            {texts.sourceCreationDate}
                        </div>
                        <div>{data.human_date_source_creation}</div>
                    </div>
                ) : null}
                {data.place_of_storage_institution ? (
                    <div>
                        <div className={styles.label}>{texts.storagePlace}</div>
                        <div>{data.place_of_storage_institution} | {data.place} | {data.country}</div>
                    </div>
                ) : null}
                {data.signature ?
                    <div>
                        <span className={styles.label}>{texts.signature}</span>
                        <span>{data.signature}</span>
                    </div>
                    : null
                }
            </div>
            <div>
                {data.first_editions.length ? (
                    <div>
                        <div className={styles.label}>{texts.firstEdition}</div>
                        <div>
                            {data.first_editions.map(function (item, index) {
                                return (
                                    <div key={index} className="list-element">
                                        <span>{item.title} | </span>
                                        <span>{item.country}| </span>
                                        <span>{item.publisher}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}

                <div>
                    {data.image ? (
                        <div className="list-wrapper">
                            <a
                                className="list-element"
                                href={`${urlPops}${id}-${parseLink(
                                    data.title
                                )}/1`}
                                target="blank"
                            >
                                <div
                                    className={styles.manuscriptImage}
                                    style={{
                                        backgroundImage: `url(${data.image ? data.image : ""
                                            })`,
                                    }}
                                />
                            </a>
                        </div>
                    ) : null}
                </div>

                {data.compositions.length ? (
                    <div>
                        <div className={styles.label}>{texts.compositions}</div>
                        <div className="manuscript-single--compositions">
                            {data.compositions.map(function (item, index) {
                                return (
                                    <div key={index} className="list-element">
                                        <Link
                                            to={`${props.url}/kompozycje/${item.id}`}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                }}
                                            />
                                            <span> {item.opus}</span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}
            </div>

            {lightboxOn ? (
                <Gallery images={lightboxImages} close={closeLightbox} />
            ) : null}
        </div>
    ) : (
        <Loader />
    );
}
