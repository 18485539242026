import React, { useEffect, useState} from 'react'
import {useParams} from "react-router-dom"
import Loader from '../Elements/Loader'
import Gallery from '../Elements/Gallery'
import styles from './Multimedia.module.css'
import placeholder from '../../assets/img/placeholder.jpg'
import {minHeight, mobile, initGallery, unmountGallery} from '../../Helpers'

export default function MultimediaItem(props) {

    const name = 'MultimediaItem'

    // All data
    const [data, updateData] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);

    // Gallery functions & 'variables'
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    const closeLightbox = () => {
        lightboxToggle(false)
    }

    let { id } = useParams();

    id = parseInt(id);

    let multimediaLink = `${window.apiUrl}multimedia/${id}?lang=${window.language}`

    useEffect(() => {
        minHeight();
        props.updateMenu(10);
        isLoadingUpdate(true)
        fetch(multimediaLink)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            isLoadingUpdate(false);
            initGallery(".gallery-item", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })
        .catch(err => { throw err });

        return () => unmountGallery(".gallery-item", lightboxImages, lightboxToggle)
    }, [id])

    const toggleMusic = e => {
        const players = document.querySelectorAll(".audio-item");
        players.forEach(function(item,index){
            if(e === index){
                item.play()
            } else {
                item.pause()
            }
        })
    }
   

    return (
        <main>
            {data ? (
                <div className="main-wrapper">
                    {!isLoading ? (
                        <div className={styles.mainWrapper}>
                            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: data.title }}/>

                            {data.subtitle ? (
                                <div className={styles.itemSubtitle} dangerouslySetInnerHTML={{ __html: data.subtitle }}/>
                            ) : null}

                            {data.text ? (
                                <div className={styles.itemText} dangerouslySetInnerHTML={{ __html: data.text }}/>
                            ) : null}

                            {data.galleries && data.galleries.length ? (
                                <div>
                                    <div id="gallery-wrapper">
                                        {data.galleries.map(function(item,index){
                                            const image = item.image ? item.image : placeholder;
                                            return(
                                                <div className="gallery-item" key={index} src={image} description={item.description && item.description.length ? item.description : undefined} style={{backgroundImage: `url(${image})`}}></div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : null}
                            
                            {data.audio && data.audio.length ? (
                                <div>
                                    <h3 className={styles.title}>Audio</h3>
                                    <div id="audio-wrapper">
                                        {data.audio.map(function(item,index){
                                            return(
                                                <div key={index}>
                                                    {item.title ? (
                                                        <div className={styles.elemTitle}>
                                                            {item.title}
                                                        </div>
                                                    ) : null}
                                                    {item.author ? (
                                                        <div className={styles.itemDate}>
                                                            {item.author}
                                                        </div>
                                                    ) : null}
                                                    <audio onPlay={toggleMusic.bind(null, index)} className={`audio-item audio-${index}`} src={item.audio} controls></audio>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        
                            {data.videos && data.videos.length ? (
                                <div>
                                    <div id="video-wrapper">
                                        {data.videos.map(function(item,index){
                                            return(
                                                <div className={styles.videoItem} key={index}>
                                                    <iframe title={item.title} width="100%" height="315" allowfullscreen="allowfullscreen" src={`https://www.youtube.com/embed/${item.youtube_id}`}></iframe>
                                                    <details className={styles.detail}>
                                                        <summary>{item.title}</summary>
                                                        <div className={styles.itemDetail} dangerouslySetInnerHTML={{ __html: item.description }}/>
                                                    </details>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : null}
                            {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
                        </div>
                    ) : <Loader />}
                </div>
            ) : <Loader />}
        </main>
    )
}