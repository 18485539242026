import React from 'react'
import imgPlaceholder from '../../../assets/img/placeholder-dmp.jpg'
import './ComposerBox.css'

export default function ComposerBox(props) {

    return (
        <div className={`composer-box bg-cover`} style={{ backgroundImage: `url(${props.info.image || props.info.alt_image ? props.info.image || props.info.alt_image : imgPlaceholder})`}}>
            <div className="type">{props.info.type}</div>
            <div>
                <div className="name">{props.info.first_name +" "+ props.info.last_name}</div>
                <div className="data">{props.info.date}</div>
            </div>
        </div>
    )
}
