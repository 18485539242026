import React, {useState, useEffect} from 'react'
import {useParams, Link} from "react-router-dom"
import Loader from '../../Loader'
import Gallery from '../../Gallery'
import { Translations } from '../../../../Translations'
import {mobile, initGallery, unmountGallery, parseLink} from '../../../../Helpers'

export default function Genre(props) {

    console.log("Genre props: ", props.data)

    let { id } = useParams();    
    id = parseInt(id)

    const translation = Translations();

    // Texts states
    const [text, updateText] = useState("");
    const [title, updateTitle] = useState("");
    const [compositions, updateCompositions] = useState(false);
    const [texts, updateTexts] = useState(translation);

    	// Gallery states
	const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    // Gallery function
    const closeLightbox = () => {
        lightboxToggle(false)
    }
    
    useEffect(() => {
        return () => props.updateSubmenu(false)
    }, [])

    useEffect(() => {
        props.updateMenu(3);
        props.data.forEach(function(item){
            if(item.id == id){
                updateText(item.txt)
                updateTitle(item.name)
                updateCompositions(item.compositions)
                props.updateSubmenu(item.id)
            } else {
                item.genres.forEach(function(genre){
                    if(genre.id == id){
                        updateText(genre.txt)
                        updateTitle(genre.name)
                        updateCompositions(genre.compositions)
                        props.updateSubmenu(genre.id)
                    }
                })
            }
        })
    }, [id])

    // Gallery effect / update
    useEffect(() => {
        initGallery(".gallery-text img", lightboxImages, lightboxToggle);

        if(mobile()){
            const text = document.getElementsByClassName("text-wrapper")
            text[0].scrollIntoView(); 
        }

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [text])

    
    return (
        props.data.length ? (
            <div className="gallery-text">
                <div className="title">{title}</div>
                <div className="text" dangerouslySetInnerHTML={{ __html: text }} />parseLink
                {compositions ? (
                    <div style={{padding: '20px 10px'}}>
                        <h3 className="title" style={{padding: '0'}}>{texts.compositions}</h3>
                        {compositions.map(function(item,index){
                            return(
                                <Link key={index} to={`../kompozycje/${item.id}-${parseLink(item.title)}`}>
                                    <div style={{ fontSize: '20px', display: 'block', padding: '5px', marginBottom: '10px'}} dangerouslySetInnerHTML={{ __html: `${item.title}  ${item.opus ? item.opus : ""}` }} />
                                </Link>
                            )
                        })}
                    </div>
                ) : null}
                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
            </div>) : <Loader />
    )
}