import React, { useEffect, useState } from "react";
import Header from "./Components/Header";
import Main from "./Components/Main";
import AllSearch from "./Components/AllSearch";
import ContactPage from "./Components/ContactPage";
import Footer from "./Components/Footer";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import AllCompositions from "./Components/AllCompositions";
import AllBibliographies from "./Components/AllBibliographies";
import AllMultimedia from "./Components/AllMultimedia";
import AllIconographies from "./Components/AllMultimedia/AllIconographies";
import AllAudios from "./Components/AllMultimedia/AllAudios";
import AllVideos from "./Components/AllMultimedia/AllVideos";
import MainPage from "./Components/MainPage";
import NewsItem from "./Components/MainPage/NewsItem";
import AllGenres from "./Components/AllGenres";
import AllGenresItem from "./Components/AllGenres/AllGenresItem";
import Object from "./Components/Object";
import Place from "./Components/Elements/Place";
import ReactGA from "react-ga4";
import DeclarationPage from "./Components/DeclarationPage";

// Static data passed for main page
const mainData = {
    mainPage: true,
};

// Static data passed for sub pages
const subData = {
    // Data for LangSwitch component
    social: [
        {
            name: "Facebook",
            url: "https://pl-pl.facebook.com/pages/category/Museum/Narodowy-Instytut-Fryderyka-Chopina-1570915943228919/",
        },
        {
            name: "NIFC",
            url: "https://nifc.pl/pl",
        },
    ],

    mainPage: false,
};

window.apiUrl = "https://api-pmp.nifc.pl/index.php/";
window.apiUrlPopc = 'https://api.nifc.pl/v2/index.php/manuscripts/get_manuscript_by_id/';
window.activeSearch = "";

window.localStorage.getItem("lang")
    ? window.localStorage.getItem("lang")
    : window.localStorage.setItem("lang", "pl");

console.log(window.localStorage.getItem("lang"));

window.language = window.localStorage.getItem("lang");

window.chopinUrl = `https://chopin.nifc.pl/${window.language}/chopin`;

function Builder() {
    ReactGA.initialize("G-B2KXDYKM6D");
    ReactGA.send("pageview");

    useEffect(() => {}, []);

    if (window.location.pathname === "/") {
        if (window.localStorage.getItem("lang")) {
            window.location.href = `/${localStorage.getItem("lang")}`;
        } else {
            window.location.href = "/pl";
        }
    }

    const [screenWidth, screenWidthUpdate] = useState(window.innerWidth);

    window.addEventListener("resize", function () {
        if (screenWidth < 1200) {
            if (window.innerWidth >= 1200) {
                window.location = window.location;
            }
        } else {
            if (window.innerWidth < 1200) {
                window.location = window.location;
            }
        }
    });

    window.addEventListener("keypress", (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (window.activeSearch === "single") {
                if (document.getElementById("single-submit")) {
                    document.getElementById("single-submit").click();
                }
            } else if (window.activeSearch === "secondary") {
                if (document.getElementById("submit-comp")) {
                    document.getElementById("submit-comp").click();
                }
            } else {
                console.log("Brak aktywnej wyszukiwarki");
            }
        }
    });

    return (
        <>
            <Router>
                <Switch>
                    <Route path={`/:lang(pl|en)/osoba/:id`}>
                        <Header data={subData} />
                        <Main data={subData} type="default" />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/miejsce/:id`}>
                        <Header data={subData} />
                        <Place />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/multimedia/audio`}>
                        <Header data={subData} />
                        <AllAudios />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/multimedia/wideo`}>
                        <Header data={subData} />
                        <AllVideos />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/multimedia/ikonografie`}>
                        <Header data={subData} />
                        <AllIconographies />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/multimedia`}>
                        <Header data={subData} />
                        <AllMultimedia />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/wyszukiwarka`}>
                        <Header data={subData} />
                        <AllSearch />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/wydawnictwa`}>
                        <Header data={subData} />
                        <AllBibliographies />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/kompozycje`}>
                        <Header data={subData} />
                        <AllCompositions />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/gatunki/:id`}>
                        <Header data={subData} />
                        <AllGenresItem />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/gatunki`}>
                        <Header data={subData} />
                        <AllGenres />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/kompozytorzy`}>
                        <Header data={mainData} />
                        <Main data={mainData} />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/news/:id`}>
                        <Header data={subData} />
                        <NewsItem />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/objekt`}>
                        <Header data={subData} />
                        <Object />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/aktualnosci`}>
                        <Header data={subData} />
                        <MainPage />
                        <Footer />
                    </Route>

                    <Route path={`/:lang(pl|en)/o-projekcie`}>
                        <Header data={subData} />
                        <ContactPage />
                        <Footer />
                    </Route>

                    <Route exact path={`/:lang(pl|en)`}>
                        <Header data={mainData} />
                        <Main data={mainData} />
                        <Footer />
                    </Route>

                    <Route exact path={`/:lang(pl|en)/deklaracja-dostepnosci`}>
                        <Header data={subData} />
                        <DeclarationPage />
                        <Footer />
                    </Route>

                    <Route exact path={`*`}>
                        <Redirect to="/pl" />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}
export default Builder;
