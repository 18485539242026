import React, { useEffect, useState } from 'react'
import {useHistory} from "react-router-dom"
import './LangSwitch.css';
import Logo_Fb from '../../../assets/img/Logo_Fb.png'

export default function LangSwitch(props) {

    // History method for redirect
    let history = useHistory()

    const data = props.data;

    const name ="LangSwitch"

    const [lang, langUpdate] = useState(window.language)

    const langUpdateHandler = param => {
        langUpdate(param);
        window.localStorage.setItem("lang", param);
        window.language = param;
        let path = window.location.pathname.split('/');
        path.splice(0,2);
        const formatedUrl = path.join("/")
        history.push({ pathname: `/${param}/${formatedUrl}`});
        window.location.reload();
    }

    useEffect(() => {

    }, [lang])

    return (
        <div id="LangSwitch">
            {data.social ? (
                <div className="social-wrapper">
                    {data.social.map((item,index)=>{
                        if(item.name === "Facebook") {
                            return(
                                <div key={index} style={{position:"relative", paddingRight:"10px"}}>
                                    <a rel="noopener noreferrer" target="_blank" href={item.url}><img style={{height:"18px", position:"absolute", bottom:"-3px"}} src={Logo_Fb} alt={""} /></a>
                                </div>
                            )
                        } else {
                            return(
                                <div key={index}>
                                    <a rel="noopener noreferrer" target="_blank" href={item.url}>{item.name}</a>
                                </div>
                            )
                        }
                    })}
                </div>
            ) : null}
            <div style={{display:"flex", flexFlow:"row",alignItems:"center"}}>
                <button onClick={() => langUpdateHandler("pl")} className={lang == "pl" ? "active" : ""}>PL</button>
                <button onClick={() => langUpdateHandler("en")} className={lang == "en" ? "active" : ""}>EN</button>
            </div>
        </div>
    )
}
