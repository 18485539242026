import React, { useEffect, useState, useContext } from "react";
import "./Contact.css";
import Loader from "../Elements/Loader";
import { minHeight } from "../../Helpers";
import { MSEContext } from "../Context/MainSearchEngineContext";

export default function ContactPage() {
    const name = "ContactPage";
    const [data, updateData] = useState(false);

    // Getting context
    const showContext = useContext(MSEContext);

    showContext.updateMenuPositionHandler(6);

    useEffect(() => {
        fetch(`${window.apiUrl}page/4?lang=${window.language}`)
            .then((res) => res.json())
            .then((res) => {
                updateData(res);
                console.log(name, res);
                minHeight();
            });
    }, []);

    return data ? (
        <main id="contact">
            <h1>{data.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.txt }} />
        </main>
    ) : (
        <Loader />
    );
}
