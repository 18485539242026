import React, {useState, useEffect} from 'react'
import './Multiselect.css'
import useOnclickOutside from "react-cool-onclickoutside";
import { Translations } from "../../../Translations";

export default function Multiselect(props) {

    const translation = Translations();
    
    const [opened, updateOpened] = useState(false)
    const [value, updateValue] = useState([])
    const [name, updateName] = useState([])
    const [data, updateData] = useState(props.label && props.label.length ? JSON.parse(props.label) : [])
    const [listData, updateListData] = useState([])
    const [texts, updateTexts] = useState(translation);
    const [searchType, setSearchType] = useState(false);
    const limit = 20;
    const [loader, setLoader] = useState(false)

    // Creating reference to input
    const listRef = React.createRef();

    const wrapperRef = useOnclickOutside(() => {
        updateOpened(false);
      });

    const inputUpdate = e => {
        updateName(e.target.value)
        if(e.target.value.length){
            updateOpened(true)
        }
    }

    const openListHandler = e => {
        if (e.target.classList.value.indexOf("box") > -1){
            updateOpened(false)
        } else {
            updateOpened(true)
        }
    }

    const removeItem = itemName => {
        const result = data.filter(({ name }) => !name.includes(itemName));
        updateData(result)
    }

    const choice = (newValue , newName) => {
        if (data.some(author => author.name == newName)) {
            const result = data.filter(({ name }) => !name.includes(newName));
            updateData(result)
        } else {
            updateValue(value => [...value, newValue]);
            updateData(data => [...data, { "name": newName, "value": newValue }])
        }

        listRef.current.querySelectorAll(".multiselect-record").forEach(function (item) {
            item.style.display = 'block'
        })
    }

    useEffect(() => {
        if (props.dataUrl) {
            setLoader(true)
            
            if(props.limited){
                fetch(props.dataUrl+`&limit=${limit}&search=${name.length ? name : ""}`)
                .then(res => res.json())
                .then((res)=>{
                    listData.length = 0;
                    listData.push({"name": texts.every, "value": false})
                    res.map(function(item,index){
                        return(
                            listData.push({"name": item.name, "value": item.id})
                            );
                    })
                })
                .then(setLoader(false))
            } else {
                fetch(props.dataUrl)
                .then(res => res.json())
                .then((res)=>{
                    listData.length = 0;
                    listData.push({"name": texts.every, "value": false})
                    res.map(function(item,index){
                        return(
                            listData.push({"name": item.name, "value": item.id})
                        );
                    })
                })
                .then(setLoader(false))
            }
        }
    }, [])

    // AND / OR search type change listener
    useEffect(() => {
        if (props.searchType) {
            props.searchType(searchType)
        }
    }, [searchType])
    
    useEffect(() => {
        if(props.dataUrl){
            if(!props.limited){
                listRef.current.querySelectorAll(".multiselect-record").forEach(function(item){
                    if(item.innerText.toLowerCase().includes(name.toString().toLowerCase())){
                        item.style.display = 'block'
                    } else {
                        item.style.display = 'none'
                    } 
                })
            } else {
                setLoader(true)
                fetch(props.dataUrl+`&limit=${limit}&search=${name.length ? name : ""}`)
                .then(res => res.json())
                .then((res)=>{
                    console.log(props.dataUrl+`&limit=${limit}&search=${name.length ? name : ""}`)
                    updateListData([])
                    res.map(function({name,id}){
                        updateListData(listData => [...listData, {"name": name, "value": id}]); 
                    })
                })
                .then(setLoader(false))
            }
        }
    }, [name])

    useEffect(() => {
        if (props.change) {
            props.change(data)
        }
}, [data, listData])


    return (
        <div ref={wrapperRef} className={`custom-multiselect ${props.small ? "small" : ""} ${props.medium ? "medium" : ""} ${props.searchType ? "searchtype" : ""}`}>
            <div className={`values ${props.all ? "all" : ""}` } onClick={openListHandler}>
                {data.length ? (data.map(({name, value}, index) => {
                    return(
                        <div key={index} className="box" onClick={() => removeItem(name)}>{name}</div>
                    )
                })) : null}
            </div>
            <div className={`multi-list ${opened ? "opened" : ""}`}>
                <input className="search" placeholder={texts.search} onChange={inputUpdate.bind(this)} onClick={inputUpdate.bind(this)} type="text" />

                <div ref={listRef} className={`multiselect-records ${loader ? "loading" : ""}`}>
                    {listData.map(function (item, index) {
                        const selected = (data.filter(e => e.name === item.name).length > 0) ? "selected" : ""
                        if(item.value == null || !item.value){
                            return
                        } else {
                            return (
                                <div className={`multiselect-record ${selected}`} key={index} onClick={choice.bind(null, item.value, item.name)} value={item.value}>{item.name}</div>
                            )
                        }
                    })}
                </div>
            </div>

            {/* Search options are displaying only when alternative props is given */}

            {props.searchType ? (
                <div className="search-options">
                    <div onClick={() => setSearchType(!searchType)} className={`search-option ${!searchType ? "selected" : ""}`}>
                        {texts.disjunction}
                    </div>
                </div>
            ) : null}
        </div>
    )
}