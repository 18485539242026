import React, { useEffect, useState, useContext} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import Loader from '../Elements/Loader'
import {Translations} from '../../Translations'
import Gallery from '../Elements/Gallery'
import styles from './News.module.css'
import placeholder from '../../assets/img/placeholder.jpg'
import {minHeight, initGallery, unmountGallery} from '../../Helpers'

export default function NewsItem() {

    const name = 'NewsItem'

    // Languages
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // All data
    const [data, updateData] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);

    // Gallery functions & 'variables'
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    const closeLightbox = () => lightboxToggle(false);

    let { id } = useParams();

    let newsUrl = window.location.pathname;
    newsUrl = newsUrl.slice(3, newsUrl.length)

    let NewsLink = `${window.apiUrl}${newsUrl}?lang=${window.language}`

    useEffect(() => {
        minHeight()
        console.log(NewsLink)
        isLoadingUpdate(true)
        fetch(NewsLink)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            isLoadingUpdate(false);
            initGallery(".gallery-item", lightboxImages, lightboxToggle);
        })
        .catch(err => { throw err });

        return () => unmountGallery(".gallery-item", lightboxImages, lightboxToggle)
    }, [id])

   
    return (
        <main>
            {data ? (
                <div>
                    <div className={styles.navigation}>
                        <Link className="back" to={`/${window.language}/aktualnosci`}>{texts.news}</Link>
                    </div>
                    <div className="main-wrapper">
                        {!isLoading ? (
                            <div className={styles.mainWrapper}>
                                <h1 className={styles.title}>{data.title}</h1>
                                {data.publication_date ? (
                                    <div className={styles.itemDate}>{data.publication_date}</div>
                                ) : null}
                                {data.image ? (
                                    <div src={data.image} className={`gallery-item ${styles.newsImage}`}>
                                        <div className={styles.imgHolder} style={{backgroundImage: `url(${data.image})`}}></div>
                                    </div>
                                ) : null}
                                {data.subtitle ? (
                                    <div className={styles.itemSubtitle}>{data.subtitle}</div>
                                ) : null}

                                {data.text ? (
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.text }} />
                                ) : null}

                                {data.galleries && data.galleries.length ? (
                                    <div>
                                        <h3 className={styles.title}>{texts.gallery}</h3>
                                        <div id="gallery-wrapper">
                                            {data.galleries.map(function(item,index){
                                                const image = item.image ? item.image : placeholder;
                                                return(
                                                    <div className={`gallery-item`} key={index} src={image} style={{backgroundImage: `url(${image})`}}></div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                            
                                {data.audio && data.audio.length ? (
                                    <div>
                                        <h3 className={styles.title}>Audio</h3>
                                        <div id="audio-wrapper">
                                            {data.audio.map(function(item,index){
                                                return(
                                                    <div key={index}>
                                                        {item.title ? (
                                                            <div className={styles.elemTitle}>
                                                                {item.title}
                                                            </div>
                                                        ) : null}
                                                        {item.author ? (
                                                            <div className={styles.itemDate}>
                                                                {item.author}
                                                            </div>
                                                        ) : null}
                                                        <audio src={item.audio} controls></audio>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                            
                                {data.videos && data.videos.length ? (
                                    <div>
                                        <h3 className={styles.title}>Video</h3>
                                        <div id="video-wrapper">
                                            {data.videos.map(function(item,index){
                                                return(
                                                    <div key={index}>
                                                        <iframe title={item.title} width="100%" height="315" src={`https://www.youtube.com/embed/${item.youtube_id}`}></iframe>
                                                        <details className={styles.detail}>
                                                            <summary>{item.title}</summary>
                                                            <div className={styles.itemDetail} dangerouslySetInnerHTML={{ __html: item.description }}/>
                                                        </details>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
                            </div>
                        ) : <Loader />}
                    </div>
                </div>
            ) : <Loader />}
        </main>
    )
}