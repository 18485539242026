import React, {useEffect} from 'react';
import {useParams} from "react-router-dom"
import LangSwitch from './LangSwitch'
import Menu from './Menu'
import Logo from './Logo'
import SingleSearch from '../Elements/SingleSearch'
import TopSearchEngine from '../Elements/TopSearchEngine'
import './Header.css'


export default function Header(props) {

    let {lang} = useParams();

    if(lang){
        window.localStorage.setItem("lang", lang)
        window.language = lang
    }

    useEffect(() => {
        console.log("Lang to:", lang)
    }, [lang])

    let url = window.location.href.split("/");

    switch (url[4]) {
        case 'osoba':
            let person = url[5].split("-")
            fetch(`https://api-pmp.nifc.pl/index.php/person/${person[0]}?lang=${url[3]}`)
                .then(res => res.json())
                .then((res) => {

                    document.title = res.first_name + " " + res.last_name ;
                })
            break;
        case 'news':
            fetch(`https://api-pmp.nifc.pl/index.php//news/pmp/${url[6]}?lang=${url[3]}`)
                .then(res => res.json())
                .then((res) => {
                    document.title = res.title;
                })
            break;
        case 'gatunki':
            if (url.length === 5) {
                document.title = "Dziedzictwo muzyki Polskiej";
            } else {
                fetch(`https://api-pmp.nifc.pl/index.php//compositions/genre/${url[5]}?lang=${url[3]}`)
                    .then(res => res.json())
                    .then((res) => {
                        document.title = res.name;
                    })
            }
            break;
        case 'miejsce':
            if (url.length === 5) {
                document.title = "Dziedzictwo muzyki Polskiej";
            } else {
                fetch(`https://api-pmp.nifc.pl/index.php/place/get_place/${url[5]}?lang=${url[3]}`)
                    .then(res => res.json())
                    .then((res) => {
                        document.title = res.name;
                    })
            }
            break;
        default:
            document.title = "Dziedzictwo muzyki Polskiej"

    }
    
    const data = props.data

    // Class added to header if page is main page
    const mainPage = props.data.mainPage ? "mainPage bg-cover" : "";
    const mainPage2 = props.data.mainPage ? "mainPage" : "subPage";

    // SingleSearch component only for subpages
    const singleSearch = props.data.mainPage ? <div></div> : <SingleSearch />;

    // TopSearchEngine component only on main page
    const topSearchEngine = props.data.mainPage ? (<TopSearchEngine />) : null


    return (
        <header className={mainPage}>
                <LangSwitch data={data}  />
                <div className={`header-wrapper ${mainPage2}`}>
                    <Logo data={data} />
                    {singleSearch}
                    <Menu />
                </div>
                
                {topSearchEngine}
        </header>
    )
}
