import React, {useState, useEffect} from 'react'
import {Link, useRouteMatch, useHistory} from "react-router-dom"
import Loader from '../Loader'
import Dropdown from '../Dropdown';
import {Translations} from '../../../Translations'
import {parseLink} from '../../../Helpers'
import {mobile} from '../../../Helpers'
import {Datalist} from '../Datalist'

export default function Letters(props) {

    // Just name of component
    const name = "letters";

    // Data for this component
    const [data, updateData] = useState(false);

    // History method for redirect
    let history = useHistory()

    // history.push({ pathname: `/${param}/${formatedUrl}`});

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

        // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

        // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [titlesList, updateTitles] = useState([]);
    const [sendersList, updateSenders] = useState([]);
    const [yearsList, updateYears] = useState([]);
    const [addressesList, updateAddresses] = useState([]);
    const [composersList, updateComposers] = useState([]);

    // Data selected from dropdowns
    const [titles, titlesUpdate] = useState(false);
    const [senders, sendersUpdate] = useState(false);
    const [yearFrom, yearFromUpdate] = useState(false);
    const [yearTo, yearToUpdate] = useState(false);
    const [addresses, addressesUpdate] = useState(false);
    const [composers, composersUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [sortType, updateSortType] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    const sortParams = [
        {name: texts.title+" "+texts.asc, value: "titleAsc"},
        {name: texts.title+" "+texts.desc, value: "titleDesc"},
        {name: texts.date+" "+texts.asc, value: "dateAsc"},
        {name: texts.date+" "+texts.desc, value: "dateDesc"},
        {name: texts.sender+" "+texts.asc, value: "senderAsc"},
        {name: texts.sender+" "+texts.desc, value: "senderDesc"},
        {name: texts.addresse+" "+texts.asc, value: "addresserAsc"},
        {name: texts.addresse+" "+texts.desc, value: "addresserDesc"},
    ]


    // Get data for search enginge dropdowns
    const dropdownData = () => {
        // titles
        const titlesPromise = fetch(`${window.apiUrl}letters/titles?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json(), titlesList.length = 0)
        .then((res)=>{
            titlesList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        titlesList.push({"name": item , "value": item})
                    );
            })
            // console.log("titles:", titles)
        })

        // senders
        const sendersPromise = fetch(`${window.apiUrl}letters/senders?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json(), sendersList.length = 0)
        .then((res)=>{
            sendersList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        sendersList.push({"name": item.name, "value": item.id})
                    );
            })
            // console.log("Autorzy", senders)
        })

        // addresses
        const addressesPromise = fetch(`${window.apiUrl}letters/addressees?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json(), addressesList.length = 0)
        .then((res)=>{

            addressesList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        addressesList.push({"name": item.name, "value": item.id})
                    );
            })
            // console.log("Kategorie:", addresses)
        })

        // Years
        const yearsPromise = fetch(`${window.apiUrl}letters/years?id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json(), yearsList.length = 0)
        .then((res)=>{
            yearsList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    yearsList.push({"name": item, "value": item})
                    );
            })
            // console.log("Lata:", years);
        })

        // composers
        const composersPromise = fetch(`${window.apiUrl}letters/composers?id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json(), composersList.length = 0)
        .then((res)=>{
            composersList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    composersList.push({"name": item, "value": item})
                    );
            })
            // console.log("Lata:", composers);
        })
        
        return Promise.all([titlesPromise, sendersPromise, addressesPromise, yearsPromise, composersPromise]);
    }

    let searchParams = "";
    
    if(titles){
        searchParams += `&title=${titles}`
    }

    if(senders){
        searchParams += `&id_sender=${senders}`
    }

    if(addresses){
        searchParams += `&id_addressee=${addresses}`
    }

    if(composers){
        searchParams += `&id_composer=${composers}`
    }

    if(yearFrom){
        searchParams += `&year_start=${yearFrom}`
    }

    if(yearTo){
        searchParams += `&year_stop=${yearTo}`
    }

    if(searchText){
        searchParams += `&search=${searchText}`
    }

    if(sortType){
        searchParams += `&sort=${sortType}`
    }

    const clear = e => {
        e.preventDefault();
        titlesUpdate(false);
        sendersUpdate(false);
        addressesUpdate(false);
        composersUpdate(false);
        yearFromUpdate(false);
        yearToUpdate(false);
        clearRequestUpdate(!clearRequest)
    }


    let link = `${window.apiUrl}letters?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(link)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(link)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })
        .then(()=>{
            if (searchText) {
                const texts = document.querySelectorAll(".text-searched");
                texts.forEach((item) => {
                    let array = [];
                    const regEx = new RegExp(searchText, "ig");
                    const replaceMask = searchText;
                    item.innerHTML = item.innerHTML.replace(regEx, replaceMask);
                    array = item.innerHTML.split(searchText);
                    if (array.length && array.length > 1) {
                        item.innerHTML = "..." + array[0].slice(array[0].length - 20, array[0].length) + `<span style="background: yellow">${searchText}</span>` + array[1].slice(0, 300) + "..."
                    } else {
                        item.innerHTML = item.innerHTML.substring(0,400)+"..."
                    }
                })
            }
        })
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {

        props.updateMenu(9);
        updateData(false);
        dropdownData().then(()=>{
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(link)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                if(mobile()){
                    const text = document.getElementsByClassName("text-wrapper")
                    text[0].scrollIntoView(); 
                }
            })    
        });
    },[clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if(sortType){
            loadingUpdate(true);
            setNoMore(false)
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                // console.log(object)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                loadingUpdate(false);
            })    
        }
        }, [sortType])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    console.log("path to:", path, "a url to:", url)

    const changetitles = (name, value) => {
        titlesUpdate(value)
    }

    const changesenders = (name, value) => {
        sendersUpdate(value)
    }

    const changeaddresses = (name, value) => {
        addressesUpdate(value)
    }

    const changeYearFrom = (name, value) => {
        yearFromUpdate(value)
    }

    const changeYearTo = (name, value) => {
        yearToUpdate(value)
    }

    const changeSort = (name, value) => {
        updateSortType(value)
    }



    return (
        data ? (
            <div className="compositions-wrapper">
                <div className="title">{texts.letters}</div>
                <form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase}/>
                        <div className="dropdowns-wrapper three">
                            {titlesList && titlesList.length ? (
                                <div>
                                    <div className="label">{texts.title}</div>
                                    <Datalist small data={titlesList} change={changetitles} />
                                </div>
                            ) : null}

                            {sendersList && sendersList.length ? (
                                <div>
                                    <div className="label">{texts.sender}</div>
                                    <Datalist medium data={sendersList} change={changesenders} />
                                </div>
                            ) : null}

                            {addressesList && addressesList.length ? (
                                <div>
                                    <div className="label">{texts.addresse}</div>
                                    <Datalist medium data={addressesList} change={changeaddresses} />
                                </div>
                            ) : null}

                            {yearsList && yearsList.length ? (
                                <div>
                                    <div className="label">{texts.dateFrom}</div>
                                    <Datalist data={yearsList} change={changeYearFrom} />
                                </div>
                            ) : null}

                            {yearsList && yearsList.length ? (
                                <div>
                                    <div className="label">{texts.date} {texts.to}</div>
                                    <Datalist data={yearsList} change={changeYearTo} />
                                </div> 
                            ) : null}
                        </div>
                        <div style={{textAlign: "center"}}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                    <div className="sort-section">
                        <span className="label">{texts.sortBy}</span>
                        <Dropdown data={sortParams} type="sort" change={changeSort}  />
                    </div>
                </form>
                {loading ? <Loader /> : (
                    <div>
                        <div className="composition-item letter-item tab-header">
                            <div>{texts.date}</div>
                            <div>{texts.title}</div>
                            <div>{texts.sender}</div>
                            <div>{texts.addresse}</div>
                        </div> 
                        {data.map(function(item,index){
                            return(
                                <Link className={`${index%2 == 0 ? "light" : "dark"}`} to={`${url}/${item.id}${item.name ? "-"+parseLink(item.name) : ""}`} key={index}>
                                    <div className="composition-item letter-item">
                                        <div dangerouslySetInnerHTML={{ __html: item.data_txt}}/>
                                        <div dangerouslySetInnerHTML={{ __html: item.name}}/>
                                        <div>
                                            {item.senders.length ? (
                                                item.senders.map(function(item, index){
                                                    return(
                                                        <span key={index}>{`${item.index > 1 ? ", " : ""}${item.name}`}</span>
                                                    )
                                                })
                                            ) : null}
                                        </div>
                                        <div>
                                            {item.addressees.length ? (
                                                item.addressees.map(function(item, index){
                                                    return(
                                                        <span key={index}>{`${item.index > 1 ? ", " : ""}${item.name}`}</span>
                                                    )
                                                })
                                            ) : null}
                                        </div>
                                        {searchText && searchText.length ? (
                                            <div className="text text-searched" dangerouslySetInnerHTML={{ __html: item.txt}} />
                                        ) : (
                                            <div className="text" dangerouslySetInnerHTML={{ __html: item.txt.substring(0, 300) + "..."}}/>
                                        )}
                                        

                                        {item.date_of_publishing_text && item.date_of_publishing_text.length ? (
                                            <div dangerouslySetInnerHTML={{ __html: item.date_of_publishing_text}}/>
                                        ) : null}

                                        {item.category && item.category.length ? (
                                            <div dangerouslySetInnerHTML={{ __html: item.category}}/>
                                        ) : null}
                                        
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                )}


                {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
            </div>
        ) : <Loader />
    )
}
