import React, { useState, useEffect } from 'react'
import { Link, useParams, useRouteMatch, Route } from "react-router-dom"
import styles from './Place.module.css'
import Gallery from '../Gallery'
import { Translations } from "../../../Translations";
import placeholder from '../../../assets/img/placeholder.jpg'
import Loader from '../Loader'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'

export default function Place(props) {

    let { id } = useParams();
    id = parseInt(id);

    const name = "Place id:" + id;
    const [data, updateData] = useState(false);
    const [loading, loadingUpdate] = useState(false);

    const [texts, updateTexts] = useState(Translations());

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    const fetchUrl = `${window.apiUrl}place/get_place/${id}?lang=${window.language}`

    useEffect(() => {
        fetch(fetchUrl)
            .then(res => res.json())
            .then((res) => {
                console.log(name, res);
                updateData(res);
                initGallery(".gallery-item", lightboxImages, lightboxToggle);

                if (mobile()) {
                    const text = document.getElementsByClassName("text-wrapper")
                    text[0].scrollIntoView();
                }
            })
            .catch((error) => {
                console.log('Feching error for:', name, error);
            });

            return () => unmountGallery(".gallery-item", lightboxImages, lightboxToggle)
    }, [])

    const closeLightbox = () => lightboxToggle(false)

    const mainImage = data.photo ? data.photo : placeholder


    return (
        <main>
            {data ? (
                <div className={`main-wrapper ${styles.placeWrapper}`}>
                    <div src={mainImage} className={`gallery-item ${styles.mainImage}`} style={{ backgroundImage: `url(${mainImage})` }} />
                    <div className={styles.description}>
                        {data.name ? (
                            <h1 className={styles.title}>{data.name}</h1>
                        ) : null}
                    </div>
                    <div className={styles.textWrapper}>
                        <div className={styles.text} dangerouslySetInnerHTML={{__html: data.text}} />
                    </div>
                </div>
            ) : <Loader />}
            {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
        </main>
    )
}