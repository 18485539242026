import React, { useEffect, useState, useContext } from "react";
import { MSEContext } from "../../Context/MainSearchEngineContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { mobile } from "../../../Helpers";
import { Translations } from "../../../Translations";
import "./Menu.css";

export default function Menu() {
    const translation = Translations();

    const [texts, updateTexts] = useState(translation);

    // Getting context
    const showContext = useContext(MSEContext);

    let data = [
        {
            name: texts.news,
            active: showContext.menuPosition === 1 ? true : false,
            url: `/${window.language}/aktualnosci`,
        },
        {
            name: texts.composers,
            active: showContext.menuPosition === 2 ? true : false,
            url: `/${window.language}/kompozytorzy`,
        },
        {
            name: texts.genres,
            active: showContext.menuPosition === 3 ? true : false,
            url: `/${window.language}/gatunki`,
        },
        {
            name: texts.compositions,
            active: showContext.menuPosition === 4 ? true : false,
            url: `/${window.language}/kompozycje`,
        },
        {
            name: texts.biblio,
            active: showContext.menuPosition === 5 ? true : false,
            url: `/${window.language}/wydawnictwa`,
        },
        {
            name: texts.multimedia,
            active: showContext.menuPosition === 7 ? true : false,
            url: `/${window.language}/multimedia`,
        },
        {
            name: texts.aboutProject,
            active: showContext.menuPosition === 6 ? true : false,
            url: `/${window.language}/o-projekcie`,
        },
    ];

    useEffect(() => {
        // console.log("test")
    }, [showContext]);

    // console.log("Menu context", showContext)

    const [menuState, menuToggle] = useState(false);

    const isOpened = menuState ? "" : "closed";

    const [lang, langUpdate] = useState(window.language);

    const langUpdateHandler = (param) => {
        langUpdate(param);
        window.language = param;
        localStorage.setItem("lang", param);
        window.location.reload();
    };

    window.localStorage.getItem("lang");

    return mobile() ? (
        <div className="menu-wrapper">
            <div onClick={() => menuToggle(true)} className="hamburger"></div>
            <ul id="menu" className={isOpened}>
                <li className="tools">
                    <div>
                        <button
                            onClick={() => langUpdateHandler("pl")}
                            className={
                                lang == "pl" ? "active lang-btn" : "lang-btn"
                            }
                        >
                            PL
                        </button>
                        <button
                            onClick={() => langUpdateHandler("en")}
                            className={
                                lang == "en" ? "active lang-btn" : "lang-btn"
                            }
                        >
                            EN
                        </button>
                    </div>
                    <button onClick={() => menuToggle(false)}></button>
                </li>
                {data.map(function (item, index) {
                    const active = item.active ? "active" : "";
                    return (
                        <li key={index} onClick={() => menuToggle(false)}>
                            <Link className={active} to={item.url}>
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    ) : (
        <ul id="menu">
            {data.map(function (item, index) {
                const active = item.active ? "active" : "";
                return (
                    <li key={index}>
                        <Link className={active} to={item.url}>
                            {item.name}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}
