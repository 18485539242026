import React, {useState, useEffect} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
import Loader from '../Loader'
import './Articles.css'
import {Translations} from '../../../Translations'
import Dropdown from '../Dropdown';
import {mobile} from '../../../Helpers'
import {parseLink} from '../../../Helpers'

export default function Articles(props) {

    // Just name of component
    const name = "Articles";

    const compositionText = React.createRef();

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [tonationsList, updateTonations] = useState([]);
    const [castsList, updateCasts] = useState([]);
    const [genresList, updateGenres] = useState([]);
    const [creationList, updateCreation] = useState([]);
    const [dedicationList, updateDedication] = useState([]);
    const [opusList, updateOpus] = useState([]);

    // Data selected from dropdowns
    const [tonation, tonationUpdate] = useState(false);
    const [casts, castsUpdate] = useState(false);
    const [genres, genresUpdate] = useState(false);
    const [year, yearUpdate] = useState(false);
    const [dedication, dedicationUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [opus, opusUpdate] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);


    // Get data for search enginge dropdowns
    /*const dropdownData = () => {
        // Tonations
        const keysPromise = fetch(`${window.apiUrl}articles/keys?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{
            tonationsList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        tonationsList.push({"name": item.name, "value": item.id})
                    );
            })
        })

        // Casts
        const castsPromise = fetch(`${window.apiUrl}articles/cast?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{
            castsList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        castsList.push({"name": item.name, "value": item.id})
                    );
            })
        })

        // Genres
        const genresPromise = fetch(`${window.apiUrl}articles/genres?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{

            genresList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        genresList.push({"name": item.name, "value": item.id})
                    );
            })
        })

        // Years
        const yearsPromise = fetch(`${window.apiUrl}articles/years?id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{
            creationList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    creationList.push({"name": item, "value": item})
                    );
            })
        })

        // Opus
        const opusPromise = fetch(`${window.apiUrl}articles/opuses?id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json(), opusList.length = 0)
        .then((res)=>{
            opusList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    opusList.push({"name": item.name, "value": item.id})
                    );
            })
        })

        // Dedications
        const dedicationsPromise = fetch(`${window.apiUrl}articles/dedications?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{
            dedicationList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    dedicationList.push({"name": item.last_name + " " + item.first_name, "value": item.id})
                    );
            })
        })
        return Promise.all([keysPromise, castsPromise, genresPromise, yearsPromise, opusPromise, dedicationsPromise]);
    }*/

    let searchParams = "";
    
    if(tonation){
        searchParams += `&id_key=${tonation}`
    }

    if(casts){
        searchParams += `&id_cast=${casts}`
    }

    if(genres){
        searchParams += `&id_genre=${genres}`
    }

    if(year){
        searchParams += `&year=${year}`
    }

    if(dedication){
        searchParams += `&id_dedication_recipient=${dedication}`
    }

    if(searchText){
        searchParams += `&search=${searchText}`
    }

    if(opus){
        searchParams += `&opus=${opus}`
    }

    const clear = e => {
        e.preventDefault();
        opusUpdate(false);
        updateSearchText(false);
        tonationUpdate(false);
        castsUpdate(false);
        genresUpdate(false);
        yearUpdate(false);
        dedicationUpdate(false);
        clearRequestUpdate(!clearRequest)
    }

    const submit = e => {
        loadingUpdate(true);
        updateFetchCounter(1);
        e.preventDefault();
        fetch(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${0}&limit=${elementAmount}${searchParams}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${0}&limit=${elementAmount}${searchParams}`)
            updateData(res);
            console.log(res.length,"res.length")
            console.log(res.length < elementAmount)
            setNoMore(false)
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })  
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        props.updateMenu(11);
        updateData(false);
        /*if(!dedicationList.length){
            dropdownData().then(()=>{
                fetch(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                .then(res => res.json())
                .then((res)=>{
                    console.log(name, res);
                    console.log(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                    updateData(res);
                    setNoMore(false)
                    if(res.length < elementAmount){
                        setNoMore(true);
                    }
                    if(mobile()){
                        const text = document.getElementsByClassName("text-wrapper")
                        text[0].scrollIntoView(); 
                    }
                })    
            });
        } else {

        }*/

        fetch(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                updateData(res);
                setNoMore(false)
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })
    },[clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(`${window.apiUrl}articles?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    useEffect(() => {
        const texts = document.querySelectorAll(".article-item-text a");

        if(texts && texts.length){
            texts.forEach(function(text){
                text.setAttribute('target', '_blank');
            })
        }
        // console.log(texts)
    }, [compositionText])


    useEffect(()=> {
        console.log(noMoreData,"noMoreData")
    },[noMoreData])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    const changeTonation = (name, value) => {
        tonationUpdate(value)
    }

    const changeCasts = (name, value) => {
        castsUpdate(value)
    }

    const changeGenres = (name, value) => {
        genresUpdate(value)
    }

    const changeOpus = (name, value) => {
        opusUpdate(value)
    }

    const changeYear = (name, value) => {
        yearUpdate(value)
    }

    const changeDedication = (name, value) => {
        dedicationUpdate(value)
    }

    const authorsList = (authors) => {

        let authorsString = ""
        authors.forEach((item, index) => {
            if(index !== authors.length - 1) {
                authorsString = `${authorsString} ${item.first_name}&nbsp;${item.last_name},`
            } else {
                authorsString = `${authorsString} ${item.first_name}&nbsp;${item.last_name}`
            }
        })

        return authorsString
    }


    return (
        data  ? (
            <div className="articles-wrapper">
                <div className="title">{texts.articles}</div>
                {/*<form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase}/>
                        <div className="dropdowns-wrapper">
                            <div>
                                <div className="label">{texts.tonation}</div>
                                <Dropdown data={tonationsList} type="tonacja" change={changeTonation}  />
                            </div>
                            <div>
                                <div className="label">{texts.cast}</div>
                                <Dropdown data={castsList} type="obsada" change={changeCasts} />
                            </div>

                            {opusList && opusList.length ? (
                                <div>
                                    <div className="label">{texts.opus}</div>
                                    <Dropdown data={opusList} type="obsada" change={changeOpus} />
                                </div>   
                            ) : null}

                            <div>
                                <div className="label">{texts.genre}</div>
                                <Dropdown data={genresList} type="gatunki" change={changeGenres} />
                            </div>
                            <div>
                                <div className="label">{texts.createYear}</div>
                                <Dropdown data={creationList} type="data" change={changeYear}/>
                            </div>
                            <div>
                                <div className="label">{texts.dedicationAdres}</div>
                                <Dropdown data={dedicationList} type="dedykacja" change={changeDedication}/>
                            </div>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                </form>*/}

                {loading ? <Loader /> : (
                    <div>
                        <div className="article-item-single-main tab-header">
                            <div>{texts.title}</div>
                            <div>{texts.authors}</div>
                        </div>     
                        {data.map(function(item,index){
                            return(
                                <div key={index} className="article-item-single">
                                    <Link to={`${url}/${item.id}-${parseLink(item.title)}`} key={index}>
                                        <div className="article-item-single-main">
                                            <div>
                                                <span dangerouslySetInnerHTML={{ __html: item.title }}/>
                                            </div>
                                            {item.authors ? (
                                                <div dangerouslySetInnerHTML={{ __html: authorsList(item.authors)}} />
                                            ) : (
                                                <div>?</div>
                                            )}
                                        </div>
                                    </Link>
                                    {item.text && item.text.length ? (
                                        <div ref={compositionText} className="article-item-text" dangerouslySetInnerHTML={{ __html: item.text.slice(0,300)+" ..." }}/>
                                    ) : null}
                                </div>

                            )
                        })}
                    </div>
                )}

                {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
            </div>

        ) : <Loader />
    )
}