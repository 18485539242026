import React, {useState, useEffect} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
// import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import Loader from '../Loader'
import styles from './FirstEditions.css'
import arrow from '../../../assets/icons/arrow.svg'
import Dropdown from '../Dropdown'
import {Translations} from '../../../Translations'
import {parseLink} from '../../../Helpers'
import {mobile} from '../../../Helpers'
import {Datalist} from '../Datalist'

export default function FirstEditions(props) {

    // Just name of component
    const name = "FirstEditions";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data currently fetching?
    const [loading, loadingUpdate] = useState(false);

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

    // Data for dropdowns' lists
    const [countriesList, updateCountriesList] = useState([]);
    const [creationList, updateCreationList] = useState([]);
    const [compositionsList, updateCompositions] = useState([]);
    const [institutionsList, updateInstitutions] = useState([]);
    
    // Data selected from dropdowns
    const [compositions, compositionsUpdate] = useState(false);
    const [institutions, institutionsUpdate] = useState(false);
    const [countries, countriesUpdate] = useState(false);
    const [creation, creationUpdate] = useState(false)
    const [sortType, updateSortType] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    const sortParams = [
        {name: texts.title+" "+texts.asc, value: "titleAsc"},
        {name: texts.title+" "+texts.desc, value: "titleDesc"},
        {name: texts.sourceType+" "+texts.asc, value: "sourceAsc"},
        {name: texts.sourceType+" "+texts.desc, value: "sourceDesc"},
        {name: texts.place+" "+texts.asc, value: "placeAsc"},
        {name: texts.place+" "+texts.desc, value: "placeDesc"},
        {name: texts.date+" "+texts.asc, value: "dateAsc"},
        {name: texts.date+" "+texts.desc, value: "dateDesc"},
    ]

        // Get data for search enginge dropdowns
        const dropdownData = () => {

            // Compositions
            const compositionPromise = fetch(`${window.apiUrl}first_editions/compositions?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), compositionsList.length=0)
            .then((res)=>{
                console.log(`${window.apiUrl}first_editions/compositions?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
                compositionsList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                        compositionsList.push({"name": item.name, "value": item.id})
                        );
                })
                // console.log("Kompozycje:", compositions)
            })
    
            // Publisher
            const institutionsPromise = fetch(`${window.apiUrl}first_editions/institutions?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), institutionsList.length=0)
            .then((res)=>{
                console.log(`${window.apiUrl}first_editions/institutions?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
                institutionsList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                            institutionsList.push({"name": item.name, "value": item.id})
                        );
                })
                // console.log("Wydawca", institutions)
            })
    
            // Countries
            const countriesPromise = fetch(`${window.apiUrl}first_editions/countries?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), countriesList.length=0)
            .then((res)=>{
                console.log(`${window.apiUrl}first_editions/countries?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
                countriesList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                        countriesList.push({"name": item.name, "value": item.id})
                        );
                })
                // console.log("Kraje:", countries)
            })

            // Years
            const yearsPromise = fetch(`${window.apiUrl}first_editions/years?id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), creationList.length=0)
            .then((res)=>{
                console.log(`${window.apiUrl}first_editions/years?id_composer=${props.id ?  props.id : ""}`)
                creationList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                        creationList.push({"name": item, "value": item})
                        );
                })
                console.log("Lata:", creation);
            })

                return Promise.all([compositionPromise, institutionsPromise, countriesPromise, yearsPromise]);
            

        }


    let searchParams = "";

    if(institutions){
        searchParams += `&id_institution=${institutions}`
    }

    if(creation){
        searchParams += `&year=${creation}`
    }

    if(countries){
        searchParams += `&id_country=${countries}`
    }
    
    if(compositions){
        searchParams += `&id_composition=${compositions}`
    }

    if(sortType){
        searchParams += `&sort=${sortType}`
    }

    const clear = e => {
        e.preventDefault();
        updateSortType(false)
        institutionsUpdate(false);
        countriesUpdate(false);
        creationUpdate(false);
        compositionsUpdate(false);
        clearRequestUpdate(!clearRequest)
        setNoMore(false)
    }

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(`${window.apiUrl}first_editions?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(`${window.apiUrl}first_editions?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })  
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        props.updateMenu(6);
        updateData(false);
        dropdownData().then(()=>{
            fetch(`${window.apiUrl}first_editions?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(`${window.apiUrl}first_editions?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                if(mobile()){
                    const text = document.getElementsByClassName("text-wrapper")
                    text[0].scrollIntoView(); 
                }
            })    
        });
    },[clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if(sortType){
            loadingUpdate(true);
            setNoMore(false)
            fetch(`${window.apiUrl}first_editions?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                loadingUpdate(false);
            })    
        }
        }, [sortType])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            // loadingUpdate(true);
            fetch(`${window.apiUrl}first_editions?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                // loadingUpdate(false);
            })    
        }
    }, [fetchCounter])


    useEffect(() => {
        console.log(creation)
    }, [creation])

    const changeCompositions = (name, value) => {
        compositionsUpdate(value)
    }

    const changeInstitutions = (name, value) => {
        institutionsUpdate(value)
    }

    const changeCountries = (name, value) => {
        countriesUpdate(value)
    }

    const changeYear = (name, value) => {
        creationUpdate(value)
    }

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    const changeSort = (name, value) => {
        updateSortType(value)
    }

    let {path, url} = useRouteMatch();

    // console.log("path to:", path, "a url to:", url)

    return (
        data ? (
            <div className="first-editions-wrapper">
                <div className="title">{texts.firstEditions}</div>
                <form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                    <div>
                        <div className="dropdowns-wrapper">
                            {compositionsList && compositionsList.length ? (
                                <div>
                                    <div className="label">{texts.compositions}</div>
                                    <Datalist small data={compositionsList} change={changeCompositions} />
                                </div>
                            ) : null}

                            {institutionsList && institutionsList.length ? (
                                <div>
                                    <div className="label">{texts.sourceType}</div>
                                    <Datalist small data={institutionsList} change={changeInstitutions} />
                                </div>
                            ) : null}

                            {countriesList && countriesList.length ? (
                                <div>
                                    <div className="label">{texts.countries}</div>
                                    <Datalist medium data={countriesList} change={changeCountries} />
                                </div>
                            ) : null}

                            {creationList && creationList.length ? (
                                <div>
                                    <div className="label">{texts.date}</div>
                                    <Datalist data={creationList} change={changeYear} />
                                </div>
                            ) : null}

                        </div>
                        <div style={{textAlign: "center"}}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                    <div className="sort-section">
                        <span className="label">{texts.sortBy}</span>
                        <Dropdown data={sortParams} type="sort" change={changeSort}  />
                    </div>
                </form>
                {loading ? <Loader /> : (
                <div>
                    <div className="first-edition-item tab-header">
                        <div>{texts.title}</div>
                        <div>{texts.publisher}</div>
                        <div>{texts.place}</div>
                        <div>{texts.date}</div>
                    </div>  
                {data.map(function(item,index){
                    return(
                        <Link to={`${url}/${item.id}-${parseLink(item.title)}`} key={index}>
                            <div className="first-edition-item">
                                <div dangerouslySetInnerHTML={{ __html: item.title }}/>
                                <div>{item.publishinghouse}</div>
                                <div>{item.place}</div>
                                <div>{item.date}</div>
                                <div className="first-edition--text" dangerouslySetInnerHTML={{ __html: item.text }}/>
                            </div>
                        </Link>
                    
                    )
                })}
                </div>)}

                {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
            </div>

        ) : <Loader />
    )
}
