import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import './ComposerView.css'
import Loader from '../Loader'
import imgPlaceholder from '../../../assets/img/placeholder-dmp.jpg'
import Compositions from '../Compositions'
import Composition from '../Composition'
import Manuscripts from '../Manuscripts'
import Manuscript from '../Manuscript'
import FirstEdition from '../FirstEdition'
import FirstEditions from '../FirstEditions'
import Calendary from '../Calendary'
import Bibliography from '../Bibliograhy'
import BibliographyItem from '../Bibliograhy/BibliographyItem.jsx'
import Genre from './Genre'
import {Translations} from '../../../Translations'
import Gallery from '../Gallery'
import Filmography from '../Filmography'
import FilmographyItem from '../Filmography/FilmographyItem'
import Letters from '../Letters'
import LetterItem from '../Letters/LetterItem'
import Multimedia from '../../Multimedia'
import MultimediaItem from '../../Multimedia/MultimediaItem'
import SpotifyItem from '../../Multimedia/SpotifyItem'
import { MSEContext } from '../../Context/MainSearchEngineContext'
import {mobile, initGallery, unmountGallery, parseLink} from '../../../Helpers'
import Article from "../Article";
import Articles from "../Articles";

export default function ComposerView(props) {    

    let { id } = useParams();
    
    id = parseInt(id);

    let { path, url, image } = useRouteMatch();

    const textRef = React.createRef();

    // Getting context 
    const showContext = useContext(MSEContext)

    const name = "ComposerView";
    const [data, updateData] = useState(false);
    const [loading, loadingUpdate] = useState(false);
    const [text, updateText] = useState(false);
    const [textTitle, updateTextTitle] = useState(false);
    const [primaryText, updatePrimaryText] = useState(data.biography);
    const [calendaryData, updateCalendaryData] = useState();
    const [compositions, setCompositions] = useState(false)
    const [articles, setArticles] = useState(false)
    const [activeSubcategory, updateSubcategory] = useState("");
    const [firstEditions, updateFirstEditions] = useState(false)
    const [manuscripts, updateManuscripts] = useState(false)
    const [letters, updateLetters] = useState(false)
    const [bibliography, updateBibliography] = useState(false)
    const [filmography, updateFilmography] = useState(false)
    const [multimedia, updateMultimedia] = useState(false)
    const [genres, updateGenres] = useState([])

    // state of menu
    const [mainPosition, updateMainPosition] = useState(1);
    const [subPosition, updateSubPosition] = useState(1);

    // Language init
    const [texts, updateTexts] = useState();
    const translation = Translations();

    // Gallery functions & 'variables'
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    const closeLightbox = () => lightboxToggle(false);





    const initialFetch =() => {
        const composerPromise = fetch(`${window.apiUrl}person/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            updateData(res);
            console.log(name, res)
            data.mini && data.mini.length ? updatePrimaryText(data.mini) : updatePrimaryText(data.biography)
        })

        const firstEditionsPromise = fetch(`${window.apiUrl}first_editions?id_composer=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=> updateFirstEditions(res))

        const manuscriptsPromise = fetch(`${window.apiUrl}manuscripts?id_composer=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=> updateManuscripts(res))

        const lettersPromise = fetch(`${window.apiUrl}letters?id_composer=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=>updateLetters(res))

        const compositionsPromise = fetch(`${window.apiUrl}compositions?id_composer=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=>setCompositions(res))

        const articlesPromise = fetch(`${window.apiUrl}articles?id_composer=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=>setArticles(res))

        const bibliographyPromise = fetch(`${window.apiUrl}bibliographies?ids_characters[]=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=>updateBibliography(res))

        const filmographyPromise = fetch(`${window.apiUrl}filmography?id_character=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=>updateFilmography(res))

        const multimediaPromise = fetch(`${window.apiUrl}multimedia?id_composer=${id}&lang=${window.language}&limit=1`)
        .then(res => res.json())
        .then((res)=>updateMultimedia(res))

        return Promise.all([firstEditionsPromise, composerPromise, compositionsPromise, articlesPromise, manuscriptsPromise, lettersPromise, bibliographyPromise, filmographyPromise, multimediaPromise]);
    }

    const prevCountRef = useRef();

    useEffect(() => {
        prevCountRef.current = mainPosition;
    }, [mainPosition])

    const prevCount = prevCountRef.current;

    useEffect(() => {
        showContext.updateMenuPositionHandler(2);
        updateTexts(translation);
        if (mobile()) {
            window.scrollTo(0, 200);
        }
        initialFetch();
    }, [id , url])

    useEffect(() => {
        console.log(mainPosition);
        if (prevCount == 1) {
            if (!mobile()) {
                window.scrollTo(0, 300);
            }
        }
    }, [mainPosition])

    useEffect(() => {
        if(data) {
            let dataLocal = data;
            dataLocal.genres.sort((a,b) => a.order - b.order);
            updateData(dataLocal)
        }
    }, [data])

    useEffect(() => {
        const text = document.getElementsByClassName("text-wrapper")

        if(mobile() && primaryText){
            text[0].scrollIntoView(); 
        }

        initGallery(".primary-view .text img", lightboxImages, lightboxToggle);
        
        
        return () => unmountGallery(".primary-view .text img", lightboxImages, lightboxToggle)
    }, [primaryText, data.mini])


    const selectMenu = (main, target) => {
        if(main){
            updateText(false);
            updateMainPosition(1)
            if(data.mini && data.mini.length)
            if(target.target.classList.value.indexOf("menu-item") > -1 ){
                updatePrimaryText(false);
                updateSubPosition(1);
            }
            document.querySelectorAll(".menu .menu-subitem").forEach(function(e){
                e.classList.remove("selected")
            })
        }
        target.currentTarget.parentElement.querySelectorAll(".menu-postion").forEach((e) => e.classList.remove("selected"));
        target.currentTarget.classList.toggle("selected");
    }

    const menu = data ? (
        <div className="menu">
            <div onClick={selectMenu.bind(this, true)} className={`menu-postion ${mainPosition === 1 ? "selected" : ""}`}>
                <Link to={url}>
                    <div className="menu-item">{texts.biography}</div>
                </Link>
                {data.mini && data.mini.length ? (
                    <div onClick={()=>{updatePrimaryText(false); updateSubPosition(1)}} className={`menu-subitem ${subPosition === 1 || subPosition === false  ? "selected" : ""}`}>{texts.shortBio}</div> 
                ) : null}
                <div onClick={()=>{updatePrimaryText(data.biography); updateSubPosition(2)}} className={`menu-subitem ${subPosition === 2 ? "selected" : ""}`}>{texts.fullBio}</div> 
            </div>
            
            {data.calendar_mastercategories && data.calendar_mastercategories.length ? (
                data.calendar_mastercategories.map(function (item, index) {
                    return (
                        item.categories.length ?
                            <div onClick={selectMenu.bind(this, false)} className={`menu-postion ${mainPosition === 2 ? "selected" : ""}`} key={index}>
                                <Link to={`${url}/kalendarium/${item.categories[0].id}-${parseLink(`${item.categories[0].title}`)}`}>
                                    <div className="menu-item">{texts.calendar}</div>
                                </Link>
                                {item.categories ? (
                                    item.categories.map(function (subitem, subkey) {
                                        return (
                                            <Link className={subPosition === subitem.id ? "active" : ""} key={subkey} to={`${url}/kalendarium/${subitem.id}-${parseLink(`${subitem.title}`)}`}>
                                                <div className="menu-subitem">{subitem.title}</div>
                                            </Link>
                                        )
                                    })
                                ) : null}
                            </div>
                        : null
                    )
                })
            ) : null}

            {data.genres && data.genres.length ? (
                <div onClick={selectMenu.bind(this, false)} className={`menu-postion ${mainPosition === 3 ? "selected" : ""}`}>
                    {data.genres[0] && data.genres[0].genres[0] ? (
                        <Link to={`${url}/gatunki/${data.genres[0].genres[0].id}-${parseLink(data.genres[0].genres[0].name)}`}>
                            <div className="menu-item">{texts.genres2}</div>
                        </Link>
                    ) : (
                        <Link to={`${url}/gatunki/${data.genres[0].id}-${parseLink(data.genres[0].name)}`}>
                            <div className="menu-item">{texts.genres2}</div>
                        </Link>
                    )}

                    {data.genres.map(function (item, index) {
                        return (
                            <div key={index}>
                                <div className={`menu-subitem  ${item.id === subPosition ? "selected" : ""}`}>
                                    {item.txt && item.txt.length ? (
                                        <Link to={`${url}/gatunki/${item.id}-${parseLink(item.name)}`}>
                                            {item.name}
                                        </Link>
                                    ) : (
                                        <span className="no-pointer">{item.name}</span>
                                    )}
                                </div>
                                {item.genres.map((subItem,subIndex) => {
                                    return(
                                        <div key={subIndex} className={`menu-subitem third-level ${subItem.id == subPosition ? "selected" : ""}`}>
                                            <Link to={`${url}/gatunki/${subItem.id}-${parseLink(subItem.name)}`}>
                                                {subItem.name}
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            ) : null}

            {compositions && compositions.length ? (
                <Link className={`menu-postion ${mainPosition === 4 ? "selected" : ""}`} to={`${url}/kompozycje`}>
                    <div>
                        <div className="menu-item">{texts.compositions}</div>
                    </div>
                </Link>) : null}

            {manuscripts && manuscripts.length ? (
                <Link className={`menu-postion ${mainPosition === 5 ? "selected" : ""}`} to={`${url}/rekopisy`}>
                    <div>
                        <div className="menu-item">{texts.manuscripts}</div>
                    </div>
                </Link>) : null}

            {firstEditions && firstEditions.length ? (
                    <Link className={`menu-postion ${mainPosition === 6 ? "selected" : ""}`} to={`${url}/pierwodruki`}>
                        <div>
                            <div className="menu-item">{texts.firstEditions}</div>
                        </div>
                    </Link>) : null}

            {letters && letters.length ? (
                <Link className={`menu-postion ${mainPosition === 9 ? "selected" : ""}`} to={`${url}/listy`}>
                    <div>
                        <div className="menu-item">{texts.letters}</div>
                    </div>
                </Link>) : null}

            {bibliography && bibliography.length ? (
                    <Link className={`menu-postion ${mainPosition === 7 ? "selected" : ""}`} to={`${url}/bibliografia`}>
                        <div>
                            <div className="menu-item">{texts.biblio}</div>
                        </div>
                    </Link>) : null}

            {filmography && filmography.length ? (
                <Link className={`menu-postion ${mainPosition === 8 ? "selected" : ""}`} to={`${url}/filmografia`}>
                    <div>
                        <div className="menu-item">{texts.filmography}</div>
                    </div>
                </Link>) : null}

            {multimedia && multimedia.length ? (
                <Link className={`menu-postion ${mainPosition === 10 ? "selected" : ""}`} to={`${url}/multimedia`}>
                    <div>
                        <div className="menu-item">Multimedia</div>
                    </div>
                </Link>) : null}

            {articles && articles.length ? (
                <Link className={`menu-postion ${mainPosition === 11 ? "selected" : ""}`} to={`${url}/artykuly`}>
                    <div>
                        <div className="menu-item">{texts.articles}</div>
                    </div>
                </Link>) : null}
        </div>
    ) : null

    const routes = data ? (
        <div className="overflow-hidden">
            <Route path={`${path}/kalendarium/:id`}>
                <div className={`text-wrapper overflow-hidden ${loading ? "loading" : ""}`}>
                    <Calendary data={calendaryData} function={updateSubcategory} updateMenu={updateMainPosition} updateSubmenu={updateSubPosition} />
                </div>
            </Route>

            {data.genres && data.genres.length ? (
            <Route path={`${path}/gatunki/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <Genre data={data.genres} updateMenu={updateMainPosition} updateSubmenu={updateSubPosition} url={url} />
                </div>
            </Route>
            ) : null}

            <Route path={`${path}/rekopisy/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <Manuscript updateMenu={updateMainPosition} url={url}  id={id} image={image} />
                </div>
            </Route>
            <Route exact path={`${path}/rekopisy`}>
                <div className={`text-wrapper manuscripts ${loading ? "loading" : ""}`}>
                    <Manuscripts path={path} id={id} image={image} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route path={`${path}/pierwodruki/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <FirstEdition updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/pierwodruki`}>
                <div className={`text-wrapper firstEditions ${loading ? "loading" : ""}`}>
                    <FirstEditions path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route path={`${path}/kompozycje/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <Composition updateMenu={updateMainPosition} url={url} />
                </div>
            </Route>
            <Route exact path={`${path}/kompozycje`}>
                <div className={`text-wrapper compositions ${loading ? "loading" : ""}`}>
                    <Compositions path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/bibliografia`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <Bibliography path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/bibliografia/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <BibliographyItem updateMenu={updateMainPosition} url={url} />
                </div>
            </Route>
            <Route exact path={`${path}/filmografia`}>
                <div className={`text-wrapper filmography ${loading ? "loading" : ""}`}>
                    <Filmography path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/filmografia/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <FilmographyItem updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/listy`}>
                <div className={`text-wrapper letters ${loading ? "loading" : ""}`}>
                    <Letters path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/listy/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <LetterItem updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/multimedia`}>
                <div className={`text-wrapper multimedia ${loading ? "loading" : ""}`}>
                    <Multimedia path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/multimedia/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <MultimediaItem updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route path={`${path}/artykuly/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <Article updateMenu={updateMainPosition} url={url} />
                </div>
            </Route>
            <Route exact path={`${path}/artykuly`}>
                <div className={`text-wrapper compositions ${loading ? "loading" : ""}`}>
                    <Articles path={path} id={id} updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={`${path}/multimedia/spotify/:id`}>
                <div className={`text-wrapper ${loading ? "loading" : ""}`}>
                    <SpotifyItem updateMenu={updateMainPosition} />
                </div>
            </Route>
            <Route exact path={path}>
                <>
                <div className={`text-wrapper primary-view ${loading ? "loading" : ""}`}>
                    {text ? <div className="text-title">{textTitle}</div> : null}
                    {text ? (
                        text.map(function (item, index) {
                            const text = item.text ? item.text : item ? item : [];
                            return (
                                <div className="article" key={index}>
                                    <div className="title" dangerouslySetInnerHTML={{ __html: item.title }} />                                                
                                    <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
                                </div>
                            )
                        })
                        ) : (
                         <div className="text" dangerouslySetInnerHTML={{ __html: primaryText ? primaryText : data.mini ? data.mini : data.biography }} />
                    )}

                </div>
                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
                </>
            </Route>
        </div>) : null

    const navigation = texts ? (
        <div className="navigation">
            <div style={{cursor: "pointer"}} className="back" onClick={()=>window.history.back()}>{texts.return}</div>
            <Link to={`/${window.language}/kompozytorzy`}>{texts.all+" "+texts.composers}</Link>
        </div>) : null

    const basicInfo = texts && data ? (
        <div className="basic-info">
            <div className="type">{data.gender == 'female' ? texts.composerFem : texts.composer}</div>
            <div className="name">{data.first_name + " " + data.last_name}</div>
            {data.date_of_born && data.date_of_die ? (
                <div className="date">{`${data.date_of_born.slice(0,4)}–${data.date_of_die.slice(0,4)}`}</div>
                ) : null}
        </div>) : null

    return (
        data ? (
            mobile() ? (
            <div id="composer-view">
                {navigation}
                <div className="content-wrapper">
                    <div className="composer-data">
                        {basicInfo}
                        <div className="background bg-cover" style={{ backgroundImage: `url(${data.image ? data.image : imgPlaceholder})` }}></div>
                        <div className="lead" dangerouslySetInnerHTML={{ __html: data.lead }} />
                    </div>
                    <div className="composer-content">
                        <div className="menu-wrapper">
                            {menu}
                        </div>
                        {routes}
                    </div>
                </div>
            </div>) 
            : 
            // Desktop
                (<div id="composer-view" className={`${mainPosition === 1 ? "" : "compact"}`}>
                {navigation}
                <div className="content-wrapper">
                    <div className="background bg-cover" style={{ backgroundImage: `url(${data.image ? data.image : imgPlaceholder})` }}></div>
                    <div className="composer-data">
                        {basicInfo}
                        <div className="lead" dangerouslySetInnerHTML={{ __html: data.lead }} />
                    </div>
                    <div className="composer-content">
                        <div className="menu-wrapper">
                            {menu}
                        </div>
                            {routes}
                    </div>
                </div>
            </div>)
        ) : <Loader />
    )
}