import React, {useState, useEffect, useContext} from 'react'
import Arrow from '../../../assets/icons/arrow.svg'
import './Dropdown.css'
import { MSEContext } from '../../Context/MainSearchEngineContext'

export default function Dropdown(props) {


    // if(props.type){
    //     console.log(props.type);
    //     console.log("Dropdown", props.data[0])
    // }

    // Getting context 
    const mSEContext = useContext(MSEContext);

    const [dropdownName, dropdownNameUpdate] = useState(props.data[0].name);

    const [dropdownValue, dropdownValueUpdate] = useState(props.data[0].value);

    // Does dropdown is opened?
    const [dropdownOpen, dropdownToggle] = useState(false)

    const updateNameHandler = e => {
        mSEContext.updateNameHandler(e)
        // console.log(e);
    };

    const updateYearFromHandler = e => {
        mSEContext.updateYearFromHandler(e)
        // console.log(e);
    };

    const updateYearToHandler = e => {
        mSEContext.updateYearToHandler(e)
        // console.log(e);
    };

    // Effect depends on type of passed props
    useEffect(() => {
        props.yearFrom ? updateYearFromHandler(dropdownValue) : void(0);
        props.yearTo ? updateYearToHandler(dropdownValue) : void(0);
        props.letter ? updateNameHandler(dropdownValue) : void (0);
    }, [dropdownValue])

    useEffect(() => {
        props.letter && !mSEContext.name.length ? dropdownNameUpdate(props.data[0].name) : void(0)
    }, [mSEContext])

    useEffect(() => {
        props.year ? dropdownNameUpdate(props.data[0].value) : void(0)
    }, [mSEContext])

    // Update state on <li> click
    const selectHandler = (name, value) => {
        if(props.type){
            props.change(name, value)
        }
        dropdownNameUpdate(name);
        dropdownValueUpdate(value);
        dropdownToggle(false);
    }

    // Checking prop for optional styling
    const red = props.red ? "red" : "";

    const isOpen = dropdownOpen ? "opened" : "";


    return (
            <div className={`custom-dropdown ${isOpen} ${red}`} onMouseLeave={()=>dropdownToggle(false)}>
            <div onClick={() => dropdownToggle(!dropdownOpen)} className="selected-value"><span>{props.label ? props.label : dropdownName}</span><img src={Arrow} alt="arrow mark"/></div>
                <ul>
                    {props.data.map((item,index)=>
                        <li onClick={selectHandler.bind(null,item.name,item.value)} key={index}>{item.name}</li>
                    )}
                </ul>
            </div>
    )
}
