import React, { useEffect, useState, useContext} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import Loader from '../Elements/Loader'
import {parseLink, minHeight, initGallery, unmountGallery} from '../../Helpers'
import {Translations} from '../../Translations'
import Gallery from '../Elements/Gallery'
import styles from './AllGenres.module.css'
import { MSEContext } from '../Context/MainSearchEngineContext'

export default function AllGenresItem(props) {

    const composerParam = new URL(window.location.href).searchParams.get("composer")

    const name = 'AllGenresItem'

    // Languages
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // All data
    const [data, updateData] = useState(false);
    const [secondaryData, updateSecondaryData] = useState(false)

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);

    // Gallery functions & 'variables'
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    // Getting context 
    const showContext = useContext(MSEContext)

    showContext.updateMenuPositionHandler(3);

    const closeLightbox = () => lightboxToggle(false)

    let { id } = useParams();

    id = parseInt(id);

    let genreCompositions = `${window.apiUrl}compositions?id_genre=${id}&lang=${window.language}`

    let NewsLink = `${window.apiUrl}/compositions/genre/${id}?lang=${window.language}`

    useEffect(() => {
        minHeight()
        console.log(NewsLink)
        console.log(genreCompositions)
        isLoadingUpdate(true)
        fetch(NewsLink)
        .then(res => res.json())
        .then((res)=>{
            updateData(res);
            console.log(name, res);
            isLoadingUpdate(false);
            initGallery(".gallery-item", lightboxImages, lightboxToggle);
        })
        .catch(err => { throw err });

        fetch(genreCompositions)
        .then(res => res.json())
        .then((res)=>{
            updateSecondaryData(res);
            console.log("Secondary data: ", res);
            isLoadingUpdate(false);
        })
        .catch(err => { throw err });

        return () => unmountGallery(".gallery-item", lightboxImages, lightboxToggle)
    }, [id])

   
    return (
        <main>
            {data ? (
                <div>
                    <div className={styles.navigation}>
                        <div style={{cursor: "pointer"}} className="back" onClick={()=>window.history.back()}>{texts.return}</div>
                        <Link to={`/${window.language}/gatunki`}>{texts.genres}</Link>
                    </div>
                   
                    <div className="main-wrapper">
                        {!isLoading ? (
                            <div className={styles.mainWrapper}>
                                <h1 className={styles.title}>{data.name}</h1>

                                {data.composers && data.composers.length ? (
                                    <div className={styles.composers}>
                                        <h3>{texts.composers}</h3>
                                        <div style={{display: 'grid'}}>
                                            {data.composers.map(function(item,index){
                                                return(
                                                    <div className={composerParam == item.id ? "grid-first" : ""}>
                                                        {item.id === 1 ? (
                                                            <a target="_blank" href={`${window.chopinUrl}/gatunki`} key={index}>
                                                                <div>{item.name}</div>
                                                            </a>
                                                        ) : (
                                                            <Link to={`/${window.language}/osoba/${item.id}-${parseLink(item.name)}`} key={index}>
                                                                <div>{item.name}</div>
                                                            </Link>
                                                        )}

                                                        {secondaryData && secondaryData.length ? (
                                                            <div style={{marginBottom: 30}}>
                                                                {secondaryData.map(function(subItem,subIndex){
                                                                if(subItem.id_composer == item.id){
                                                                    return(
                                                                        subItem.id_composer === 1 ? (
                                                                            <a target="_blank" href={`${window.chopinUrl}/kompozycja/${subItem.id}-${parseLink(subItem.title)}`} key={index}>
                                                                                <div style={{paddingLeft: 30}}dangerouslySetInnerHTML={{ __html: `${subItem.title} ${subItem.opus ? subItem.opus : ""}` }} />
                                                                            </a>
                                                                        ) : (
                                                                            <Link to={`/${window.language}/osoba/${subItem.id_composer}-${parseLink(subItem.composer)}/kompozycje/${subItem.id}-${parseLink(subItem.title)}`} key={subIndex}>
                                                                                <div style={{paddingLeft: 30}}dangerouslySetInnerHTML={{ __html: `${subItem.title} ${subItem.opus ? subItem.opus : ""}` }} />
                                                                            </Link>
                                                                        )
                                                                    )
                                                                } 
                                                                })}
                                                            </div>
                                                    ) : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : null}

                                {data.txt ? (
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.txt }} />
                                ) : null}

                                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
                            </div>
                        ) : <Loader />}
                    </div>
                </div>
            ) : <Loader />}
        </main>
    )
}