import React, {useState, useEffect} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
import Loader from '../Loader'
import Dropdown from '../Dropdown';
import {Translations} from '../../../Translations'
import {parseLink} from '../../../Helpers'
import {mobile} from '../../../Helpers'
import {Datalist} from '../Datalist'

export default function Filmography(props) {

    // Just name of component
    const name = "Filmographies";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

        // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

        // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [directorsList, updatedirectors] = useState([]);
    const [yearsList, updateYears] = useState([]);

    // Data selected from dropdowns
    const [directors, directorsUpdate] = useState(false);
    const [years, yearsUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [sortType, updateSortType] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    const sortParams = [
        {name: texts.title+" "+texts.asc, value: "titleAsc"},
        {name: texts.title+" "+texts.desc, value: "titleDesc"},
        {name: texts.genre+" "+texts.asc, value: "categoryAsc"},
        {name: texts.genre+" "+texts.desc, value: "categoryDesc"},
    ]

    // Get data for search enginge dropdowns
    const dropdownData = () => {
        // directors
        const directorsPromise = fetch(`${window.apiUrl}filmography/directors?lang=${window.language}&id_character=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{
            directorsList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        directorsList.push({"name": item.name , "value": item.id})
                    );
            })
            // console.log("directors:", directors)
        })

        // Years
        const yearsPromise = fetch(`${window.apiUrl}filmography/years?id_character=${props.id ?  props.id : ""}`)
        .then(res => res.json())
        .then((res)=>{
            yearsList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                    yearsList.push({"name": item, "value": item})
                    );
            })
            // console.log("Lata:", years);
        })

        return Promise.all([directorsPromise, yearsPromise]);
    }

    let searchParams = "";
    
    if(directors){
        searchParams += `&id_director=${directors}`
    }

    if(years){
        searchParams += `&year=${years}`
    }

    if(searchText){
        searchParams += `&search=${searchText}`
    }

    if(sortType){
        searchParams += `&sort=${sortType}`
    }

    const clear = e => {
        e.preventDefault();
        directorsUpdate(false);
        yearsUpdate(false);
        clearRequestUpdate(!clearRequest)
    }

    let link = `${window.apiUrl}filmography?id_character=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(link)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(link)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })  
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        props.updateMenu(8);
        updateData(false);
        if(!directorsList.length && !yearsList.length){
            dropdownData().then(()=>{
                fetch(link)
                .then(res => res.json())
                .then((res)=>{
                    console.log(name, res);
                    console.log(link)
                    updateData(res);
                    if(res.length < elementAmount){
                        setNoMore(true);
                    }
                    if(mobile()){
                        const text = document.getElementsByClassName("text-wrapper")
                        text[0].scrollIntoView(); 
                    }
                })    
            });
        } else {
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(link)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            }) 
        }

    },[clearRequest])

    // Get only list data on the counter increase
    useEffect(() => {
        if(sortType){
            loadingUpdate(true);
            setNoMore(false)
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                loadingUpdate(false);
            })    
        }
        }, [sortType])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(link)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    // console.log("path to:", path, "a url to:", url)

    const changedirectors = (name, value) => {
        directorsUpdate(value)
    }

    const changeYears = (name, value) => {
        yearsUpdate(value)
    }

    const changeSort = (name, value) => {
        updateSortType(value)
    }

    return (
        data ? (
            <div className="compositions-wrapper">
                <div className="title">{texts.filmography}</div>
                <form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase}/>
                        <div className="dropdowns-wrapper two">
                            <div>
                                <div className="label">{texts.direction}</div>
                                <Datalist medium data={directorsList} change={changedirectors} />
                            </div>
                            <div>
                                <div className="label">{texts.createYear}</div>
                                <Datalist data={yearsList} change={changeYears} />
                            </div>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                    <div className="sort-section">
                        <span className="label">{texts.sortBy}</span>
                        <Dropdown data={sortParams} type="sort" change={changeSort}  />
                    </div>
                </form>
                {loading ? <Loader /> : (
                    <div>   
                        <div className="composition-item filmo-item tab-header">
                            <div>{texts.title}</div>
                            <div>{texts.year}</div>
                            <div>{texts.genre}</div>
                            <div>{texts.direction}</div>
                        </div> 
                        {data.map(function(item,index){
                            return(
                                <Link to={`${url}/${item.id}-${parseLink(item.title)}`} key={index}>
                                    <div className="composition-item filmo-item">
                                        <div dangerouslySetInnerHTML={{ __html: item.title}}/>
                                        <div>{item.year ? item.year : null}</div>
                                        <div>{item.type ? item.type : null}</div>
                                        <div>
                                            {item.directors.length ? (
                                                item.directors.map(function(item, index){
                                                    return(
                                                        <div key={index}>{item.name}</div>
                                                    )
                                                })
                                            ) : null}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                )}


                {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
            </div>
        ) : <Loader />
    )
}
