import React, { useEffect, useState} from 'react'
import {useParams} from "react-router-dom"
import Loader from '../Elements/Loader'
import Gallery from '../Elements/Gallery'
import styles from './Multimedia.module.css'
import placeholder from '../../assets/img/placeholder.jpg'
import {minHeight, mobile, initGallery, unmountGallery} from '../../Helpers'

export default function MultimediaItem(props) {

    const name = 'SpotifyItem'

    // All data
    const [data, updateData] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);

    // Gallery functions & 'variables'
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    const closeLightbox = () => lightboxToggle(false)
    
    let { id } = useParams();
    id = parseInt(id);
    let multimediaLink = `${window.apiUrl}spotify/?id_composer=${id}?lang=${window.language}`


    useEffect(() => {
        minHeight();
        props.updateMenu(10);
        isLoadingUpdate(true)
        fetch(multimediaLink)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            isLoadingUpdate(false);
            initGallery(".gallery-item", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })
        .catch(err => { throw err });

        return () => unmountGallery(".gallery-item", lightboxImages, lightboxToggle)
    }, [id])

   
    return (
        <>
            {data ? (
                <div>
                    {!isLoading ? (
                        <>
                            {data.map(function(item,index){
                                return(
                                <div key={index}>
                                    <h3 className={styles.title}>{item.title}</h3>

                                    {item.subtitle ? (
                                        <div className={styles.itemSubtitle} dangerouslySetInnerHTML={{ __html: item.subtitle }}/>
                                    ) : null}
    
                                    {item.text ? (
                                        <div className={styles.itemText} dangerouslySetInnerHTML={{ __html: item.text }}/>
                                    ) : null}

                                    {item.spotify_link && item.spotify_link.length ? (
                                            <iframe title={`item.title`} src={`https://open.spotify.com/embed/${item.tracklist_type ? item.tracklist_type : 'playlist'}/${item.spotify_link}`} width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                                    ) : null}

                                </div>
                                )
                            })}
                            {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
                        </>
                    ) : <Loader />}
                </div>
            ) : <Loader />}
        </>
    )
}