import React, { useEffect, useState, useContext } from 'react'
import { MSEContext } from '../Context/MainSearchEngineContext'
import { Link, useRouteMatch } from "react-router-dom"
import Loader from '../Elements/Loader'
import { minHeight } from '../../Helpers'
import { parseLink } from '../../Helpers'
import { Translations } from '../../Translations'
import styles from './AllMultimedia.module.css'
import placeholder from '../../assets/img/placeholder.jpg'
import audioImg from '../../assets/img/audio.jpg'
import iconoImg from '../../assets/img/iconography.jpg'
import videoImg from '../../assets/img/video.jpg'
import { mobile } from '../../Helpers'

export default function AllMultimedia(props) {

    const name = 'AllMultimedia'

    // Languages
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // All data
    const [data, updateData] = useState(false);
    const [spotifyData, updateSpotifyData] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [clearRequest, clearRequestUpdate] = useState(false);

    // Pagination options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false);
    const elementAmount = 12;

    // Getting context 
    const showContext = useContext(MSEContext)

    // console.log(name, props)

    let searchParam = searchText ? `&search=${searchText}` : ""
    let multimediaLink = `${window.apiUrl}multimedia?&lang=${window.language}${searchParam}`
    let spotifyLink = `${window.apiUrl}spotify?lang=${window.language}${searchParam}`

    const fetchHandler = (link, updater, fetchName) => {
        showContext.updateMenuPositionHandler(7);
        isLoadingUpdate(true)
        fetch(link)
            .then(res => res.json())
            .then((res) => {
                console.log(fetchName, "link: ", link);
                console.log(fetchName, "response: ", res);
                updater(res);
                isLoadingUpdate(false);
                if (mobile()) {
                    const text = document.getElementsByClassName("text-wrapper")
                    text[0].scrollIntoView();
                }
            })
    }

    useEffect(() => {

        fetchHandler(multimediaLink, updateData, "MultimediaData");
        fetchHandler(spotifyLink, updateSpotifyData, "SpotifyData");
    }, [clearRequest])

    const clear = e => {
        e.preventDefault();
        updateSearchText(false);
        clearRequestUpdate(!clearRequest);
    }

    const submit = e => {
        e.preventDefault();
        fetchHandler();
    }

    let { path, url } = useRouteMatch();

    return (
        <main>
            <div className={`main-wrapper ${styles.mainWrapper}`}>
                {/* <form className="secondary-search" onClick={() => window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={searchText ? searchText : texts.searchPhrase} />
                        <div style={{ textAlign: "center" }}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                </form> */}
                <div className={styles.innerWrapper}>
                    <h1 className="title">{texts.multimedia}</h1>
                    <div className={styles.secondaryWrapper}>
                        <Link to={`${url}/ikonografie`}>
                            <div className={styles.item}>
                                <div className={styles.image} style={{ backgroundImage: `url(${iconoImg})` }}></div>
                                <div>
                                    <div className={styles.itemTitle}>{texts.iconography}</div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className={styles.secondaryWrapper}>
                        <Link to={`${url}/audio`}>
                            <div className={styles.item}>
                                <div className={styles.image} style={{ backgroundImage: `url(${audioImg})` }}></div>
                                <div>
                                    <div className={styles.itemTitle}>Audio</div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className={styles.secondaryWrapper}>
                        <Link to={`${url}/wideo`}>
                            <div className={styles.item}>
                                <div className={styles.image} style={{ backgroundImage: `url(${videoImg})` }}></div>
                                <div>
                                    <div className={styles.itemTitle}>{texts.video}</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    )
}