import React, {useState, useEffect, useContext} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
// import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import Loader from '../Elements/Loader'
import styles from './AllCompositions.module.css'
import {Translations} from '../../Translations'
import Dropdown from '../Elements/Dropdown';
import {parseLink} from '../../Helpers'
import { MSEContext } from '../Context/MainSearchEngineContext'
import {Datalist} from '../Elements/Datalist'

export default function AllCompositions(props) {

    // Just name of component
    const name = "AllCompositions";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Data for dropdowns' lists
    const [composersList, updateComposers] = useState([]);
    const [tonationsList, updateTonations] = useState([]);
    const [castsList, updateCasts] = useState([]);
    const [opusList, updateOpus] = useState([]);
    const [genresList, updateGenres] = useState([]);
    const [creationListStart, updateCreationListStart] = useState([]);
    const [creationListEnd, updateCreationListEnd] = useState([]);
    const [dedicationList, updateDedication] = useState([]);

    // Data selected from dropdowns
    const [composers, composersUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [tonation, tonationUpdate] = useState(false);
    const [casts, castsUpdate] = useState(false);
    const [genres, genresUpdate] = useState(false);
    const [yearStart, yearStartUpdate] = useState(false);
    const [yearEnd, yearEndUpdate] = useState(false);
    const [dedication, dedicationUpdate] = useState(false);
    const [opus, opusUpdate] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);


    const [compositionCheckbox, setCompositionCheckbox] = useState(0);

    const handleCompositionClick = () => {
        setCompositionCheckbox(compositionCheckbox === 0 ? 1 : 0);
      };



    // Getting context 
    const showContext = useContext(MSEContext)

    showContext.updateMenuPositionHandler(4);


    // Get data for search enginge dropdowns
    const dropdownData = () => {

        // Composers
        const composersPromise = composers ? console.log("jest kompzytor") : (fetch(`${window.apiUrl}compositions/composers?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            composersList.push({"name": texts.every, "value": false})
            res.map(function(item,index){
                return(
                        composersList.push({"name": item.name, "value": item.id})
                    );
            })
            // console.log("Kompozytorzy:", composers)
            // console.log("Kompozytorzy:", composersList)
        }))

          // Tonations
          const keysPromise = fetch(`${window.apiUrl}compositions/keys?lang=${window.language}&id_composer=${composers ?  composers : ""}`)
          .then(res => res.json(), tonationsList.length = 0)
          .then((res)=>{
              tonationsList.push({"name": texts.every, "value": false})
              res.map(function(item,index){
                  if(item.name){
                    return(
                        tonationsList.push({"name": item.name, "value": item.id})
                    )
                  }
              })
            //   console.log("Tonacje:", tonationsList)
          })
  
          // Casts
          const castsPromise = fetch(`${window.apiUrl}compositions/cast?lang=${window.language}&id_composer=${composers ?  composers : ""}`)
          .then(res => res.json(), castsList.length = 0)
          .then((res)=>{
              castsList.push({"name": texts.every, "value": false})
              res.map(function(item,index){
                  return(
                          castsList.push({"name": item.name, "value": item.id})
                      );
              })
              // console.log("Obsada", casts)
          })
  
          // Genres
          const genresPromise = fetch(`${window.apiUrl}compositions/genres?lang=${window.language}&id_composer=${composers ?  composers : ""}`)
          .then(res => res.json(), genresList.length = 0)
          .then((res)=>{
  
              genresList.push({"name": texts.every, "value": false})
              res.map(function(item,index){
                  return(
                          genresList.push({"name": item.name, "value": item.id})
                      );
              })
              // console.log("Gatunki:", genres)
          })
  
          // Years
          const yearsPromise = fetch(`${window.apiUrl}compositions/years?id_composer=${composers ?  composers : ""}`)
          .then(res => res.json(), creationListStart.length = 0)
          .then((res)=>{
            creationListStart.push({"name": texts.every, "value": false})
              console.log(`${window.apiUrl}compositions/years?id_composer=${composers ?  composers : ""}`)
              res.map(function(item,index){
                  return(
                    creationListStart.push({"name": item, "value": item})
                      );
              })
              // console.log("Lata:", creation);
          })

        // Opus
        const opusPromise = fetch(`${window.apiUrl}compositions/opuses?id_composer=${composers ?  composers : ""}`)
        .then(res => res.json(), opusList.length = 0)
        .then((res)=>{
            console.log(`${window.apiUrl}compositions/opuses?id_composer=${composers ?  composers : ""}`)
            res.map(function(item,index){
                return(
                    opusList.push({"name": item.name, "value": item.value})
                    );
            })
            // console.log("Opusy:", opusList);
        })
  
          // Dedications
          const dedicationsPromise = fetch(`${window.apiUrl}compositions/dedications?lang=${window.language}&id_composer=${composers ?  composers : ""}`)
          .then(res => res.json(), dedicationList.length = 0)
          .then((res)=>{
              dedicationList.push({"name": texts.every, "value": false})
              res.map(function(item,index){
                  return(
                      dedicationList.push({"name": item.last_name + " " + item.first_name, "value": item.id})
                      );
              })
              // console.log("Dedykacje:", dedication)
          })


        return Promise.all([composersPromise, keysPromise, castsPromise, genresPromise, yearsPromise, opusPromise, dedicationsPromise]);
    }

    let searchParams = "";

    if(composers){
        searchParams += `&id_composer=${composers}`
    }

    if(searchText){
        searchParams += `&search=${searchText}`
    }
 
    if(tonation){
        searchParams += `&id_key=${tonation}`
    }

    if(casts){
        searchParams += `&id_cast=${casts}`
    }

    if(genres){
        searchParams += `&id_genre=${genres}`
    }

    if(yearStart){
        searchParams += `&year_start=${yearStart}`
    }

    if(yearEnd){
        searchParams += `&year_end=${yearEnd}`
    }

    if(dedication){
        searchParams += `&id_dedication_recipient=${dedication}`
    }

    if(opus){
        searchParams += `&opus=${opus}`
    }
    
    if (compositionCheckbox) {
        searchParams += `&text_not_empty=1`;
      } else {
        searchParams += `&text_not_empty=0`;
      }



    const clear = e => {
        e.preventDefault();
        updateSearchText(false);
        composersUpdate(false);
        setNoMore(false);
        updateFetchCounter(1);
        tonationUpdate(false);
        castsUpdate(false);
        genresUpdate(false);
        yearStartUpdate(false);
        yearEndUpdate(false);
        dedicationUpdate(false);
        opusUpdate(false);
        setCompositionCheckbox(0);


        updateComposers([]);
        clearRequestUpdate(!clearRequest)
    }

    let compositionsLink = `${window.apiUrl}compositions?lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`

    const submit = e => {
        loadingUpdate(true);
        e.preventDefault();
        fetch(compositionsLink)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(compositionsLink)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
            loadingUpdate(false);
        })  
    }

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        updateData(false);
        dropdownData().then(()=>{
            fetch(compositionsLink)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(compositionsLink)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        });
    },[clearRequest])

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        if(composers){
        dropdownData().then(()=>{
            fetch(compositionsLink)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(compositionsLink)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        });
        }
    },[composers])

    useEffect(() => {
        console.log("tonation to:", tonation)
    }, [tonation])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(compositionsLink)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(compositionsLink);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    // console.log("path to:", path, "a url to:", url)

    const changeComposers = (name, value) => {
        composersUpdate(value)
    }

    const changeTonation = (name, value) => {
        tonationUpdate(value)
    }

    const changeCasts = (name, value) => {
        castsUpdate(value)
    }

    const changeOpus = (name, value) => {
        opusUpdate(name)
    }

    const changeGenres = (name, value) => {
        genresUpdate(value)
    }

    const changeYearStart = (name, value) => {
        yearStartUpdate(value);
        
        creationListStart.find(({ name }) => name === value );
        const index = creationListStart.findIndex(({ name }) => name === value );
        let table = creationListStart.slice(index,creationListStart.length)
        updateCreationListEnd(table);
    }

    const changeYearEnd = (name, value) => {
        yearEndUpdate(value)
    }

    const changeDedication = (name, value) => {
        if (value !== undefined) {
            dedicationUpdate(`${value}`)
        } else {
            dedicationUpdate(false)
        }
        //dedicationUpdate(`${value}`)
    }

    const cutSearchText = (e) => {
        let value = e;

        while (value[0] === " " && value.length !==0) {
            value = value.slice(1, value.length);
        }
        updateSearchText(value);
    }
    
    const changeCheckbox = (name, value) => {
        setCompositionCheckbox(value)
    }


    
    return (
        data  ? (
            <main>
                <div className="main-wrapper compositions-wrapper" style={{backgroundColor: "#f8f8f8", padding: "30px"}}>
                    <div className={styles.title}>{texts.compositions}</div>
                    <form className={`${styles.compositions} secondary-search`} style={{marginBottom: "30px"}} onClick={()=>window.activeSearch = 'secondary'}>
                        <div>
                            <input type="text" onChange={e => cutSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase}/>
                            <div className="dropdowns-wrapper">
                                {composersList && composersList.length ? (
                                    <div>
                                        <div className="label">{texts.composers}</div>
                                        <Datalist medium data={composersList} change={changeComposers} compositions={true} />
                                    </div>
                                ) : null}
                                {tonationsList && tonationsList.length ? (
                                    <div>
                                        <div className="label">{texts.tonation}</div>
                                        <Datalist data={tonationsList} change={changeTonation} compositions={true}/>
                                    </div>
                                ) : null}
                                {castsList && castsList.length ? (
                                    <div>
                                        <div className="label">{texts.cast}</div>
                                        <Datalist small data={castsList} change={changeCasts} compositions={true}/>
                                    </div>
                                ) : null}
                                {opusList && opusList.length ? (
                                    <div>
                                        <div className="label">{texts.opus}</div>
                                        <Datalist data={opusList} change={changeOpus} compositions={true}/>
                                    </div>
                                ) : null}

                                {genresList && genresList.length ? (
                                    <div>
                                        <div className="label">{texts.genre}</div>
                                        <Datalist medium data={genresList} change={changeGenres} compositions={true}/>
                                    </div>
                                ) : null}
                                {creationListStart && creationListStart.length ? (
                                    <div>
                                        <div className="label">{texts.createYearFrom}</div>
                                        <Datalist data={creationListStart} change={changeYearStart} compositions={true}/>
                                    </div>
                                ) : null}

                                {creationListStart && creationListStart.length ? (
                                    <div>
                                        <div className="label">{texts.createYearTo}</div>
                                        <Datalist data={creationListEnd} change={changeYearEnd} compositions={true}/>
                                    </div>
                                ) : null}
                                
                                {dedicationList && dedicationList.length ? (
                                    <div className="dedication-wrapper">
                                        <div className="label">{texts.dedicationAdres}</div>
                                        <Datalist medium data={dedicationList} change={changeDedication} compositions={true}/>
                                    </div> 
                                ) : null}


                                <div className="composition-wrapper">
                                    <div className="tabel-composition">{texts.compositionCheckbox}</div>
                                    <div onClick={handleCompositionClick} className={`search-composition ${compositionCheckbox ? "selected-composition" : ""}`} change={changeCheckbox} compositions={true}></div>
                                </div>

                            </div>
                            <div style={{textAlign: "center"}}>
                                <button onClick={clear} className="button white">{texts.clear}</button>
                                <button onClick={submit} id="submit-comp" className="button red" type="submit">{texts.search}</button>
                            </div>
                        </div>
                    </form>

                    {!data || loading ? <Loader /> : (
                        <div>
                            <div className="composition-item tab-header">
                                <div>{texts.composer}</div>
                                <div>{texts.title}</div>
                                <div>{texts.type}</div>
                                <div>{texts.createDate}</div>
                            </div>   
                            {data.map(function(item,index){
                                const date = item.human_date ? item.human_date : item.create_date ? item.create_date : "?" 
                                return(
                                    <div className="composition-item-single" key={index}>
                                        {item.id_composer === 1 ? (
                                            <>
                                                    <a target="_blank" href={`${window.chopinUrl}/kompozycja/${item.id}-${parseLink(item.title)}`} key={index}>
                                                        <div className="compositions-item-list">
                                                            <div>{item.composer}</div>
                                                            <div dangerouslySetInnerHTML={{ __html: item.title + ` ${item.opus ? item.opus : ""}` }} />
                                                            <div>{item.genre}</div>
                                                            <div dangerouslySetInnerHTML={{ __html: date }} />
                                                        </div>
                                                    </a>

                                            </>
                                        ) : (
                                            <div>
                                                <Link to={`/${window.language}/osoba/${item.id_composer}-${parseLink(item.composer)}/kompozycje/${item.id}-${parseLink(item.title)}`} key={index}>
                                                    <div className="compositions-item-list">
                                                        <div>{item.composer}</div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.title + ` ${item.opus ? item.opus : ""}` }} />
                                                        <div>{item.genre}</div>
                                                        <div dangerouslySetInnerHTML={{ __html: date }} />
                                                    </div>
                                                </Link>
                                            </div>
                                        )}

                                        {item.text && item.text.length ? (
                                            <div className="composition-item-text" dangerouslySetInnerHTML={{ __html: item.text.slice(0,300)+"..." }}/>
                                    ) : null}
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                    {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
                </div>
            </main>

        ) : <Loader />
    )
}
