import React, {useContext, useState, useEffect} from 'react'
import './SingleSearch.css'
import {useHistory} from "react-router-dom";
import {MSEContext} from '../../Context/MainSearchEngineContext';
import { Translations } from "../../../Translations";

export default function SingleSearch() {

    const translation = Translations();

    // Getting context 
    const mSEContext = useContext(MSEContext);
    const [texts, updateTexts] = useState(translation);

    // Creating reference to input
    const inputRef = React.createRef();

    // History method for redirect
    let history = useHistory()

    const searchComposer = e => {
        e.preventDefault();
        console.log(inputRef.current.value);
        mSEContext.updateAllSearchHandler(inputRef.current.value)
        history.push({ pathname: `/${window.language}/wyszukiwarka`})
    }

    useEffect(() => {
        window.addEventListener("keypress", e => {
            if (e.key === 'Enter') {
                e.preventDefault();
                if(mSEContext.searchActive === "single"){
                    document.getElementById("single-submit").click()
                }
            }
        })
        return () => {
            window.removeEventListener("keypress", e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    if(mSEContext.searchActive === "single"){
                        document.getElementById("single-submit").click()
                    }
                }
            })
        }
    }, [mSEContext.searchActive])

    return (
        <div id="single-search">
            <form onSubmit={searchComposer}>
                <input onChange={()=>window.activeSearch = 'single'} ref={inputRef} type="text" className="composer" placeholder={texts.search}/>
                <button id="single-submit" className="bg-contain" type="submit"></button>
            </form>
        </div>
    )
}
