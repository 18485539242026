import React, {useState, useEffect} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {Translations} from '../../../Translations'
import Loader from '../Loader'
import styles from '../Composition/Composition.module.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'

export default function LetterItem(props) {

    let { id } = useParams();
    id = parseInt(id);

    const translation = Translations();

    const name = "letterItem";
    const [data, updateData] = useState(false);
    const [loading, loadingUpdate] = useState(false);
    const [texts, updateTexts] = useState(translation);

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 300);
        props.updateMenu(9);
        fetch(`${window.apiUrl}letter/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            initGallery(".gallery-text img", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [])

    // Gallery function
    const closeLightbox = () => lightboxToggle(false);
    

    return (
        data ? (
            <div className="composition-single">
                <div>
                    <h1 className={styles.mainTitle} dangerouslySetInnerHTML={{__html: data.name }}/ >
                </div>

                {data.image ? (
                    <div>
                        <img style={{maxWidth:"100%"}} src={data.image} alt=""/>
                    </div>
                ) : null}

                <div className="composition-single--table">
                    {data.senders && data.senders.length ? (
                        <div>
                            <div className={styles.label}>{texts.sender}</div>
                            <div>
                                {data.senders.map(function(item, index){
                                    return(
                                        <span key={index}>{`${index > 0 ? ", " : ""}${item.name}`}</span>
                                    )
                                })}
                            </div>
                        </div>
                    ) : null}
                    {data.addressees && data.addressees.length ? (
                        <div>
                            <div className={styles.label}>{texts.addresse}</div>
                            <div>
                                {data.addressees.map(function(item, index){
                                    return(
                                        <span key={index}>{`${index > 0 ? ", " : ""}${item.name}`}</span>
                                    )
                                })}
                            </div>
                        </div>
                    ) : null}
                    {data.data_txt && data.data_txt.length ? (
                        <div>
                            <div className={styles.label}>{texts.date}</div>
                            <div>{data.data_txt}</div>
                        </div>
                    ) : null}
                </div>
                
                {data.txt ? (
                    <div>
                        <div className={`${styles.mainText} gallery-text`}>
                            <div dangerouslySetInnerHTML={{__html: data.txt}}/>
                        </div>
                    </div>
                ) : null}

                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
            </div>
        ) : <Loader />
    )
}