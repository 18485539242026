import React, {useState, useEffect} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
// import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import Loader from '../Loader'
import Dropdown from '../Dropdown'
import './Manuscripts.css'
import {Translations} from '../../../Translations'
import {parseLink} from '../../../Helpers'
import {mobile} from '../../../Helpers'
import {Datalist} from '../Datalist'
import placeholder from '../../../assets/img/placeholder.jpg'

export default function Manuscripts(props) {

    // Just name of component
    const name = "Manuscripts";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data currently fetching?
    const [loading, loadingUpdate] = useState(false);

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

    // Data for dropdowns' lists
    const [categoriesList, updateCategories] = useState([]);
    const [placesList, updatePlaces] = useState([]);
    const [compositionsList, updateCompositions] = useState([]);

    // Data selected from dropdowns
    const [categories, categoriesUpdate] = useState(false);
    const [places, placesUpdate] = useState(false);
    const [compositions, compositionsUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [sortType, updateSortType] = useState(false);

    const [clearRequest, clearRequestUpdate] = useState(false);

    const sortParams = [
        {name: texts.title+" "+texts.asc, value: "titleAsc"},
        {name: texts.title+" "+texts.desc, value: "titleDesc"},
        {name: texts.sourceType+" "+texts.asc, value: "sourceAsc"},
        {name: texts.sourceType+" "+texts.desc, value: "sourceDesc"},
        {name: texts.place+" "+texts.asc, value: "placeAsc"},
        {name: texts.place+" "+texts.desc, value: "placeDesc"},
    ]

        // Get data for search enginge dropdowns
        const dropdownData = () => {

            // Categories
            const categoriesPromise = fetch(`${window.apiUrl}manuscripts/categories?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), categoriesList.length = 0)
            .then((res)=>{
                categoriesList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                        categoriesList.push({"name": item.name, "value": item.id})
                        );
                })
                // console.log("Kategorie:", categories)
            })
    
            // Places
            const placesPromise = fetch(`${window.apiUrl}manuscripts/places_of_storage?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), placesList.length = 0)
            .then((res)=>{
                placesList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                            placesList.push({"name": item.name, "value": item.id})
                        );
                })
                // console.log("Miejsca", places)
            })
    
            // compositions
            const compositionsPromise = fetch(`${window.apiUrl}manuscripts/compositions?lang=${window.language}&id_composer=${props.id ?  props.id : ""}`)
            .then(res => res.json(), compositionsList.length = 0)
            .then((res)=>{
                compositionsList.push({"name": texts.every, "value": false})
                res.map(function(item,index){
                    return(
                            compositionsList.push({"name": item.name, "value": item.id})
                        );
                })
                // console.log("Gatunki:", compositions)
            })
    
            return Promise.all([categoriesPromise, placesPromise, compositionsPromise]);
        }

        let searchParams = "";
    
        if(categories){
            searchParams += `&id_category=${categories}`
        }
    
        if(places){
            searchParams += `&id_place_of_storage_institution=${places}`
        }
        
        if(compositions){
            searchParams += `&id_composition=${compositions}`
        }
    
        if(searchText){
            searchParams += `&search=${searchText}`
        }

        if(sortType){
            searchParams += `&sort=${sortType}`
        }

        const clear = e => {
            e.preventDefault();
            updateSortType(false)
            updateSearchText(false);
            categoriesUpdate(false);
            placesUpdate(false);
            compositionsUpdate(false);
            setNoMore(false)
            clearRequestUpdate(!clearRequest)
        }

        const submit = e => {
            loadingUpdate(true);
            e.preventDefault();
            fetch(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                loadingUpdate(false);
            })  
        }
    
            // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        props.updateMenu(5);
        updateData(false);
        dropdownData().then(()=>{
            fetch(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                console.log(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                updateData(res);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                if(mobile()){
                    const text = document.getElementsByClassName("text-wrapper")
                    text[0].scrollIntoView(); 
                }
            })    
        });
    },[clearRequest])

    useEffect(() => {
        setNoMore(false)
        if(sortType){
            loadingUpdate(true);
            fetch(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                // console.log(name, res);
                console.log(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
                updateData(res);
                loadingUpdate(false);
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [sortType])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            // loadingUpdate(true);
            fetch(`${window.apiUrl}manuscripts?id_composer=${props.id}&lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}${searchParams}`)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
                // loadingUpdate(false);
            })    
        }
    }, [fetchCounter])

    const changeCategories = (name, value) => {
        categoriesUpdate(value)
    }

    const changePlaces = (name, value) => {
        placesUpdate(value)
    }

    const changeCompositions = (name, value) => {
        compositionsUpdate(value)
    }

    const changeSort = (name, value) => {
        updateSortType(value)
    }

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    console.log("path to:", path, "a url to:", url)

    return (
        data ? (
            <div className="manuscripts-wrapper">
                <div className="title">{texts.manuscripts}</div>
                <form className="secondary-search" onClick={()=>window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={texts.searchPhrase}/>
                        <div className="dropdowns-wrapper three">
                            {categoriesList && categoriesList.length ? (
                                <div>
                                    <div className="label">{texts.sourceType}</div>
                                    <Dropdown data={categoriesList} type="tonacja" change={changeCategories}  />
                                </div>
                            ) : null}

                            {placesList && placesList.length ? (
                                <div>
                                    <div className="label">{texts.storagePlace}</div>
                                    <Datalist small data={placesList} change={changePlaces} />
                                </div>
                            ) : null}

                            {compositionsList && compositionsList.length ? (
                                <div>
                                    <div className="label">{texts.compositions}</div>
                                    <Datalist small data={compositionsList} change={changeCompositions} />
                                </div>
                            ) : null}

                        </div>
                        <div style={{textAlign: "center"}}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                    <div className="sort-section">
                        <span className="label">{texts.sortBy}</span>
                        <Dropdown data={sortParams} type="sort" change={changeSort}  />
                    </div>
                </form>
                {loading ? <Loader /> : (
                    <div>
                        <div className="manuscript-item tab-header">
                            <div>{texts.title}</div>
                            <div>{texts.sourceType}</div>
                            <div>{texts.storagePlace}</div>
                        </div>   
                        {data.map(function(item,index){
                            const image = item.image && item.image.length ? item.image : placeholder;

                            return(
                                <Link to={`${url}/${item.id}-${parseLink(item.title)}`} key={index}>
                                    <div className="manuscript-item">
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                            <div className="background bg-cover" style={{backgroundImage: `url(${image})`}}></div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: item.manuscripts_category }} />
                                        <div dangerouslySetInnerHTML={{ __html: item.place_of_storage }} />
                                    </div>
                                </Link>
                            
                            )
                        })}
                    </div>
                )}

                {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
            </div>

        ) : <Loader />
    )
}
