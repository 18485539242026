import React, {useState} from 'react';

export const MSEContext = React.createContext({
    name: "",
    search: "",
    allSearch: "",
    yearFrom: false,
    yearTo: false,
    menuPosition: 1,
    searchActive: false
});


const MSEContextProvider = props => {

    const [name, updateName] = useState("");
    const [search, updateSearch] = useState("");
    const [allSearch, updateAllSearch] = useState(false);
    const [yearFrom, updateYearFrom] = useState(false);
    const [yearTo, updateYearTo] = useState(false);
    const [menuPosition, updateMenuPosition] = useState(1);
    const [searchActive, updateSearchActive] = useState(false);
    

    const updateSearchHandler = e =>{
        updateSearch(e)
    }

    const updateAllSearchHandler = e =>{
        updateAllSearch(e)
    }

    const updateNameHandler = e => {
        updateName(e)
    }

    const updateYearFromHandler = e => {
        updateYearFrom(e)
    }

    const updateYearToHandler = e => {
        updateYearTo(e)
    }

    const updateMenuPositionHandler = e => {
        updateMenuPosition(e)
    }

    const updateSearchActiveHandler = e => {
        updateSearchActive(e)
    }

    return(
        <MSEContext.Provider value={{ 
            name: name, 
            search: search, 
            allSearch: allSearch,
            yearFrom: yearFrom, 
            yearTo: yearTo, 
            menuPosition: menuPosition,
            searchActive: searchActive,
            updateSearchHandler, updateNameHandler, updateYearFromHandler, updateYearToHandler, updateAllSearchHandler, updateMenuPositionHandler, updateSearchActiveHandler }}>
            {props.children}
        </MSEContext.Provider>
    )
}

export default MSEContextProvider;