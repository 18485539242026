import React from 'react';
import ReactDOM from 'react-dom';
import Builder from './Builder';
import MSEContextProvider from './Components/Context/MainSearchEngineContext'
import * as serviceWorker from './serviceWorker';
import './assets/css/index.css'

ReactDOM.render(
    <MSEContextProvider>
        <Builder />
    </MSEContextProvider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
