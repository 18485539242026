import React, {useState, useEffect} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {Translations} from '../../../Translations'
import Loader from '../Loader'
import styles from './Article.module.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'

export default function Article(props) {

    let { id } = useParams();
    id = parseInt(id);

    const translation = Translations();

    const name = "Article";
    const [data, updateData] = useState(false);
    const [texts, updateTexts] = useState(translation);

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 300);
        props.updateMenu(11);
        fetch(`${window.apiUrl}article/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            initGallery(".gallery-text img", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [])

    // Gallery function
    const closeLightbox = () => lightboxToggle(false)

    const authorsList = (authors) => {

        let authorsString = ""
        authors.forEach((item, index) => {
            if(index !== authors.length - 1) {
                authorsString = `${authorsString} ${item.first_name}&nbsp;${item.last_name},`
            } else {
                authorsString = `${authorsString} ${item.first_name}&nbsp;${item.last_name}`
            }
        })

        return authorsString
    }

    return (
        data ? (
            <div className="composition-single">
                <div>
                    <h1 className={styles.mainTitle} dangerouslySetInnerHTML={{__html: `${data.title ? data.title : ""} ${data.opus ? data.opus : ""}`}}/>
                </div>

                {data.authors ? (
                    <div>
                        <h3 className={styles.subTitle} dangerouslySetInnerHTML={{__html: authorsList(data.authors)}}/>
                    </div>) : null}
                
                {data.image ? (
                    <div>
                        <img style={{maxWidth:"100%"}} src={data.image} alt={data.alt_photo ? data.alt_photo : ""}/>
                    </div>) : null}
                {data.txt ? (
                    <div className={styles.mainTextWrapper}>
                        <div className={`${styles.mainText} gallery-text`}>
                            <div dangerouslySetInnerHTML={{__html: data.txt}}/>
                        </div>
                    </div>) : null}
                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
            </div>
        ) : <Loader />
    )
}