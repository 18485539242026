import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import './MainSearchEngine.css'
import ComposerBox from '../ComposerBox'
import {mobile} from "../../../Helpers"
import { MSEContext } from '../../Context/MainSearchEngineContext'
import {Translations} from '../../../Translations'
import Loader from '../Loader'

export default function MainSearchEngine() {

    const name = "MainSearchEngine";

    const translation = Translations();

    var latinize = require('latinize');

    const [texts, updateTexts] = useState(translation);
    const [menuState, menuToggle] = useState(false);
    const [generes, updateGeneres] = useState([]);
    const [generesList, updateGeneresList] = useState("");
    const [isLoading, isLoadingUpdate] = useState(true);
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)

    // Type of using grid
    const [displaySingle, switchDisplay] = useState(true)

    const isOpened = menuState ? "opened" : "closed";

    // Getting context 
    const showContext = useContext(MSEContext)

    const [data, updateData] = useState([])

    // Reset of searched name
    const resetName = () => showContext.updateNameHandler("");
    
    // Reset of searched year
    const resetYear = () => showContext.updateYearHandler(false)
    
    const elementAmount = 12;

    let generesListKeeper = "";

    useEffect(() => {
        updateGeneresList([])
        generes.forEach(function (e) {
            generesListKeeper += `&genres[]=${e}`;
            updateGeneresList(generesListKeeper)
        })
    }, [generes])

    // Does year exists in context's state?
    let year = showContext.yearFrom && showContext.yearTo ? `&period_life_start=${showContext.yearFrom}-01-01&period_life_end=${showContext.yearTo}-01-01` : "";

    // Dynamic fetching link
    let dataUrl = `${window.apiUrl}people?offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}&lang=${window.language}&search=${showContext.search}&last_name=${showContext.name}${year}${generesList}`
    
    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    showContext.updateMenuPositionHandler(2);
    
    useEffect(() => {
        if(fetchCounter > 1){
            isLoadingUpdate(true);
            fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(data.concat(out));
                if(out.length < elementAmount){
                    setNoMore(true);
                }
                isLoadingUpdate(false);
            })
            .catch(err => { throw err });
        } 
    }, [fetchCounter])

    // Get Data
    useEffect(() => {
        isLoadingUpdate(true);
        updateFetchCounter(1)
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
                out.length < elementAmount ? setNoMore(true) : setNoMore(false)
                isLoadingUpdate(false);
            })
            .catch(err => { throw err });
    }, [showContext, generesList]);


    const selectHandler = e => {
        e.preventDefault();
        e.currentTarget.classList.toggle("selected");
        const value = e.currentTarget.querySelector("input").value;
        if (!generes.includes(value)){
            updateGeneres(oldArray => [...oldArray, value])
        } else {
            updateGeneres(generes.filter((e) => (e !== value)))
        }
        // Toggle input
        e.currentTarget.querySelector("input").checked = !e.currentTarget.querySelector("input").checked
    }


    return (
        data ? (
            mobile() ? (
                <form id="MainSearchEngine">
                    <div className="input-area">
                        <div className="mobile-functions"></div>
                        
                        <div className={`mobile-search-area ${isOpened}`}>
                            <div>
                            <span>{texts.genres}:</span>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-4">{texts.mazurek}</label>
                                    <input type="checkbox" name="compositors" id="checkbox-4" value="32" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-5">{texts.concert}</label>
                                    <input type="checkbox" name="multimedia" id="checkbox-5" value="1" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-6">{texts.song}</label>
                                    <input type="checkbox" name="source" id="checkbox-6" value="6" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-7">{texts.symphony}</label>
                                    <input type="checkbox" name="source" id="checkbox-7" value="7" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-8">{texts.sonata}</label>
                                    <input type="checkbox" name="source" id="checkbox-8" value="9" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-9">{texts.polonaise}</label>
                                    <input type="checkbox" name="source" id="checkbox-9" value="23" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-10">{texts.etude}</label>
                                    <input type="checkbox" name="source" id="checkbox-10" value="22" />
                                </div>
                                <div className="select-area" onClick={selectHandler}>
                                    <div className="custom-checkbox bg-contain"></div>
                                    <label htmlFor="checkbox-10">{texts.nocturne}</label>
                                    <input type="checkbox" name="source" id="checkbox-10" value="24" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="navigation">
                            <div><span>{texts.searchRecords}</span></div>
                            <div>
                                {showContext.year ? <button onClick={resetYear} className="result-box">urodzeni w {showContext.year}</button> : null }
                                {showContext.name ? <button onClick={resetName} className="result-box">na literę {showContext.name}</button> : null}
                            </div>
                            <div className="display">
                                <div className={displaySingle ? "active" : ""} onClick={() => switchDisplay(true)}>
                                    <svg version="1.1" x="0px" y="0px" width="600px" height="600px" viewBox="0 0 600 600" enable-background="new 0 0 600 600">
                                        <path d="M599.75,29.838C599.75,13.353,586.397,0,569.912,0H29.838C13.353,0,0,13.353,0,29.838v540.074c0,16.485,13.353,29.838,29.838,29.838h540.074c16.485,0,29.838-13.353,29.838-29.838V29.838z M540.073,540.073H59.677V59.677h480.396V540.073z"/>
                                    </svg>
                                </div>
                                <div className={displaySingle ? "" : "active"} onClick={() => switchDisplay(false)}>
                                    <svg height="600pt" viewBox="-19 -19 600 600" width="600pt" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m251.25 12.5c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 213.75h-201.25v-201.25h201.25zm0 0" />
                                        <path d="m562.5 12.5c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 213.75h-201.25v-201.25h201.25zm0 0" />
                                        <path d="m251.25 323.75c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 212.5h-201.25v-200h201.25zm0 0" />
                                        <path d="m562.5 323.75c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 212.5h-201.25v-200h201.25zm0 0" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className={`result-content ${displaySingle ? "" : "grid-view"}`}>
                            {data ? data.map(function (item, index) {
                                const link = item.id == 1 ? `https://chopin.nifc.pl/pl` : `/${window.language}/osoba/${item.id}-${latinize(item.first_name)}-${latinize(item.last_name)}`
                                if(item.id == 1){
                                    return (
                                        <a href={link} target="_blank" key={index}>
                                            <ComposerBox info={item} />
                                        </a>
                                    )
                                } else {
                                    return (
                                        <Link to={`${link}`} key={index}>
                                            <ComposerBox info={item} />
                                        </Link>
                                    )
                                }

                            }) : <div className="no-results">{texts.noResults}</div>}
                        </div>
                        {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
                    </div>
                </form>
            ) : (
                    <form id="MainSearchEngine">
                        <div></div>
                        <div>
                            <div className="navigation">
                                <div><span>{texts.searchRecords}</span></div>
                                <div>
                                    {showContext.year ? <button onClick={resetYear} className="result-box">urodzeni w {showContext.year}</button> : null}
                                    {showContext.name && showContext.name.length > 0 ? <button onClick={resetName} className="result-box">na literę {showContext.name}</button> : null}
                                </div>
                            </div>
                            <div className={`result-content ${isLoading ? "loading" : ""}`}>
                                {data ? data.map(function (item, index) {
                                    const link = item.id == 1 ? `https://chopin.nifc.pl/pl` : `/${window.language}/osoba/${item.id}-${latinize(item.first_name)}-${latinize(item.last_name)}`
                                    if(item.id == 1){
                                        return (
                                            <a href={link} target="_blank" key={index}>
                                                <ComposerBox info={item} />
                                            </a>
                                        )
                                    } else {
                                        return (
                                            <Link to={`${link}`} key={index}>
                                                <ComposerBox info={item} />
                                            </Link>
                                        )
                                    }

                                }) : <div className="no-results">{texts.noResults}</div>}
                            </div>
                            {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
                        </div>
                    </form >
                )
        ) : <Loader name={name} />
    )
}
