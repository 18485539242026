import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from 'react'
import './Datalist.css'
import useOnclickOutside from "react-cool-onclickoutside";
import { MSEContext } from '../../Context/MainSearchEngineContext'

export const Datalist = forwardRef((props, ref) => {
    useImperativeHandle(ref,() => ({
            reset() {
                updateOpened(false)
                updateValue("")
                updateName("")
            }
         }),
     )
    const [opened, updateOpened] = useState(false)
    const [value, updateValue] = useState("")
    const [name, updateName] = useState("")

    // Creating reference to input
    const listRef = React.createRef();

    // Getting context 
    const mSEContext = useContext(MSEContext);

    const wrapperRef = useOnclickOutside(() => {
        updateOpened(false);
      });

    const check = (phrase) => {
        let contains = false;

        props.data.map(function(item,index){
            if(item.name && item.name.toString().toLowerCase().includes(phrase.toString().toLowerCase())) {
                contains = true;
            }
        })

        if(phrase.length === 0) {
            props.change(false)
        }

        return contains;
    }

    const inputUpdate = e => {
        if(e.target.value !== " ") {
            if(props.compositions){
                if(check(e.target.value)) {
                    updateName(e.target.value)
                }
            } else {
                updateName(e.target.value)
            }
    
            if(e.target.value.length){
                updateOpened(true)
            }
        } 
    }

    const updateYearFromHandler = e => mSEContext.updateYearFromHandler(e)
    const updateYearToHandler = e => mSEContext.updateYearToHandler(e)
    
    const choice = (value , name) => {
        if(props.change){
            props.change(name, value)
        }
        updateValue(value);
        updateName(name);
        updateOpened(false);
    }

    useEffect(() => {
        if(props.compositions){
            let validate = false;
            if(!opened) {
                props.data.map(function(item,index){
                    if(item.name === name) {
                        validate = true;
                    } 
                })

                if(!validate) {
                    props.change(false)
                    updateName("");
                    updateValue("");
                }
            }
        }
    }, [opened])

    useEffect(() => {
        let counter = 0;
        listRef.current.querySelectorAll(".datalist-record").forEach((item) => {
            if(props.maxRecords){
                if(item.innerText.toLowerCase().includes(name.toString().toLowerCase())){
                    counter++;
                    if(counter < props.maxRecords) {
                        item.classList.add("record-visible");
                        item.classList.remove("record-invisible");
                    } else {
                        item.classList.remove("record-visible");
                        item.classList.add("record-invisible");
                    }
                } else {
                    item.classList.remove("record-visible");
                    item.classList.add("record-invisible");
                }
            } else {
                if(item.innerText.toLowerCase().includes(name.toString().toLowerCase())){
                    item.style.display = 'block'
                } else {
                    item.style.display = 'none'
                } 
            }
        })
        props.yearFrom ? updateYearFromHandler(value) : void(0);
        props.yearTo ? updateYearToHandler(value) : void(0);
    }, [name, value])

    return (
        <div ref={wrapperRef} className={`custom-datalist ${props.small ? "small" : ""} ${props.medium ? "medium" : ""} ${props.inline ? "inline" : ""}`}>
            <input value={name ? name : props.label ? props.label : "" } onChange={inputUpdate.bind(this)} onClick={inputUpdate.bind(this)} type="text"/>
            <div ref={listRef} className={`datalist-records ${opened ? "opened" : ""}`}>
                {props.data.map(function(item,index){
                    return(
                        <div className="datalist-record" key={index} onClick={choice.bind(null, item.value, item.name)} value={item.value}>{item.name}</div>
                    )
                })}
            </div>
        </div>
    )
})