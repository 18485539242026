import React, { useEffect, useState, useContext} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {MSEContext} from '../Context/MainSearchEngineContext';
import Loader from '../Elements/Loader'
import {parseLink, minHeight} from '../../Helpers'
import {Translations} from '../../Translations'
import ComposerBox from '../Elements/ComposerBox'
import styles from './AllSearch.module.css'

export default function AllSearch() {

    // Getting context 
    const context = useContext(MSEContext);

    // Languages
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // All data
    const [composers, updateComposers] = useState(false);
    const [compositions, updateCompositions] = useState(false);
    const [manuscripts, updateManuscripts] = useState(false);
    const [firstEditions, updateFirstEditions] = useState(false);
    const [bibliography, updateBibliography] = useState(false);
    const [filmography, updateFilmography] = useState(false);
    const [letters, updateLetters] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);

    useEffect(() => {
        minHeight()
    }, [])

    useEffect(() => {
        if(context.allSearch){
            getData()
        } else {
            updateComposers(false)
        }
    }, [context])

    const getData = () => {
        const composerPromise = fetchData(composerLink, updateComposers, "Composers");
        const compositionPromise = fetchData(compositionLink, updateCompositions, "Compositions");
        const manuscriptPromise = fetchData(manuscriptLink, updateManuscripts, "Manuscripts");
        const firstEditionPromise = fetchData(firstEditionLink, updateFirstEditions, "FirstEditions");
        const bibliographyPromise = fetchData(bibliographyLink, updateBibliography, "Bibliography");
        const filmographyPromise = fetchData(filmographyLink, updateFilmography, "Filmography");
        const lettersPromise = fetchData(lettersLink, updateLetters, "Letters");
        return Promise.all([composerPromise, compositionPromise, manuscriptPromise, firstEditionPromise, bibliographyPromise, filmographyPromise, lettersPromise]);
    }

    let searchParam = context.allSearch ? `&search=${context.allSearch}` : "";
    let composerLink = `${window.apiUrl}people?lang=${window.language}${searchParam}`
    let compositionLink = `${window.apiUrl}compositions?lang=${window.language}${searchParam}`
    let manuscriptLink = `${window.apiUrl}manuscripts?lang=${window.language}${searchParam}`
    let firstEditionLink = `${window.apiUrl}first_editions?lang=${window.language}${searchParam}`
    let bibliographyLink = `${window.apiUrl}bibliographies?lang=${window.language}${searchParam}`
    let filmographyLink = `${window.apiUrl}filmography?lang=${window.language}${searchParam}`
    let lettersLink = `${window.apiUrl}letters?lang=${window.language}${searchParam}`

    const fetchData = (url, update, name) => {
        isLoadingUpdate(true)
        fetch(url)
        .then(res => res.json())
        .then((res)=>{
            update(res);
            isLoadingUpdate(false)
        })
        .catch(err => { throw err });
    }

    const composersList = composers && composers.length ? (
        <div className={styles.listWrapper}>
            <div className={styles.title}>{texts.composers}</div>
            <div className={`result-content ${styles.item} ${styles.composers}`}>
                {composers ? composers.map((item, index) =>
                    <Link to={`/${window.language}/osoba/${item.id}-${parseLink(item.first_name)}-${parseLink(item.last_name)}`} key={index}>
                        <ComposerBox info={item} />
                    </Link>
                ) : <div className="no-results">{texts.noResults}</div>  }
            </div>
        </div>
    ) : null

    const compositionsList = compositions && compositions.length ? (
        <div className={styles.listWrapper}>   
            <div className={styles.title}>{texts.compositions}</div>
            {compositions.map(function(item,index){
                return(
                    <Link to={`/${window.language}/osoba/${item.id_composer}-${parseLink(item.composer)}/kompozycje/${item.id}-${parseLink(item.title)}`} key={index}>
                        <div className={`${styles.compositionItem} ${styles.item}`}>
                            <div dangerouslySetInnerHTML={{ __html: item.title + ` ${item.opus ? item.opus : ""}` }}/>
                            <div>{item.composer}</div>
                            <div>{item.genre}</div>
                            <div dangerouslySetInnerHTML={{ __html: item.human_date }}/>
                        </div>
                    </Link>
                )
            })}
        </div>
    ) : null;

    const manuscriptsList = manuscripts && manuscripts.length ? (
        <div className={styles.listWrapper}>
            <div className={styles.title}>{texts.manuscripts}</div>
            {manuscripts.map(function(item,index){
                return(
                    <Link to={`/${window.language}/osoba/${item.id_composer}-${parseLink(item.composer)}/rekopisy/${item.id}-${parseLink(item.title)}`} key={index}>
                        <div className={`${styles.manuscriptItem} ${styles.item}`}>
                            <div dangerouslySetInnerHTML={{ __html: item.title }}/>
                            <div dangerouslySetInnerHTML={{ __html: item.manuscripts_category }} />
                            <div dangerouslySetInnerHTML={{ __html: item.place_of_storage }} />
                        </div>
                    </Link>
                )
            })}
        </div>
    ) : null 

    const firstEditionsList = firstEditions && firstEditions.length ? (
        <div className={styles.listWrapper}>
            <div className={styles.title}>{texts.firstEditions}</div>
            {firstEditions.map(function(item,index){
                return(
                    <Link to={`/${window.language}/osoba/${item.composers[0].id}-${parseLink(item.composers[0].first_name+" "+item.composers[0].last_name)}/rekopisy/${item.id}-${parseLink(item.title)}`} key={index}>
                        <div className={`${styles.firstEditionItem} ${styles.item}`}>
                            <div dangerouslySetInnerHTML={{ __html: item.title }}/>
                            <div>{item.publishinghouse}</div>
                            <div>{item.place}</div>
                            <div>{item.date}</div>
                            <div className="first-edition--text" dangerouslySetInnerHTML={{ __html: item.text }}/>
                        </div>
                    </Link>
                )
            })}
        </div>
    ) : null 

    const bibliographyList = bibliography && bibliography.length ? (
        <div className={styles.listWrapper}>
            <div className={styles.title}>{texts.biblio}</div>
            {bibliography.map(function(item,index){
                return(
                    <Link to={`/${window.language}/osoba/${item.characters[0].id}-${parseLink(item.characters[0].first_name+" "+item.characters[0].last_name)}/bibliografia/${item.id}-${item.title ? parseLink(item.title) : null}`} key={index}>
                        <div key={index} className={`${styles.firstEditionItem} ${styles.item}`}>
                            <div dangerouslySetInnerHTML={{ __html: item.title}}/>
                            <div>
                                {item.authors.length ? (
                                    item.authors.map(function(item, index){
                                        return(
                                            <div key={index}><span>{item.first_name}</span> <span>{item.last_name}</span></div>
                                        )
                                    })
                                ) : null}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item.date_of_publishing_text ? item.date_of_publishing_text : "" }}/>
                            <div>{item.category ? item.category : null}</div>

                        </div>
                    </Link>
                )
            })}
        </div>
    ) : null 

    const filmographyList = filmography && filmography.length ? (
        <div className={styles.listWrapper}>
            <div className={styles.title}>{texts.filmography}</div>
            {filmography.map(function(item,index){
                return(
                    <Link to={`/${window.language}/osoba/${item.characters[0].id}-${parseLink(item.characters[0].first_name+" "+item.characters[0].last_name)}/filografia/${item.id}-${parseLink(item.title)}`} key={index}>
                        <div className={`${styles.filmographyItem} ${styles.item}`}>
                            <div dangerouslySetInnerHTML={{ __html: item.title}}/>
                            <div>{item.type ? item.type : null}</div>
                            <div>
                                {item.directors.length ? (
                                    item.directors.map(function(item, index){
                                        return(
                                            <div key={index}>{item.name}</div>
                                        )
                                    })
                                ) : null}
                            </div>
                        </div>
                    </Link>
                )
            })}
        </div>
    ) : null 

    const lettersList = letters && letters.length ? (
        <div className={styles.listWrapper}>
            <div className={styles.title}>{texts.letters}</div>
            {letters.map(function(item,index){
                return(
                    <Link to={`/${window.language}/osoba/${item.composers[0].id}-${parseLink(item.composers[0].name)}/listy/${item.id}-${parseLink(item.name)}`} key={index}>
                        <div className={`${styles.lettersItem} ${styles.item}`}>
                            <div dangerouslySetInnerHTML={{ __html: item.data_txt}}/>
                            <div dangerouslySetInnerHTML={{ __html: item.name}}/>
                            <div dangerouslySetInnerHTML={{ __html: item.sender_name}}/>
                            <div dangerouslySetInnerHTML={{ __html: item.addressee_name}}/>
                            <div dangerouslySetInnerHTML={{ __html: item.date_of_publishing_text ? item.date_of_publishing_text : "" }}/>
                            <div>{item.category ? item.category : null}</div>
                        </div>
                    </Link>
                )
            })}
        </div>
    ) : null 


    return (
        <main>
            <div className="main-wrapper">
                {!isLoading ? (
                    <div>
                        {composersList}
                        {compositionsList}
                        {manuscriptsList}
                        {firstEditionsList}
                        {bibliographyList}
                        {filmographyList}
                        {lettersList}
                    </div>
                ) : <Loader />}

            </div>
        </main>
    )
}