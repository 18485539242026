import React, { useEffect, useState, useContext, useRef} from "react";
import "./TopSearchEngine.css";
import {Datalist} from '../Datalist'
import { MSEContext } from "../../Context/MainSearchEngineContext";
import { Translations } from "../../../Translations";

export default function TopSearchEngine(props) {
	
	// Get translations of static labels in current language
	const translation = Translations();
	const [texts, updateTexts] = useState(translation);

	// Name of component for debugging purpose
	const name = "TopSearchEngine";

	// Getting context
	const mSEContext = useContext(MSEContext);

	const inputRef = React.createRef();

	const datalistRef1 = React.useRef();
	const datalistRef2 = React.useRef();
	
	// Create array of objects for Datalist components
	const dateObject = () => {
		let createdArray = [];
		const yearStart = 1750;
		const yearsNumber = 204;
		for(let i = 0; i < yearsNumber; i++){
			createdArray.push({name: `${yearStart + i}`, value: yearStart+i})
		}
		return createdArray;
	}

	// Update state with context's function
	const inputUpdateHandler = (e) => {
		mSEContext.updateSearchHandler(e.currentTarget.value);
	};

	useEffect( () => {
		mSEContext.updateSearchHandler("");
		mSEContext.updateYearFromHandler(false);
		mSEContext.updateYearToHandler(false);
		inputRef.current.value = "";
		datalistRef1.current.reset();
		datalistRef2.current.reset();
	},[])

	const resetSearch = e => {
		e.preventDefault();
		mSEContext.updateSearchHandler("");
		mSEContext.updateYearFromHandler(false);
		mSEContext.updateYearToHandler(false);
		inputRef.current.value = "";
		datalistRef1.current.reset();
		datalistRef2.current.reset();
	}


	const visible = !((mSEContext.search && mSEContext.search.length) || mSEContext.yearFrom || mSEContext.yearTo)  ? "invisible" : ""

	return (
		<div id="TopSearchEngine">
			<h3>{texts.mainSpan}</h3>
			<form action="">
				<input
					type="text"
					placeholder={
						mSEContext.search ? mSEContext.search : texts.searchComp
					}
					onChange={inputUpdateHandler.bind(this)}
					ref={inputRef}
				/>

				<div className="wrapper">
					<div>
						<p>{texts.dateFrom}</p>
						<Datalist maxRecords={11} ref={datalistRef1} data={dateObject()} inline yearFrom />
					</div>
					<div>
						<p>{texts.to}</p>
						<Datalist maxRecords={11} ref={datalistRef2} data={dateObject()} inline yearTo />
					</div>
					<div style={{verticalAlign: 'middle'}}>
						<button className={`reset-btn ${visible}`} onClick={resetSearch}></button>
					</div>
				</div>
	
			</form>
		</div>
	);
}