import React, {useState, useEffect} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {Translations} from '../../../Translations'
import Loader from '../Loader'
import styles from './Composition.module.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery} from '../../../Helpers'

export default function Composition(props) {

    let { id , lang } = useParams();
    id = parseInt(id);

    const translation = Translations();

    const name = "Composition";
    const [data, updateData] = useState(false);
    const [texts, updateTexts] = useState(translation);

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 300);
        props.updateMenu(4);
        fetch(`${window.apiUrl}composition/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            updateData(res);
            initGallery(".gallery-text img", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [])

    // Gallery function
    const closeLightbox = () => lightboxToggle(false)

    return (
        data ? (
            <div className="composition-single">
                <div>
                    <h1 className={styles.mainTitle} dangerouslySetInnerHTML={{__html: `${data.title ? data.title : ""} ${data.opus ? data.opus : ""}`}}/>
                </div>

                {data.subtitle ? (
                    <div>
                        <h3 className={styles.subTitle} dangerouslySetInnerHTML={{__html: `${data.subtitle}`}}/>
                    </div>) : null}
                
                {data.image ? (
                    <div>
                        <img style={{maxWidth:"100%"}} src={data.image} alt={data.alt_photo ? data.alt_photo : ""}/>
                    </div>) : null}

                <div className="composition-single--table">
                    {data.composer ? (
                        <div>
                            <div className={styles.label}>{texts.composer}</div>
                            <div>{data.composer}</div>
                        </div>) : null}

                    {data.genre ? (
                        <div>
                            <div className={styles.label}>{texts.genre}</div>
                            <div>{data.genre}</div>
                        </div>) : null}

                    {data.key ? (
                        <div>
                            <div className={styles.label}>{texts.tonation}</div>
                            <div>{data.key}</div>
                        </div>) : null}

                    {data.cast ? (
                        <div>
                            <div className={styles.label}>{texts.cast}</div>
                            <div>{data.cast}</div>
                        </div>) : null}

                    {data.human_date ? (
                        <div>
                            <div className={styles.label}>{texts.createDate}</div>
                            <div dangerouslySetInnerHTML={{__html: data.human_date}} />
                        </div>) : null}

                    {data.dedications && data.dedications.length ? (
                        <div>
                            <div className={styles.label}>{texts.dedications}</div>
                            <div>
                                {data.dedications.map(function(item,index){
                                    return(
                                    <div style={{display: 'inline'}} key={index}>{index > 0 ? "," : ""} {item.name}</div>
                                    )
                                })}
                            </div>
                        </div>) : null}

                    {data.opus ? (
                        <div>
                            <div className={styles.label}>{texts.opusSpan}</div>
                            <div>{data.opus}</div>
                        </div>) : null}

                    {data.executionTime ? (
                        <div>
                            <div className={styles.label}>{texts.executionTime}</div>
                            <div>{data.execution_time}</div>
                        </div>) : null}

                    {data.premiere_human_date ? (
                        <div>
                            <div className={styles.label}>{texts.premiereDate}</div>
                            <div>{data.premiere_human_date}</div>
                        </div>) : null}

                    {data.place_name || data.area_name ? (
                        <div>
                            <div className={styles.label}>{texts.praPlace}</div>
                            <div>{data.place_name}{data.place_name && data.area_name ? ", " : ""}{data.area_name}</div>
                        </div>) : null}

                    {data.ordering_institutions ? (
                        <div>
                            <div className={styles.label}>{texts.orderingInstitution}</div>
                            <div>{data.ordering_institutions}</div>
                        </div>) : null}

                    {data.ordering_persons ? (
                        <div>
                            <div className={styles.label}>{texts.orderingPerson}</div>
                            <div>{data.ordering_persons}</div>
                        </div>) : null}

                    {data.lyrics_authors ? (
                        <div>
                            <div className={styles.label}>{texts.lyricsAuthor}</div>
                            <div>{data.lyrics_authors}</div>
                        </div>) : null}

                </div>
                {data.text ? (
                    <div>
                        <div className={`${styles.mainTitle} font-w-500`}>{texts.aboutSong}</div>
                        <div className={`${styles.mainText} gallery-text`}>
                            <div dangerouslySetInnerHTML={{__html: data.text}}/>
                        </div>
                    </div>) : null}

                <div>
                    {data.manuscripts.length ? (
                            <div>
                                <div className={styles.label}>{texts.manuscripts}</div>
                                <div>{data.manuscripts.map(function(item,index){
                                    return(
                                        <div key={index} className="list-element">
                                            {console.log(item, "item")}
                                            <Link to={`${props.url}/rekopisy/${item.id}`}>
                                                <span dangerouslySetInnerHTML={{__html: item.title}}/>
                                                {lang === "pl" ?
                                                    <>
                                                        {item.manuscripts_category ? (
                                                            <span>| {item.manuscripts_category}</span>
                                                        ) : null}
                                                        {item.place_of_storage_institution ? (
                                                            <span>| {item.place_of_storage_institution}</span>
                                                        ) : null}
                                                        {item.place ? (
                                                            <span>| {item.place}</span>
                                                        ) : null}
                                                        {item.country ? (
                                                            <span>| {item.country}</span>
                                                        ) : null}
                                                    </>
                                                : null}
                                                {item.signature ? (
                                                    <span>| {item.signature}</span>
                                                ) : null}
                                            </Link>                                        
                                        </div>
                                    )
                                })}</div>
                            </div>
                        ) : null}
                        {data.first_editions.length ? (
                            <div>
                                <div className={styles.label}>{texts.firstEditions}</div>
                                <div>{data.first_editions.map(function(item,index){
                                    return(
                                        <div key={index} className="list-element">
                                            <Link to={`${props.url}/pierwodruki/${item.id}`}>
                                                <span dangerouslySetInnerHTML={{__html: item.title}}/>
                                                {item.country ? (
                                                    <span>| {item.country}</span>
                                                ) : null}
                                                {item.publishinghouse ? (
                                                    <span>| {item.publishinghouse}</span>
                                                ) : null}
                                            </Link>                                        
                                        </div>
                                    )
                                })}</div>
                            </div>
                        ) : null}

                        {data.humdrums.length ? (
                            <div>
                                <div className={styles.label}>{texts.scores}</div>
                                <div>{data.humdrums.map(function(item,index){
                                    return(
                                        <div key={index} className="list-element">
                                            <a target="_blank" href={`https://chopinscores.org/index.php/partytura/${item.id}`}>
                                                <span dangerouslySetInnerHTML={{__html: item.title}}/>
                                            </a>                                        
                                        </div>
                                    )
                                })}</div>
                            </div>
                        ) : null}
                </div>
                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
            </div>
        ) : <Loader />
    )
}