import React from 'react'
import './Logo.css'
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

export default function Logo(props) {
    return (
            <Link to={`/${window.language}`}>
                <div id="logo">
                    <div className={`logo bg-contain ${props.data.mainPage ? "logo-white" : ""}`}></div>
                </div>
            </Link>
    )
}