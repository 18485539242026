import React, {useState, useEffect, useContext} from 'react'
import {Link, useRouteMatch} from "react-router-dom"
// import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import Loader from '../Elements/Loader'
import styles from './MainPage.module.css'
import {Translations} from '../../Translations'
import Dropdown from '../Elements/Dropdown';
import {parseLink} from '../../Helpers'
import placeholder from '../../assets/img/placeholder.jpg'
import { MSEContext } from '../Context/MainSearchEngineContext'

export default function MainPage(props) {

    // Just name of component
    const name = "MainPage";

    // Data for this component
    const [data, updateData] = useState(false);

    // Is data fetching?
    const [loading, loadingUpdate] = useState(false);

    // Pagination's options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false)
    const elementAmount = 20;

    // Multilanguage things
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // Getting context 
    const showContext = useContext(MSEContext)

    let compositionsLink = `${window.apiUrl}news?lang=${window.language}&offset=${(fetchCounter - 1)*elementAmount}&limit=${elementAmount}`

    // Get dropdowns data and list data on the 1st render
    useEffect(() => {
        showContext.updateMenuPositionHandler(1);
        fetch(compositionsLink)
        .then(res => res.json())
        .then((res)=>{
            console.log(name, res);
            console.log(compositionsLink)
            updateData(res);
            if(res.length < elementAmount){
                setNoMore(true);
            }
        })    
    },[])

    // Get only list data on the counter increase
    useEffect(() => {
        if(fetchCounter>1){
            fetch(compositionsLink)
            .then(res => res.json())
            .then((res)=>{
                console.log(name, res);
                updateData(data.concat(res));
                if(res.length < elementAmount){
                    setNoMore(true);
                }
            })    
        }
    }, [fetchCounter])

    const more = e => {
        e.preventDefault();
        updateFetchCounter(fetchCounter+1);
    }

    let {path, url} = useRouteMatch();

    // console.log("path to:", path, "a url to:", url)

    
    return (
        data && !loading ? (
            <main>
                <div className="main-wrapper" style={{backgroundColor: "#f8f8f8", padding: "30px"}}>
                    <div className={styles.title}>{texts.news}</div>

                    {!data || loading ? <Loader /> : (
                        <div className={styles.newsWrapper}>   
                            {data.map(function(item,index){
                                const image = item.image ? item.image : placeholder
                                return(
                                    <Link to={`/${window.language}/news/${item.category}/${item.id}`} key={index}>
                                        <div className={styles.news}>
                                            <div className={styles.newsImage}>
                                                <div className={styles.imgHolder} style={{backgroundImage: `url(${image})`}}></div>
                                            </div>
                                            <div className={styles.newsTitle} dangerouslySetInnerHTML={{__html: item.title}} />
                                            <div className={styles.newsLead} dangerouslySetInnerHTML={{__html: item.lead}} />
                                            <div className={styles.newsDate}>{item.publication_date}</div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    )}

                    {data.length < 1 ? <div style={{textAlign: "center", fontWeight: "bold"}}>{texts.noPositions}</div> : null}
                    {noMoreData ? null : <button className="more-btn" onClick={more}>{texts.moreResults}</button> }
                </div>
            </main>

        ) : <Loader />
    )
}
