import React from 'react'
import './Main.css'
import MainSearchEngine from '../Elements/MainSearchEngine'
import ComposerView from '../Elements/ComposerView'

export default function Main(props) {
    const name = "Main";

    // console.log("Main component - is main page: "+props.data.mainPage);

    // console.log(name, "props:", props.type)

    const wrapper = props.data.mainPage ? "main-wrapper" : "sub-wrapper";

    const searchEngine = props.data.mainPage ? <MainSearchEngine /> : null;

    const composerView = props.data.mainPage ? null : <ComposerView type={props.type} />;

    return (
        <main>
            <div className={wrapper}>
                {searchEngine}
                {composerView}
            </div>
        </main>
    )
}
