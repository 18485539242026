import React, {useState, useEffect} from 'react'
import { Link, useParams, useRouteMatch, Route} from "react-router-dom"
import {Translations} from '../../../Translations'
import Loader from '../Loader'
import styles from '../Composition/Composition.module.css'
import Gallery from '../Gallery'
import {mobile, initGallery, unmountGallery, parseLink} from '../../../Helpers'

export default function BibliographyItem(props) {

    let { id } = useParams();
    id = parseInt(id);

    const translation = Translations();

    const [data, updateData] = useState(false);
    const [texts, updateTexts] = useState(translation);
    const [table, updateTable] = useState([]);

    // Gallery states
    const [lightboxOn, lightboxToggle] = useState(false);
    const [lightboxImages, lightboxImagesUpdate] = useState([]);

    const tableCreate = (res) => {
        res.editor.map(function(item,index){
            let editor = item.first_name+" "+item.last_name;
            let newTable = table;
            newTable.push(editor)
            updateTable(table.join(", "));
        })
    }

    useEffect(() => {
        window.scrollTo(0, 300);
        props.updateMenu(7);
        fetch(`${window.apiUrl}bibliography/${id}?lang=${window.language}`)
        .then(res => res.json())
        .then((res)=>{
            updateData(res);
            console.log(res)
            tableCreate(res);
            initGallery(".gallery-text img", lightboxImages, lightboxToggle);
            if(mobile()){
                const text = document.getElementsByClassName("text-wrapper")
                text[0].scrollIntoView(); 
            }
        })

        return () => unmountGallery(".gallery-text img", lightboxImages, lightboxToggle)
    }, [])

    // Gallery function
    const closeLightbox = () => lightboxToggle(false)


    // Deklarujemy zmienną przed blokiem return
    let groupedRelations = {};

    if (data.relations && data.relations.length) {
        // Iterujemy przez relacje i grupujemy je na podstawie type_relation_id
        data.relations.forEach((item) => {
            const typeRelationName = item.type_relation_id;
            if (!groupedRelations[typeRelationName]) {
                groupedRelations[typeRelationName] = [];
            }
            groupedRelations[typeRelationName].push(item);
        });
    }

    {console.log(groupedRelations)}
    return (
        data ? (
            <div className="composition-single">
                <div>
                    <h1 className={styles.mainTitle} dangerouslySetInnerHTML={{__html: `${data.title ? data.title : ""} ${data.opus ? data.opus : ""}`}}/ >
                </div>

                {data.image ? (
                    <div>
                        <img style={{maxWidth:"100%"}} src={data.image} alt=""/>
                    </div>
                ) : null}

                {data.txt ? (
                    <div>
                        <div className={`${styles.mainText} gallery-text`}>
                            <div dangerouslySetInnerHTML={{__html: data.txt}}/>
                        </div>
                    </div>
                ) : null}

                <div className="composition-single--table biblio-table">
                    {data.date_of_publishing_text ? (
                        <div>
                            <div className={styles.label}>{texts.publicationDate}</div>
                            <div>{data.date_of_publishing_text}</div>
                        </div>
                    ) : null}
                    {data.isbn ? (
                        <div>
                            <div className={styles.label}>ISBN</div>
                            <div>{data.isbn}</div>
                        </div>
                    ) : null}
                    {data.keywords ? (
                        <div>
                            <div className={styles.label}>{texts.keywords}</div>
                            <div>{data.keywords}</div>
                        </div>
                    ) : null}
                    {data.physical_description && data.physical_description.length ? (
                        <div>
                            <div className={styles.label}>{texts.descriptionF}</div>
                            <div dangerouslySetInnerHTML={{__html: data.physical_description}} />
                        </div>
                    ) : null}
                    {data.category ? (
                        <div>
                            <div className={styles.label}>{texts.category}</div>
                            <div>{data.category}</div>
                        </div>
                    ) : null}
                    {data.series ? (
                        <div>
                            <div className={styles.label}>{texts.series}</div>
                            <div>{data.series}</div>
                        </div>
                    ) : null}
                    {data.journal ? (
                        <div>
                            <div className={styles.label}>{texts.journal}</div>
                            <div>{data.journal}</div>
                        </div>
                    ) : null}
                    {data.publishing_house && data.publishing_house.length ? (
                        <div>
                            <div className={styles.label}>{texts.publishHouse}</div>
                            <div>
                                {data.publishing_house.map(function(item,index){
                                    return(
                                    <span key={index}>{index > 0 ? ", " : null}{item.name}</span>
                                    )
                                })}
                            </div>
                        </div>
                    ) : null}
                    {data.areas ? (
                        <div>
                            <div className={styles.label}>{texts.publishPlace}</div>
                            <div>{data.areas}</div>
                        </div>
                    ) : null}
                    {data.authors.length ? (
                            <div>
                                <div className={styles.label}>{texts.authors}</div>
                                <div>{data.authors.map(function(item,index){
                                    return(
                                    <span key={index}>{index > 0 ? ", " : null}{item.first_name + " " +item.last_name}</span>
                                    )
                                })}</div>
                            </div>
                        ) : null}
                    {data.reviewing && data.reviewing.length ? (
                        <div>
                            <div className={styles.label}>{texts.reviewing}</div>
                            <div>{data.reviewing.map(function (item, index) {
                                return (
                                    <div key={index} dangerouslySetInnerHTML={{ __html: item.title }} />
                                )
                            })}</div>
                        </div>
                    ) : null}
                    {data.reviewed_by && data.reviewed_by.length ? (
                        <div>
                            <div className={styles.label}>{texts.reviewed_by}</div>
                            <div>{data.reviewed_by.map(function (item, index) {
                                return (
                                    <div key={index} dangerouslySetInnerHTML={{ __html: item.title }} />
                                )
                            })}</div>
                        </div>
                    ) : null}

                    {data.circulation_number ? (
                        <div>
                            <div className={styles.label}>{texts.circulationNumber}</div>
                            <div>{data.circulation_number}</div>
                        </div>
                    ) : null}

                    {data.pages_num ? (
                        <div>
                            <div className={styles.label}>{texts.pagesAmout}</div>
                            <div>{data.pages_num}</div>
                        </div>
                    ) : null}


                    {data.preface_foreword && data.preface_foreword.length ? (
                        <div>
                            <div className={styles.label}>{texts.prefaceForeword}</div>
                            <div>{data.preface_foreword.map(function (item, index) {
                                return (
                                <>
                                    {index > 0 ? (
                                        <span>, </span>
                                    ) : null}
                                    <span key={index} dangerouslySetInnerHTML={{ __html: item.first_name + " " + item.last_name }} />
                                </>
                                )
                            })}</div>
                        </div>
                    ) : null}

                    {data.language ? (
                        <div>
                            <div className={styles.label}>{texts.language}</div>
                            <div>{data.language}</div>
                        </div>
                    ) : null}

                    {data.editor ? (
                        data.editor.length > 0 ? (
                            <div>
                                <div className={styles.label}>{texts.editors}</div>
                                <div>
                                    {table}
                                </div>
                            </div>
                            ) : null
                    ) : null}

                    {data.shop_url ? (
                        <div>
                            <div className={styles.label}>{texts.shopURL}</div>
                            <div>
                                <a href={data.shop_url}><em>{texts.shopURLTxt}</em></a>
                            </div>
                        </div>
                    ) : null}

                    {data.relations && data.relations.length ? (
                        <>
                            {Object.keys(groupedRelations).map((relationId) => {
                                const groupRelation = groupedRelations[relationId];
                                const relationName = groupRelation[0].type_relation_name;

                                return (
                                    <div>
                                    <div key={relationId}>
                                        <div className={styles.label}>{relationName}:</div>
                                        <ul style={{ padding: '0px', listStyle: 'none' }}>
                                            {groupRelation.map((one, index) => {
                                                const areasString = one.areas.map((area) => area.name).join(', ');
                                                const publishingHouseList = one.publishing_house
                                                    .map((publishingHouse) => publishingHouse.name)
                                                    .join(', ');

                                                return (
                                                    <li>
                                                        <a
                                                            //href={`${props.url}/bibliografia/${one.id}_${parseLink(`${one.title}`)}`}
                                                            href={`/${window.language}/osoba/${one.characters[0].id}-${parseLink(one.characters[0].first_name+" "+one.characters[0].last_name)}/bibliografia/${one.id}_${parseLink(`${one.title}`)}`}
                                                        >
                                                            { relationId == 2
                                                                ? (
                                                                    <>
                                                                    {one.title} | {publishingHouseList} | {areasString} | {one.date_of_publishing_text}
                                                                    </>
                                                                    )
                                                                : (
                                                                    <>
                                                                    {one.title}
                                                                	</>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div></div>
                                );
                            })}
                        </>
                    ) : null}
                    
                </div>
                {lightboxOn ? <Gallery images={lightboxImages} close={closeLightbox} /> : null}
            </div>
        ) : <Loader />
    )
}