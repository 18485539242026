export function mobile() {
    if (window.innerWidth < 1200) {
        return true
    } else return false
}

export function minHeight() {
    const headerH = document.getElementsByTagName("header")[0].offsetHeight;
    const footerH = document.getElementsByTagName("footer")[0].offsetHeight;
    const takenH = headerH + footerH;
    document.getElementsByTagName("main")[0].style.minHeight = `calc(100vh - ${takenH}px)`;
}

export function parseLink(link){
    var latinize = require('latinize');
    return latinize(link.replace(/<[^>]*>?/gm, '').replace(/\s+/g, '-').replace(/[['":‘”„!/,.]/g, "").replace(/[{()}]/g, '').replace(/[\[\]']+/g, '').toLowerCase())
}

export function getLang() {
    localStorage.getItem("lang")
}

export function initGallery(imageClass, lightboxImages, lightboxToggle){
    document.querySelectorAll(imageClass).forEach( img => {
        const src = img.getAttribute("src");
        const description = img.getAttribute("description");
        if(!lightboxImages.includes(src)){
            lightboxImages.push({src, description})
        }
        
        img.addEventListener("click", function(e){
            lightboxImages.forEach(function(item,i){
                if(item.src === src){
                    lightboxImages.splice(i, 1);
                    lightboxImages.unshift(item);
                }
              });
              lightboxToggle(true);
        })
    })
}

export function unmountGallery(imageClass, lightboxImages, lightboxToggle){
    document.querySelectorAll(imageClass).forEach( img => {
        img.removeEventListener("click", function(e){
            lightboxImages.forEach(function(item,i){
                if(item.src === e.target.src){
                    lightboxImages.splice(i, 1);
                    lightboxImages.unshift(item);
                }
              });
              lightboxToggle(true);
        })
    })
}