import React, { useEffect, useState, useContext } from 'react'
import { MSEContext } from '../Context/MainSearchEngineContext'
import { Link, useRouteMatch } from "react-router-dom"
import Loader from '../Elements/Loader'
import placeholder from '../../assets/img/placeholder.jpg'
import { minHeight } from '../../Helpers'
import { parseLink } from '../../Helpers'
import { Translations } from '../../Translations'
import styles from './AllMultimedia.module.css'
import { mobile } from '../../Helpers'

export default function AllAudios(props) {

    const name = 'AllAudios'

    // Languages
    const translation = Translations();
    const [texts, updateTexts] = useState(translation);

    // All data
    const [data, updateData] = useState(false);

    // Is data currently fetching?
    const [isLoading, isLoadingUpdate] = useState(false);
    const [searchText, updateSearchText] = useState(false);
    const [clearRequest, clearRequestUpdate] = useState(false);

    // Pagination options
    const [fetchCounter, updateFetchCounter] = useState(1);
    const [noMoreData, setNoMore] = useState(false);
    const elementAmount = 12;

    // Getting context 
    const showContext = useContext(MSEContext)

    // console.log(name, props)

    let searchParam = searchText ? `&search=${searchText}` : ""
    let multimediaLink = `${window.apiUrl}spotify?&lang=${window.language}${searchParam}`

    const fetchHandler = (link, updater, fetchName) => {
        showContext.updateMenuPositionHandler(7);
        isLoadingUpdate(true)
        fetch(link)
            .then(res => res.json())
            .then((res) => {
                console.log(fetchName, "link: ", link);
                console.log(fetchName, "response: ", res);
                const unique = res.filter((item, index) => res.findIndex(obj => obj.composers[0].id === item.composers[0].id) === index)
                updater(unique);
                isLoadingUpdate(false);
            })
    }

    useEffect(() => {
        fetchHandler(multimediaLink, updateData, "AllAudiosData");
    }, [clearRequest])

    const clear = e => {
        e.preventDefault();
        updateSearchText(false);
        clearRequestUpdate(!clearRequest);
    }

    const submit = e => {
        e.preventDefault();
        fetchHandler();
    }

    const isLoaded = index => {
        const box = `.box-${index}`;
        document.querySelector(box).classList.remove("loading")
    }

    return (
        <main>
            {data && data.length ? (
                <div className={`main-wrapper ${styles.mainWrapper}`}>
                    {/* <form className="secondary-search" onClick={() => window.activeSearch = 'secondary'}>
                    <div>
                        <input type="text" onChange={e => updateSearchText(e.currentTarget.value)} placeholder={searchText ? searchText : texts.searchPhrase} />
                        <div style={{ textAlign: "center" }}>
                            <button onClick={clear} className="button white">{texts.clear}</button>
                            <button onClick={submit} id="submit-comp" className="button red">{texts.search}</button>
                        </div>
                    </div>
                </form> */}
                    <div className={styles.innerWrapper}>
                        <h1 className="title">Audio</h1>

                        <div className={styles.iconoWrapper}>
                            {data.map(function (item, index) {
                                const image = item.image && item.image.length ? item.image : placeholder;
                                return (
                                    <>
                                        {item.composers && item.composers.length ? (
                                            <Link to={`/${window.language}/osoba/${item.composers[0].id}-${parseLink(item.composers[0].first_name)}-${parseLink(item.composers[0].last_name)}/multimedia/spotify/${item.composers[0].id}`}>
                                                <div className={`${styles.iconoItem} bg-cover`} style={{ backgroundImage: `url(${image})` }}>
                                                    <h3>{item.composers[0].first_name + " " + item.composers[0].last_name}</h3>
                                                </div>
                                            </Link>
                                        ) : (
                                                <div className={`${styles.iconoItem} bg-cover`} style={{ backgroundImage: `url(${image})` }}>
                                                    <h3>{item.title}</h3>
                                                </div>
                                            )}
                                    </>
                                )
                            })}
                        </div>
                        {data.length < 1 ? <div style={{ textAlign: "center", fontWeight: "bold" }}>{texts.noPositions}</div> : null}
                    </div>
                </div>
            ) : <Loader />}
        </main>
    )
}